@media screen and (max-width: 1223px) {
  .unwrapper {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media screen and (min-width: 1175px) and (max-width: 1223px) {
  .header-menu-wrapper {
    margin: 0px max(-10px, calc((100vw - 1223px) / 2));
  }
}

@media screen and (min-width: 1175px) {
  .header-service-menu-container {
    background: #eaeaea;
    padding: 0px 0px 42px;
  }
    .header-service-menu-container > ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
      .header-service-menu-container > ul .dropmarker {
        display: none;
      }

      .header-service-menu-container > ul > li > div > div > * {
        display: block;
        color: #000;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
        padding: 12px 18px;
        text-align: center;
      }
      .header-service-menu-container > ul li > div a {
        cursor: pointer;
      }
      .header-service-menu-container > ul li > div span,
      .header-service-menu-container > ul li.active > div > div > * {
        cursor: default;
      }
        .header-service-menu-container > ul > li.with-dropdown > div > div > * {
          background: rgba(2,100,165,.4);
        }
          .header-service-menu-container > ul > li.with-dropdown ~ li.with-dropdown > div > div > * {
            background: transparent;
          }
        .header-service-menu-container > ul > li > div > div:hover > *,
        .header-service-menu-container > ul > li.active > div > div > * {
          background: #333 !important;
          color: #fff;
        }

      .header-service-menu-container > ul > li > ul,
      .header-service-menu-container > ul > li.with-dropdown ~ li.with-dropdown > ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        background: rgba(2,100,165,.4);
        position: absolute;
        top: 100%;
        left: 0px;
        right: 0px;
        height: 42px;

        visibility: hidden;
        opacity: 0;
      }
        .header-service-menu-container > ul > li.with-dropdown > ul {
          visibility: visible;
          opacity: 1;
        }
        .header-service-menu-container > ul > li > ul > li {
          position: relative;
        }
          .header-service-menu-container > ul > li > ul > li > div > div > * {
            display: block;
            color: #000;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            white-space: nowrap;
            padding: 9px 18px;
            text-align: center;
          }
            .header-service-menu-container > ul > li > ul > li:hover > div > div > *,
            .header-service-menu-container > ul > li > ul > li.active > div > div > a {
              background: #333;
              color: #fff;
            }
            .header-service-menu-container > ul > li > ul > li.with-active > div > div > * {
              background: rgba(51,51,51,.6);
              color: #fff;
            }

          .header-service-menu-container > ul > li > ul > li > ul {
            background: #fff;
            position: absolute;
            top: 100%;
            left: 0px;
            min-width: 100%;
            padding: 18px 20px;
            border: 2px solid #333;

            visibility: hidden;
            opacity: 0;
          }
            .header-service-menu-container > ul > li > ul > li > div:hover + ul,
            .header-service-menu-container > ul > li > ul > li:hover > ul,
            .header-service-menu-container > ul > li > ul > li.is-hovered > ul {
              visibility: visible;
              opacity: 1;
            }
            .header-service-menu-container > ul > li > ul > li > ul li > div > div > * {
              display: block;
              padding: 2px 0px;
              white-space: nowrap;
            }
              .header-service-menu-container > ul > li > ul > li > ul li > div > div > a:hover,
              .header-service-menu-container > ul > li > ul > li > ul li.active > div > div > a {
                color: #3278be;
                text-decoration: underline;
              }
              .header-service-menu-container > ul > li > ul > li > ul li.active > div > div > a {
                font-weight: 600;
                cursor: default;
              }
}
@media screen and (max-width: 1174px) {
  .menu-is-opened {
    position: relative;
    overflow: hidden !important;
  }


  .header {
    border-bottom: 2px solid #8db4ce;
    z-index: unset;
  }
    .header > .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
      .header-info-menu-container {
        display: none;
      }

      .header-content {
        flex-grow: 1;
        order: 2;
      }


      .header-menu-wrapper {
        order: 1;
      }
        .header-menu-wrapper:before {
          content: "";
          display: block;
          background: #000;
          position: fixed;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 97;

          opacity: 0;
          visibility: hidden;

          -webkit-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
          -moz-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
          -o-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
          transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
        }
          .menu-is-opened .header-menu-wrapper:before {
            opacity: .6;
            visibility: visible;

            -webkit-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
            -moz-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
            -o-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
            transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
          }

        .header-menu-button-wrapper {
          display: block;
          padding-right: 35px;
        }
          .header-menu-button,
          .header-menu-button-close {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            background: #3278be;
            width: 44px;
            min-width: 44px;
            padding: 11px 9px;
            position: relative;
            cursor: pointer;
          }
            .header-menu-button:before,
            .header-menu-button:after,
            .header-menu-button span {
              content: '';
              font-size: 0px;
              background: #fff;
              border-radius: 9px;
              height: 3px;
              padding: 0px;
              position: relative;
            }

            .header-menu-button span {
              margin: 6px 0px;
            }


          .header-menu-button-close {
            padding: 21px 9px 23px;
            position: absolute;
            top: 10px;
            right: 0px;
            z-index: 98;
          }
            .header-menu-button-close:before,
            .header-menu-button-close:after {
              content: '';
              display: block;
              background: #fff;
              height: 3px;
              margin: 0px -2px -3px -2px;

              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            .header-menu-button-close:after {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }


        .header-service-menu-container {
          background: none;
          width: max-content;
          min-width: 400px;
          max-width: 100%;
          padding: 0px 44px 0px 0px;
          position: fixed;
          top: 0px;
          right: 100%;
          bottom: 0px;
          z-index: 99;

          -webkit-transition: right .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
          -moz-transition: right .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
          -o-transition: right .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
          transition: right .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
        }
          .menu-is-opened .header-service-menu-container {
            -webkit-transform: translateX(100%);
            -moz-transform: translateX(100%);
            -ms-transform: translateX(100%);
            -o-transform: translateX(100%);
            transform: translateX(100%);
          }

          .header-service-menu-container > ul {
            display: block;
            background: #f5f5f5;
            border: solid #f5f5f5;
            border-width: 15px 0px;
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,.5);
            font-size: 16px;
            height: 100%;
            padding: 0px 10px;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 98;
          }
            .header-service-menu-container > ul .dropmarker {
              content: '';
              color: transparent;
              font-size: 0px;
              width: 40px;
              min-width: 40px;
              cursor: pointer;
              position: relative;
              box-sizing: content-box;

              -webkit-transition: background .2s ease-in-out 0s;
              -moz-transition: background .2s ease-in-out 0s;
              -o-transition: background .2s ease-in-out 0s;
              transition: background .2s ease-in-out 0s;
            }
              .header-service-menu-container > ul li.with-active > div > .dropmarker {
                background-color: rgba(51,51,51,.06);
              }
              .header-service-menu-container > ul li.is-hovered > div > .dropmarker {
                background-color: rgba(51,51,51,.03);
              }

              .header-service-menu-container > ul .dropmarker:before {
                content: '';
                display: block;
                background: url('/i/header-menu-dropdown.svg') no-repeat 0px 50%/contain transparent;
                width: 12px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;

                -webkit-transition: -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
                -moz-transition: -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
                -o-transition: -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
                transition: -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
              }
                .header-service-menu-container > ul li.is-hovered > div > .dropmarker:before {
                  -webkit-transform: rotate(180deg);
                  -moz-transform: rotate(180deg);
                  -ms-transform: rotate(180deg);
                  -o-transform: rotate(180deg);
                  transform: rotate(180deg);
                }



            .header-service-menu-container > ul li > div {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: stretch;
            }
            .header-service-menu-container > ul li + li > div {
              border-top: 1px solid rgba(51,51,51,.4);
            }
              .header-service-menu-container > ul li:not(.header-info-item) + li.header-info-item > div {
                padding: 15px 0px 0px;
                margin: 15px 0px 0px;
              }

              .header-service-menu-container > ul li > div > div {
                width: 100%;
              }
                .header-service-menu-container > ul li > div a,
                .header-service-menu-container > ul li > div span {
                  display: block;
                  font-weight: 400;
                  padding: 10px 40px 10px 2px;
                  cursor: pointer;
                }
                  .header-service-menu-container > ul li.active > div a {
                    font-weight: 700;
                  }

                  .header-service-menu-container > ul > li > div a,
                  .header-service-menu-container > ul > li > div span {
                    text-transform: uppercase;
                  }

            .header-service-menu-container > ul li > ul {
              display: none;
              background: rgba(51,51,51,.03);
              padding: 0px 0px 0px 10px;
              margin: 0px 0px 15px;
              overflow: hidden;
            }
}


/* landscape tablet - lt */
@media screen and (max-width: 1023px) {
  /* Border */
  /* ====== */
  /* Border width */
  .lt_brw0 { border-width: 0px !important; }
  .lt_brtw0 { border-top-width: 0px !important; }
  .lt_brlw0 { border-left-width: 0px !important; }
  .lt_brrw0 { border-right-width: 0px !important; }
  .lt_brbw0 { border-bottom-width: 0px !important; }
  .lt_brw1 { border-width: 1px !important; }
  .lt_brtw1 { border-top-width: 1px !important; }
  .lt_brlw1 { border-left-width: 1px !important; }
  .lt_brrw1 { border-right-width: 1px !important; }
  .lt_brbw1 { border-bottom-width: 1px !important; }

  /* Border style */
  .lt_brstn { border-style: none; }
  .lt_brsts { border-style: solid; }
  .lt_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .lt_dn { display: none; }
  .lt_dn_i { display: none !important; }
  .lt_db { display: block; }
  .lt_di { display: inline; }
  .lt_dib { display: inline-block; }
  .lt_dt { display: table; }
  .lt_dtr { display: table-row; }
  .lt_dtc { display: table-cell; }
  .lt_du { display: unset; }

  /* Flex */
  .lt_dfdr { display: flex; flex-direction: row !important; }
  .lt_dfdrr { display: flex; flex-direction: row-reverse !important; }
  .lt_dfdc { display: flex; flex-direction: column !important; }
  .lt_dfdcr { display: flex; flex-direction: column-reverse !important; }
  .lt_difdr { display: inline-flex; flex-direction: row !important; }
  .lt_difdrr { display: inline-flex; flex-direction: row-reverse !important; }
  .lt_difdc { display: inline-flex; flex-direction: column !important; }
  .lt_difdcr { display: inline-flex; flex-direction: column-reverse !important; }
  .lt_fwn { flex-wrap: nowrap !important; }
  .lt_fww { flex-wrap: wrap !important; }
  .lt_fwwr { flex-wrap: wrap-reverse !important; }
  .lt_fjcs { justify-content: flex-start !important; }
  .lt_fjce { justify-content: flex-end !important; }
  .lt_fjcc { justify-content: center !important; }
  .lt_fjcsa { justify-content: space-around !important; }
  .lt_fjcsb { justify-content: space-between !important; }
  .lt_fais { align-items: flex-start !important; }
  .lt_faie { align-items: flex-end !important; }
  .lt_faic { align-items: center !important; }
  .lt_faib { align-items: baseline !important; }
  .lt_faist { align-items: stretch !important; }
  .lt_facs { align-content: flex-start !important; }
  .lt_face { align-content: flex-end !important; }
  .lt_facc { align-content: center !important; }
  .lt_facsa { align-content: space-around !important; }
  .lt_facsb { align-content: space-between !important; }
  .lt_facst { align-content: stretch !important; }
  .lt_fass { align-self: flex-start !important; }
  .lt_fase { align-self: flex-end !important; }
  .lt_fasc { align-self: center !important; }
  .lt_fasb { align-self: baseline !important; }
  .lt_fasst { align-self: stretch !important; }
  .lt_fg0 { flex-grow: 0 !important; }
  .lt_fg1 { flex-grow: 1 !important; }
  .lt_fsh0 { flex-shrink: 0 !important; }
  .lt_fsh1 { flex-shrink: 1 !important; }

  .lt_fo0 { order: 0; }
  .lt_fo1 { order: 1; }
  .lt_fo2 { order: 2; }
  .lt_fo3 { order: 3; }
  .lt_fo4 { order: 4; }
  .lt_fo5 { order: 5; }
  .lt_fo6 { order: 6; }
  .lt_fo7 { order: 7; }
  .lt_fo8 { order: 8; }
  .lt_fo9 { order: 9; }
  .lt_fo99 { order: 99; }
  .lt_fo999 { order: 999; }
  .lt_fo9999 { order: 9999; }


  /* Height */
  /* ====== */
  .lt_h0 { height: 0; }
  .lt_h100p, .lt_hfull { height: 100%; }
  .lt_hauto { height: auto; }


  /* Width */
  /* ===== */
  .lt_w0 { width: 0; }
  .lt_w1 { width: 1px; }
  .lt_w5p { width: 5%; }
  .lt_w7p { width: 7%; }
  .lt_w10p { width: 10%; }
  .lt_w12p { width: 12.5%; }
  .lt_w15p { width: 15%; }
  .lt_w20p { width: 20%; }
  .lt_w25p { width: 25%; }
  .lt_w33p { width: 33.33333333%; }
  .lt_w35p { width: 35%; }
  .lt_w40p { width: 40%; }
  .lt_w50p { width: 50%; }
  .lt_w60p { width: 60%; }
  .lt_w65p { width: 65%; }
  .lt_w66p { width: 66.66666666%; }
  .lt_w70p { width: 70%; }
  .lt_w75p { width: 75%; }
  .lt_w80p { width: 80%; }
  .lt_w85p { width: 85%; }
  .lt_w88p { width: 87.5%; }
  .lt_w90p { width: 90%; }
  .lt_w93p { width: 93%; }
  .lt_w95p { width: 95%; }
  .lt_w100p, .lt_wfull { width: 100%; }
  .lt_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .lt_m0auto, .lt_m0a, .lt_m0auto_i, .lt_m0a_i { margin: 0 auto !important; }
  .lt_mauto0, .lt_ma0, .lt_mauto0_i, .lt_ma0_i { margin: auto 0 !important; }
  .lt_mauto, .lt_ma, .lt_mauto_i, .lt_ma_i { margin: auto !important; }
  .lt_mtauto, .lt_mta, .lt_mtauto_i, .lt_mta_i { margin-top: auto !important; }
  .lt_mlauto, .lt_mla, .lt_mlauto_i, .lt_mla_i { margin-left: auto !important; }
  .lt_mrauto, .lt_mra, .lt_mrauto_i, .lt_mra_i { margin-right: auto !important; }
  .lt_mbauto, .lt_mba, .lt_mbauto_i, .lt_mba_i { margin-bottom: auto !important; }
  .lt_m0, .lt_m0_i { margin: 0 !important; }
  .lt_mt0, .lt_mt0_i { margin-top: 0 !important; }
  .lt_ml0, .lt_ml0_i { margin-left: 0 !important; }
  .lt_mr0, .lt_mr0_i { margin-right: 0 !important; }
  .lt_mb0, .lt_mb0_i { margin-bottom: 0 !important; }
  .lt_m1 { margin: 1px !important; }
  .lt_mt1 { margin-top: 1px !important; }
  .lt_ml1 { margin-left: 1px !important; }
  .lt_mr1 { margin-right: 1px !important; }
  .lt_mb1 { margin-bottom: 1px !important; }
  .lt_m2 { margin: 2px !important; }
  .lt_mt2 { margin-top: 2px !important; }
  .lt_ml2 { margin-left: 2px !important; }
  .lt_mr2 { margin-right: 2px !important; }
  .lt_mb2 { margin-bottom: 2px !important; }
  .lt_m3 { margin: 3px !important; }
  .lt_mt3 { margin-top: 3px !important; }
  .lt_ml3 { margin-left: 3px !important; }
  .lt_mr3 { margin-right: 3px !important; }
  .lt_mb3 { margin-bottom: 3px !important; }
  .lt_m4 { margin: 4px !important; }
  .lt_mt4 { margin-top: 4px !important; }
  .lt_ml4 { margin-left: 4px !important; }
  .lt_mr4 { margin-right: 4px !important; }
  .lt_mb4 { margin-bottom: 4px !important; }
  .lt_m5 { margin: 5px !important; }
  .lt_mt5 { margin-top: 5px !important; }
  .lt_ml5 { margin-left: 5px !important; }
  .lt_mr5 { margin-right: 5px !important; }
  .lt_mb5 { margin-bottom: 5px !important; }
  .lt_m7 { margin: 7px !important; }
  .lt_mt7 { margin-top: 7px !important; }
  .lt_ml7 { margin-left: 7px !important; }
  .lt_mr7 { margin-right: 7px !important; }
  .lt_mb7 { margin-bottom: 7px !important; }
  .lt_m10 { margin: 10px !important; }
  .lt_mt10 { margin-top: 10px !important; }
  .lt_ml10 { margin-left: 10px !important; }
  .lt_mr10 { margin-right: 10px !important; }
  .lt_mb10 { margin-bottom: 10px !important; }
  .lt_m13 { margin: 13px !important; }
  .lt_mt13 { margin-top: 13px !important; }
  .lt_ml13 { margin-left: 13px !important; }
  .lt_mr13 { margin-right: 13px !important; }
  .lt_mb13 { margin-bottom: 13px !important; }
  .lt_m15 { margin: 15px !important; }
  .lt_mt15 { margin-top: 15px !important; }
  .lt_ml15 { margin-left: 15px !important; }
  .lt_mr15 { margin-right: 15px !important; }
  .lt_mb15 { margin-bottom: 15px !important; }
  .lt_m20 { margin: 20px !important; }
  .lt_mt20 { margin-top: 20px !important; }
  .lt_ml20 { margin-left: 20px !important; }
  .lt_mr20 { margin-right: 20px !important; }
  .lt_mb20 { margin-bottom: 20px !important; }
  .lt_m25 { margin: 25px !important; }
  .lt_mt25 { margin-top: 25px !important; }
  .lt_ml25 { margin-left: 25px !important; }
  .lt_mr25 { margin-right: 25px !important; }
  .lt_mb25 { margin-bottom: 25px !important; }
  .lt_m30 { margin: 30px !important; }
  .lt_mt30 { margin-top: 30px !important; }
  .lt_ml30 { margin-left: 30px !important; }
  .lt_mr30 { margin-right: 30px !important; }
  .lt_mb30 { margin-bottom: 30px !important; }
  .lt_m35 { margin: 35px !important; }
  .lt_mt35 { margin-top: 35px !important; }
  .lt_ml35 { margin-left: 35px !important; }
  .lt_mr35 { margin-right: 35px !important; }
  .lt_mb35 { margin-bottom: 35px !important; }
  .lt_m-1 { margin: -1px !important; }
  .lt_mt-1 { margin-top: -1px !important; }
  .lt_ml-1 { margin-left: -1px !important; }
  .lt_mr-1 { margin-right: -1px !important; }
  .lt_mb-1 { margin-bottom: -1px !important; }
  .lt_m-2 { margin: -2px !important; }
  .lt_mt-2 { margin-top: -2px !important; }
  .lt_ml-2 { margin-left: -2px !important; }
  .lt_mr-2 { margin-right: -2px !important; }
  .lt_mb-2 { margin-bottom: -2px !important; }
  .lt_m-3 { margin: -3px !important; }
  .lt_mt-3 { margin-top: -3px !important; }
  .lt_ml-3 { margin-left: -3px !important; }
  .lt_mr-3 { margin-right: -3px !important; }
  .lt_mb-3 { margin-bottom: -3px !important; }
  .lt_m-4 { margin: -4px !important; }
  .lt_mt-4 { margin-top: -4px !important; }
  .lt_ml-4 { margin-left: -4px !important; }
  .lt_mr-4 { margin-right: -4px !important; }
  .lt_mb-4 { margin-bottom: -4px !important; }
  .lt_m-5 { margin: -5px !important; }
  .lt_mt-5 { margin-top: -5px !important; }
  .lt_ml-5 { margin-left: -5px !important; }
  .lt_mr-5 { margin-right: -5px !important; }
  .lt_mb-5 { margin-bottom: -5px !important; }
  .lt_m-7 { margin: -7px !important; }
  .lt_mt-7 { margin-top: -7px !important; }
  .lt_ml-7 { margin-left: -7px !important; }
  .lt_mr-7 { margin-right: -7px !important; }
  .lt_mb-7 { margin-bottom: -7px !important; }
  .lt_m-10 { margin: -10px !important; }
  .lt_mt-10 { margin-top: -10px !important; }
  .lt_ml-10 { margin-left: -10px !important; }
  .lt_mr-10 { margin-right: -10px !important; }
  .lt_mb-10 { margin-bottom: -10px !important; }
  .lt_m-13 { margin: -13px !important; }
  .lt_mt-13 { margin-top: -13px !important; }
  .lt_ml-13 { margin-left: -13px !important; }
  .lt_mr-13 { margin-right: -13px !important; }
  .lt_mb-13 { margin-bottom: -13px !important; }
  .lt_m-15 { margin: -15px !important; }
  .lt_mt-15 { margin-top: -15px !important; }
  .lt_ml-15 { margin-left: -15px !important; }
  .lt_mr-15 { margin-right: -15px !important; }
  .lt_mb-15 { margin-bottom: -15px !important; }
  .lt_m-20 { margin: -20px !important; }
  .lt_mt-20 { margin-top: -20px !important; }
  .lt_ml-20 { margin-left: -20px !important; }
  .lt_mr-20 { margin-right: -20px !important; }
  .lt_mb-20 { margin-bottom: -20px !important; }
  .lt_m-25 { margin: -25px !important; }
  .lt_mt-25 { margin-top: -25px !important; }
  .lt_ml-25 { margin-left: -25px !important; }
  .lt_mr-25 { margin-right: -25px !important; }
  .lt_mb-25 { margin-bottom: -25px !important; }
  .lt_m-30 { margin: -30px !important; }
  .lt_mt-30 { margin-top: -30px !important; }
  .lt_ml-30 { margin-left: -30px !important; }
  .lt_mr-30 { margin-right: -30px !important; }
  .lt_mb-30 { margin-bottom: -30px !important; }
  .lt_m-35 { margin: -35px !important; }
  .lt_mt-35 { margin-top: -35px !important; }
  .lt_ml-35 { margin-left: -35px !important; }
  .lt_mr-35 { margin-right: -35px !important; }
  .lt_mb-35 { margin-bottom: -35px !important; }


  /* Paddings */
  /* ======== */
  .lt_p0 { padding: 0 !important; }
  .lt_pt0 { padding-top: 0 !important; }
  .lt_pl0 { padding-left: 0 !important; }
  .lt_pr0 { padding-right: 0 !important; }
  .lt_pb0 { padding-bottom: 0 !important; }
  .lt_p1 { padding: 1px !important; }
  .lt_pt1 { padding-top: 1px !important; }
  .lt_pl1 { padding-left: 1px !important; }
  .lt_pr1 { padding-right: 1px !important; }
  .lt_pb1 { padding-bottom: 1px !important; }
  .lt_p2 { padding: 2px !important; }
  .lt_pt2 { padding-top: 2px !important; }
  .lt_pl2 { padding-left: 2px !important; }
  .lt_pr2 { padding-right: 2px !important; }
  .lt_pb2 { padding-bottom: 2px !important; }
  .lt_p3 { padding: 3px !important; }
  .lt_pt3 { padding-top: 3px !important; }
  .lt_pl3 { padding-left: 3px !important; }
  .lt_pr3 { padding-right: 3px !important; }
  .lt_pb3 { padding-bottom: 3px !important; }
  .lt_p4 { padding: 4px !important; }
  .lt_pt4 { padding-top: 4px !important; }
  .lt_pl4 { padding-left: 4px !important; }
  .lt_pr4 { padding-right: 4px !important; }
  .lt_pb4 { padding-bottom: 4px !important; }
  .lt_p5 { padding: 5px !important; }
  .lt_pt5 { padding-top: 5px !important; }
  .lt_pl5 { padding-left: 5px !important; }
  .lt_pr5 { padding-right: 5px !important; }
  .lt_pb5 { padding-bottom: 5px !important; }
  .lt_p7 { padding: 7px !important; }
  .lt_pt7 { padding-top: 7px !important; }
  .lt_pl7 { padding-left: 7px !important; }
  .lt_pr7 { padding-right: 7px !important; }
  .lt_pb7 { padding-bottom: 7px !important; }
  .lt_p10 { padding: 10px !important; }
  .lt_pt10 { padding-top: 10px !important; }
  .lt_pl10 { padding-left: 10px !important; }
  .lt_pr10 { padding-right: 10px !important; }
  .lt_pb10 { padding-bottom: 10px !important; }
  .lt_p13 { padding: 13px !important; }
  .lt_pt13 { padding-top: 13px !important; }
  .lt_pl13 { padding-left: 13px !important; }
  .lt_pr13 { padding-right: 13px !important; }
  .lt_pb13 { padding-bottom: 13px !important; }
  .lt_p15 { padding: 15px !important; }
  .lt_pt15 { padding-top: 15px !important; }
  .lt_pl15 { padding-left: 15px !important; }
  .lt_pr15 { padding-right: 15px !important; }
  .lt_pb15 { padding-bottom: 15px !important; }
  .lt_p20 { padding: 20px !important; }
  .lt_pt20 { padding-top: 20px !important; }
  .lt_pl20 { padding-left: 20px !important; }
  .lt_pr20 { padding-right: 20px !important; }
  .lt_pb20 { padding-bottom: 20px !important; }
  .lt_p25 { padding: 25px !important; }
  .lt_pt25 { padding-top: 25px !important; }
  .lt_pl25 { padding-left: 25px !important; }
  .lt_pr25 { padding-right: 25px !important; }
  .lt_pb25 { padding-bottom: 25px !important; }
  .lt_p30 { padding: 30px !important; }
  .lt_pt30 { padding-top: 30px !important; }
  .lt_pl30 { padding-left: 30px !important; }
  .lt_pr30 { padding-right: 30px !important; }
  .lt_pb30 { padding-bottom: 30px !important; }
  .lt_p35 { padding: 35px !important; }
  .lt_pt35 { padding-top: 35px !important; }
  .lt_pl35 { padding-left: 35px !important; }
  .lt_pr35 { padding-right: 35px !important; }
  .lt_pb35 { padding-bottom: 35px !important; }

  .lt_pt10p { padding-top: 10% !important; }
  .lt_pt15p { padding-top: 15% !important; }
  .lt_pt20p { padding-top: 20% !important; }
  .lt_pt25p { padding-top: 25% !important; }
  .lt_pt33p { padding-top: 33.33333333% !important; }
  .lt_pt35p { padding-top: 35% !important; }
  .lt_pt40p { padding-top: 40% !important; }
  .lt_pt50p { padding-top: 50% !important; }
  .lt_pt60p { padding-top: 60% !important; }
  .lt_pt65p { padding-top: 65% !important; }
  .lt_pt66p { padding-top: 66.66666666% !important; }
  .lt_pt70p { padding-top: 70% !important; }
  .lt_pt75p { padding-top: 75% !important; }
  .lt_pt80p { padding-top: 80% !important; }
  .lt_pt85p { padding-top: 85% !important; }
  .lt_pt90p { padding-top: 90% !important; }
  .lt_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .lt_vat { vertical-align: top; }
  .lt_vam { vertical-align: middle; }
  .lt_vab { vertical-align: bottom; }
  .lt_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .lt_tal { text-align: left; }
  .lt_tal_i { text-align: left !important; }
  .lt_tac { text-align: center; }
  .lt_tac_i { text-align: center !important; }
  .lt_tar { text-align: right; }
  .lt_tar_i { text-align: right !important; }
  .lt_taj { text-align: justify; }
  .lt_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .lt_fsi { font-style: italic; }

  /* Font size */
  .lt_fz0 { font-size: 0; }
  .lt_fz10, .lt_fz10_a, .lt_fz10_a * { font-size: 10px; }
  .lt_fz11, .lt_fz11_a, .lt_fz11_a * { font-size: 11px; }
  .lt_fz12, .lt_fz12_a, .lt_fz12_a * { font-size: 12px; }
  .lt_fz13, .lt_fz13_a, .lt_fz13_a * { font-size: 13px; }
  .lt_fz14, .lt_fz14_a, .lt_fz14_a * { font-size: 14px; }
  .lt_fz15 { font-size: 15px; }
  .lt_fz16 { font-size: 16px; }
  .lt_fz18 { font-size: 18px; }
  .lt_fz20 { font-size: 20px; }
  .lt_fz22 { font-size: 22px; }
  .lt_fz24 { font-size: 24px; }
  .lt_fz26 { font-size: 26px; }
  .lt_fz28 { font-size: 28px; }
  .lt_fz30 { font-size: 30px; }
  .lt_fz32 { font-size: 32px; }
  .lt_fz34 { font-size: 34px; }
  .lt_fz36 { font-size: 36px; }
  .lt_fz38 { font-size: 38px; }
  .lt_fz40 { font-size: 40px; }
  .lt_fz42 { font-size: 42px; }
  .lt_fz44 { font-size: 44px; }
  .lt_fz46 { font-size: 46px; }
  .lt_fz48 { font-size: 48px; }
  .lt_fz50 { font-size: 50px; }
  .lt_fz0_i { font-size: 0 !important; }
  .lt_fz10_i, .lt_fz10_a_i, .lt_fz10_a_i * { font-size: 10px !important; }
  .lt_fz11_i, .lt_fz11_a_i, .lt_fz11_a_i * { font-size: 11px !important; }
  .lt_fz12_i, .lt_fz12_a_i, .lt_fz12_a_i * { font-size: 12px !important; }
  .lt_fz13_i, .lt_fz13_a_i, .lt_fz13_a_i * { font-size: 13px !important; }
  .lt_fz14_i, .lt_fz14_a_i, .lt_fz14_a_i * { font-size: 14px !important; }
  .lt_fz15_i { font-size: 15px !important; }
  .lt_fz16_i { font-size: 16px !important; }
  .lt_fz18_i { font-size: 18px !important; }
  .lt_fz20_i { font-size: 20px !important; }
  .lt_fz22_i { font-size: 22px !important; }
  .lt_fz24_i { font-size: 24px !important; }
  .lt_fz26_i { font-size: 26px !important; }
  .lt_fz28_i { font-size: 28px !important; }
  .lt_fz30_i { font-size: 30px !important; }
  .lt_fz32_i { font-size: 32px !important; }
  .lt_fz34_i { font-size: 34px !important; }
  .lt_fz36_i { font-size: 36px !important; }
  .lt_fz38_i { font-size: 38px !important; }
  .lt_fz40_i { font-size: 40px !important; }
  .lt_fz42_i { font-size: 42px !important; }
  .lt_fz44_i { font-size: 44px !important; }
  .lt_fz46_i { font-size: 46px !important; }
  .lt_fz48_i { font-size: 48px !important; }
  .lt_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .lt_fw900, .lt_fw900_a, .lt_fw900_a *, .lt_black, .lt_black_a, .lt_black_a * { font-weight: 900; }
  .lt_fw900_i, .lt_fw900_a_i, .lt_fw900_a_i *, .lt_black_i, .lt_black_a_i, .lt_black_a_i * { font-weight: 900 !important; }
  .lt_fw700, .lt_fw700_a, .lt_fw700_a *, .lt_bold, .lt_bold_a, .lt_bold_a * { font-weight: 700; }
  .lt_fw700_i, .lt_fw700_a_i, .lt_fw700_a_i *, .lt_bold_i, .lt_bold_a_i, .lt_bold_a_i * { font-weight: 700 !important; }
  .lt_fw600, .lt_fw600_a, .lt_fw600_a *, .lt_semibold, .lt_semibold_a, .lt_semibold_a * { font-weight: 600; }
  .lt_fw600_i, .lt_fw600_a_i, .lt_fw600_a_i *, .lt_semibold_i, .lt_semibold_a_i, .lt_semibold_a_i * { font-weight: 600 !important; }
  .lt_fw400, .lt_fw400_a, .lt_fw400_a * { font-weight: 400; }
  .lt_fw400_i, .lt_fw400_a_i, .lt_fw400_a_i * { font-weight: 400 !important; }
  .lt_fw300, .lt_fw300_a, .lt_fw300_a *, .lt_semilight, .lt_semilight_a, .lt_semilight_a * { font-weight: 300; }
  .lt_fw300_i, .lt_fw300_a_i, .lt_fw300_a_i *, .lt_semilight_i, .lt_semilight_a_i, .lt_semilight_a_i * { font-weight: 300 !important; }

  /* Line height */
  .lt_lh10 { line-height: 1; }
  .lt_lh100p { line-height: 100%; }
  .lt_lh11 { line-height: 1.1; }
  .lt_lh110p { line-height: 110%; }
  .lt_lh12 { line-height: 1.2; }
  .lt_lh120p { line-height: 120%; }
  .lt_lh13 { line-height: 1.3; }
  .lt_lh130p { line-height: 130%; }
  .lt_lh14 { line-height: 1.4; }
  .lt_lh140p { line-height: 140%; }
  .lt_lh15 { line-height: 1.5; }
  .lt_lh150p { line-height: 150%; }
  .lt_lh16 { line-height: 1.6; }
  .lt_lh160p { line-height: 160%; }
  .lt_lh17 { line-height: 1.7; }
  .lt_lh170p { line-height: 170%; }
  .lt_lh18 { line-height: 1.8; }
  .lt_lh180p { line-height: 180%; }
  .lt_lh19 { line-height: 1.9; }
  .lt_lh190p { line-height: 190%; }
  .lt_lh20 { line-height: 2; }
  .lt_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .lt_fln { float: none; }
  .lt_fll { float: left; }
  .lt_flr { float: right; }
}
@media screen and (max-width: 1023px) {
  .header-logo-container img {
    width: 52px;
    margin-right: -8px;
  }

  .header-logo-container span.first {
    font-size: 18px;
    padding-right: 3px;
  }
  .header-logo-container span.last {
    font-size: 32px;
    margin-top: -10px;
  }
    .header-logo-container span.last:first-letter {
      font-size: 42px;
    }


  .header-phones {
    padding-bottom: 6px;
  }
    .header-phones div {
      font-size: 28px;
    }

  .header-address-container {
    display: none;
  }


  .header-discount {
    margin: 0px;
  }



  .main-rotator-block-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: stretch;
  }
    .main-rotator-info-container {
      height: auto;
      padding: 10px 0px 0px;
      margin: 0px 0px -40px;
      z-index: unset;
    }
      .main-rotator-info-container > div {
        flex-direction: row;
        background: rgba(0,0,0,0.85);
        width: unset;
        height: unset;
        padding: 5px 10px 10px;
        top: unset;
        right: unset;
        position: initial;
      }
        .main-rotator-info-container > div:before {
          display: none;
        }

        .main-rotator-info-container > div > * {
          flex-grow: 1;
          justify-content: center;
          height: auto;
          margin: 5px 0px 0px;
        }
        .main-rotator-info-container > div > * + * {
          border-top-width: 0px;
          border-left-width: 1px;
        }
          .main-rotator-info-container > div a {
            font-size: 22px;
            padding: 5px 10px;
          }
            .main-rotator-info-container > div a img {
              width: 25px;
              min-width: 25px;
              margin-right: 12px;
            }


  .main-rotator-container {
    height: auto;
    margin: 0px;
  }
    .main-rotator-slide-container {
      padding: 0px;
    }
      .main-rotator-slide-image {
        height: auto;
      }
        .main-rotator-slide-image a {
          height: auto;
          padding: 35% 0% 0%;
        }

      .main-slide-code-container {
        height: auto;
        padding: 0px;
      }
        .main-slide-code-container > div {
          border-width: 10px 15px;
          max-width: unset;
          max-height: unset;
          left: auto;
          bottom: auto;
          position: initial;
        }


    #main-rotator-controls > div {
      max-width: unset;
      padding: calc((100% + 20px) * 0.35) 0% 0%;
    }
      #main-rotator-controls .slick-arrow {
        width: 44px;
        height: 30px;
        top: 50%;
        margin: -15px 0px 0px;
      }


    #main-rotator-dots > div {
      display: none;
    }



  .subheader {
    font-size: 26px;
  }
    h1.subheader {
      font-size: 28px;
    }




  .list-view-item-name {
    font-size: 18px;
  }
    .list-view-item-name a,
    .list-view-item-name span {
      padding: 8px 8px 11px;
    }


  .list-view-item-text {
    font-size: 16px;
    padding: 12px 13px 15px;
  }
    .list-view-item-notice p {
      text-align: left;
    }



  .portfolio-block-container .portfolio-item-name a {
    font-size: 22px;
    padding: 0px 10px;
  }



  .advantages-block-container > div > div {
    padding: 12px 12px 0px;
  }
    .advantage-item-image img {
      height: 60px;
    }

    .advantage-item-name {
      font-size: 18px;
      min-height: 52px;
      padding: 0px 0px 8px;
    }

    .advantage-item-notice {
      padding: 0px 0px 13px;
    }

    .advantage-item-arrow {
      display: none;
    }



  .team-block-container .list-view-item {
    width: 20%;
  }



  .main-gallery-notice-container > div {
    border-width: 15px;
    width: auto;
    max-height: unset;
  }

  .main-gallery-dots {
    display: none;
  }



  .footer-menu-container {
    display: none;
  }


  .footer {
    border-top: 2px solid #3278be;
  }
    .footer-content {
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-start;
    }
      .footer-info-menu-container {
        order: 1;
        width: 100%;
      }
        .footer-info-menu-container ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: flex-start;
          margin: -12px 0px 15px -35px;
        }
          .footer-info-menu-container ul li {
            padding: 12px 0px 0px 35px;
          }

      .footer-phones-container {
        order: 2;
      }

      .footer-left-container {
        order: 3;
      }

      .footer-copyrights {
        order: 4;
        justify-content: space-around;
        align-items: center;
      }
}

/* portrait tablet - pt */
@media screen and (max-width: 979px) {
  /* Border */
  /* ====== */
  /* Border width */
  .pt_brw0 { border-width: 0px !important; }
  .pt_brtw0 { border-top-width: 0px !important; }
  .pt_brlw0 { border-left-width: 0px !important; }
  .pt_brrw0 { border-right-width: 0px !important; }
  .pt_brbw0 { border-bottom-width: 0px !important; }
  .pt_brw1 { border-width: 1px !important; }
  .pt_brtw1 { border-top-width: 1px !important; }
  .pt_brlw1 { border-left-width: 1px !important; }
  .pt_brrw1 { border-right-width: 1px !important; }
  .pt_brbw1 { border-bottom-width: 1px !important; }

  /* Border style */
  .pt_brstn { border-style: none; }
  .pt_brsts { border-style: solid; }
  .pt_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .pt_dn { display: none; }
  .pt_dn_i { display: none !important; }
  .pt_db { display: block; }
  .pt_di { display: inline; }
  .pt_dib { display: inline-block; }
  .pt_dt { display: table; }
  .pt_dtr { display: table-row; }
  .pt_dtc { display: table-cell; }
  .pt_du { display: unset; }

  /* Flex */
  .pt_dfdr { display: flex; flex-direction: row !important; }
  .pt_dfdrr { display: flex; flex-direction: row-reverse !important; }
  .pt_dfdc { display: flex; flex-direction: column !important; }
  .pt_dfdcr { display: flex; flex-direction: column-reverse !important; }
  .pt_difdr { display: inline-flex; flex-direction: row !important; }
  .pt_difdrr { display: inline-flex; flex-direction: row-reverse !important; }
  .pt_difdc { display: inline-flex; flex-direction: column !important; }
  .pt_difdcr { display: inline-flex; flex-direction: column-reverse !important; }
  .pt_fwn { flex-wrap: nowrap !important; }
  .pt_fww { flex-wrap: wrap !important; }
  .pt_fwwr { flex-wrap: wrap-reverse !important; }
  .pt_fjcs { justify-content: flex-start !important; }
  .pt_fjce { justify-content: flex-end !important; }
  .pt_fjcc { justify-content: center !important; }
  .pt_fjcsa { justify-content: space-around !important; }
  .pt_fjcsb { justify-content: space-between !important; }
  .pt_fais { align-items: flex-start !important; }
  .pt_faie { align-items: flex-end !important; }
  .pt_faic { align-items: center !important; }
  .pt_faib { align-items: baseline !important; }
  .pt_faist { align-items: stretch !important; }
  .pt_facs { align-content: flex-start !important; }
  .pt_face { align-content: flex-end !important; }
  .pt_facc { align-content: center !important; }
  .pt_facsa { align-content: space-around !important; }
  .pt_facsb { align-content: space-between !important; }
  .pt_facst { align-content: stretch !important; }
  .pt_fass { align-self: flex-start !important; }
  .pt_fase { align-self: flex-end !important; }
  .pt_fasc { align-self: center !important; }
  .pt_fasb { align-self: baseline !important; }
  .pt_fasst { align-self: stretch !important; }
  .pt_fg0 { flex-grow: 0 !important; }
  .pt_fg1 { flex-grow: 1 !important; }
  .pt_fsh0 { flex-shrink: 0 !important; }
  .pt_fsh1 { flex-shrink: 1 !important; }

  .pt_fo0 { order: 0; }
  .pt_fo1 { order: 1; }
  .pt_fo2 { order: 2; }
  .pt_fo3 { order: 3; }
  .pt_fo4 { order: 4; }
  .pt_fo5 { order: 5; }
  .pt_fo6 { order: 6; }
  .pt_fo7 { order: 7; }
  .pt_fo8 { order: 8; }
  .pt_fo9 { order: 9; }
  .pt_fo99 { order: 99; }
  .pt_fo999 { order: 999; }
  .pt_fo9999 { order: 9999; }


  /* Height */
  /* ====== */
  .pt_h0 { height: 0; }
  .pt_h100p, .pt_hfull { height: 100%; }
  .pt_hauto { height: auto; }


  /* Width */
  /* ===== */
  .pt_w0 { width: 0; }
  .pt_w1 { width: 1px; }
  .pt_w5p { width: 5%; }
  .pt_w7p { width: 7%; }
  .pt_w10p { width: 10%; }
  .pt_w12p { width: 12.5%; }
  .pt_w15p { width: 15%; }
  .pt_w20p { width: 20%; }
  .pt_w25p { width: 25%; }
  .pt_w33p { width: 33.33333333%; }
  .pt_w35p { width: 35%; }
  .pt_w40p { width: 40%; }
  .pt_w50p { width: 50%; }
  .pt_w60p { width: 60%; }
  .pt_w65p { width: 65%; }
  .pt_w66p { width: 66.66666666%; }
  .pt_w70p { width: 70%; }
  .pt_w75p { width: 75%; }
  .pt_w80p { width: 80%; }
  .pt_w85p { width: 85%; }
  .pt_w88p { width: 87.5%; }
  .pt_w90p { width: 90%; }
  .pt_w93p { width: 93%; }
  .pt_w95p { width: 95%; }
  .pt_w100p, .pt_wfull { width: 100%; }
  .pt_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .pt_m0auto, .pt_m0a, .pt_m0auto_i, .pt_m0a_i { margin: 0 auto !important; }
  .pt_mauto0, .pt_ma0, .pt_mauto0_i, .pt_ma0_i { margin: auto 0 !important; }
  .pt_mauto, .pt_ma, .pt_mauto_i, .pt_ma_i { margin: auto !important; }
  .pt_mtauto, .pt_mta, .pt_mtauto_i, .pt_mta_i { margin-top: auto !important; }
  .pt_mlauto, .pt_mla, .pt_mlauto_i, .pt_mla_i { margin-left: auto !important; }
  .pt_mrauto, .pt_mra, .pt_mrauto_i, .pt_mra_i { margin-right: auto !important; }
  .pt_mbauto, .pt_mba, .pt_mbauto_i, .pt_mba_i { margin-bottom: auto !important; }
  .pt_m0, .pt_m0_i { margin: 0 !important; }
  .pt_mt0, .pt_mt0_i { margin-top: 0 !important; }
  .pt_ml0, .pt_ml0_i { margin-left: 0 !important; }
  .pt_mr0, .pt_mr0_i { margin-right: 0 !important; }
  .pt_mb0, .pt_mb0_i { margin-bottom: 0 !important; }
  .pt_m1 { margin: 1px !important; }
  .pt_mt1 { margin-top: 1px !important; }
  .pt_ml1 { margin-left: 1px !important; }
  .pt_mr1 { margin-right: 1px !important; }
  .pt_mb1 { margin-bottom: 1px !important; }
  .pt_m2 { margin: 2px !important; }
  .pt_mt2 { margin-top: 2px !important; }
  .pt_ml2 { margin-left: 2px !important; }
  .pt_mr2 { margin-right: 2px !important; }
  .pt_mb2 { margin-bottom: 2px !important; }
  .pt_m3 { margin: 3px !important; }
  .pt_mt3 { margin-top: 3px !important; }
  .pt_ml3 { margin-left: 3px !important; }
  .pt_mr3 { margin-right: 3px !important; }
  .pt_mb3 { margin-bottom: 3px !important; }
  .pt_m4 { margin: 4px !important; }
  .pt_mt4 { margin-top: 4px !important; }
  .pt_ml4 { margin-left: 4px !important; }
  .pt_mr4 { margin-right: 4px !important; }
  .pt_mb4 { margin-bottom: 4px !important; }
  .pt_m5 { margin: 5px !important; }
  .pt_mt5 { margin-top: 5px !important; }
  .pt_ml5 { margin-left: 5px !important; }
  .pt_mr5 { margin-right: 5px !important; }
  .pt_mb5 { margin-bottom: 5px !important; }
  .pt_m7 { margin: 7px !important; }
  .pt_mt7 { margin-top: 7px !important; }
  .pt_ml7 { margin-left: 7px !important; }
  .pt_mr7 { margin-right: 7px !important; }
  .pt_mb7 { margin-bottom: 7px !important; }
  .pt_m10 { margin: 10px !important; }
  .pt_mt10 { margin-top: 10px !important; }
  .pt_ml10 { margin-left: 10px !important; }
  .pt_mr10 { margin-right: 10px !important; }
  .pt_mb10 { margin-bottom: 10px !important; }
  .pt_m13 { margin: 13px !important; }
  .pt_mt13 { margin-top: 13px !important; }
  .pt_ml13 { margin-left: 13px !important; }
  .pt_mr13 { margin-right: 13px !important; }
  .pt_mb13 { margin-bottom: 13px !important; }
  .pt_m15 { margin: 15px !important; }
  .pt_mt15 { margin-top: 15px !important; }
  .pt_ml15 { margin-left: 15px !important; }
  .pt_mr15 { margin-right: 15px !important; }
  .pt_mb15 { margin-bottom: 15px !important; }
  .pt_m20 { margin: 20px !important; }
  .pt_mt20 { margin-top: 20px !important; }
  .pt_ml20 { margin-left: 20px !important; }
  .pt_mr20 { margin-right: 20px !important; }
  .pt_mb20 { margin-bottom: 20px !important; }
  .pt_m25 { margin: 25px !important; }
  .pt_mt25 { margin-top: 25px !important; }
  .pt_ml25 { margin-left: 25px !important; }
  .pt_mr25 { margin-right: 25px !important; }
  .pt_mb25 { margin-bottom: 25px !important; }
  .pt_m30 { margin: 30px !important; }
  .pt_mt30 { margin-top: 30px !important; }
  .pt_ml30 { margin-left: 30px !important; }
  .pt_mr30 { margin-right: 30px !important; }
  .pt_mb30 { margin-bottom: 30px !important; }
  .pt_m35 { margin: 35px !important; }
  .pt_mt35 { margin-top: 35px !important; }
  .pt_ml35 { margin-left: 35px !important; }
  .pt_mr35 { margin-right: 35px !important; }
  .pt_mb35 { margin-bottom: 35px !important; }
  .pt_m-1 { margin: -1px !important; }
  .pt_mt-1 { margin-top: -1px !important; }
  .pt_ml-1 { margin-left: -1px !important; }
  .pt_mr-1 { margin-right: -1px !important; }
  .pt_mb-1 { margin-bottom: -1px !important; }
  .pt_m-2 { margin: -2px !important; }
  .pt_mt-2 { margin-top: -2px !important; }
  .pt_ml-2 { margin-left: -2px !important; }
  .pt_mr-2 { margin-right: -2px !important; }
  .pt_mb-2 { margin-bottom: -2px !important; }
  .pt_m-3 { margin: -3px !important; }
  .pt_mt-3 { margin-top: -3px !important; }
  .pt_ml-3 { margin-left: -3px !important; }
  .pt_mr-3 { margin-right: -3px !important; }
  .pt_mb-3 { margin-bottom: -3px !important; }
  .pt_m-4 { margin: -4px !important; }
  .pt_mt-4 { margin-top: -4px !important; }
  .pt_ml-4 { margin-left: -4px !important; }
  .pt_mr-4 { margin-right: -4px !important; }
  .pt_mb-4 { margin-bottom: -4px !important; }
  .pt_m-5 { margin: -5px !important; }
  .pt_mt-5 { margin-top: -5px !important; }
  .pt_ml-5 { margin-left: -5px !important; }
  .pt_mr-5 { margin-right: -5px !important; }
  .pt_mb-5 { margin-bottom: -5px !important; }
  .pt_m-7 { margin: -7px !important; }
  .pt_mt-7 { margin-top: -7px !important; }
  .pt_ml-7 { margin-left: -7px !important; }
  .pt_mr-7 { margin-right: -7px !important; }
  .pt_mb-7 { margin-bottom: -7px !important; }
  .pt_m-10 { margin: -10px !important; }
  .pt_mt-10 { margin-top: -10px !important; }
  .pt_ml-10 { margin-left: -10px !important; }
  .pt_mr-10 { margin-right: -10px !important; }
  .pt_mb-10 { margin-bottom: -10px !important; }
  .pt_m-13 { margin: -13px !important; }
  .pt_mt-13 { margin-top: -13px !important; }
  .pt_ml-13 { margin-left: -13px !important; }
  .pt_mr-13 { margin-right: -13px !important; }
  .pt_mb-13 { margin-bottom: -13px !important; }
  .pt_m-15 { margin: -15px !important; }
  .pt_mt-15 { margin-top: -15px !important; }
  .pt_ml-15 { margin-left: -15px !important; }
  .pt_mr-15 { margin-right: -15px !important; }
  .pt_mb-15 { margin-bottom: -15px !important; }
  .pt_m-20 { margin: -20px !important; }
  .pt_mt-20 { margin-top: -20px !important; }
  .pt_ml-20 { margin-left: -20px !important; }
  .pt_mr-20 { margin-right: -20px !important; }
  .pt_mb-20 { margin-bottom: -20px !important; }
  .pt_m-25 { margin: -25px !important; }
  .pt_mt-25 { margin-top: -25px !important; }
  .pt_ml-25 { margin-left: -25px !important; }
  .pt_mr-25 { margin-right: -25px !important; }
  .pt_mb-25 { margin-bottom: -25px !important; }
  .pt_m-30 { margin: -30px !important; }
  .pt_mt-30 { margin-top: -30px !important; }
  .pt_ml-30 { margin-left: -30px !important; }
  .pt_mr-30 { margin-right: -30px !important; }
  .pt_mb-30 { margin-bottom: -30px !important; }
  .pt_m-35 { margin: -35px !important; }
  .pt_mt-35 { margin-top: -35px !important; }
  .pt_ml-35 { margin-left: -35px !important; }
  .pt_mr-35 { margin-right: -35px !important; }
  .pt_mb-35 { margin-bottom: -35px !important; }


  /* Paddings */
  /* ======== */
  .pt_p0 { padding: 0 !important; }
  .pt_pt0 { padding-top: 0 !important; }
  .pt_pl0 { padding-left: 0 !important; }
  .pt_pr0 { padding-right: 0 !important; }
  .pt_pb0 { padding-bottom: 0 !important; }
  .pt_p1 { padding: 1px !important; }
  .pt_pt1 { padding-top: 1px !important; }
  .pt_pl1 { padding-left: 1px !important; }
  .pt_pr1 { padding-right: 1px !important; }
  .pt_pb1 { padding-bottom: 1px !important; }
  .pt_p2 { padding: 2px !important; }
  .pt_pt2 { padding-top: 2px !important; }
  .pt_pl2 { padding-left: 2px !important; }
  .pt_pr2 { padding-right: 2px !important; }
  .pt_pb2 { padding-bottom: 2px !important; }
  .pt_p3 { padding: 3px !important; }
  .pt_pt3 { padding-top: 3px !important; }
  .pt_pl3 { padding-left: 3px !important; }
  .pt_pr3 { padding-right: 3px !important; }
  .pt_pb3 { padding-bottom: 3px !important; }
  .pt_p4 { padding: 4px !important; }
  .pt_pt4 { padding-top: 4px !important; }
  .pt_pl4 { padding-left: 4px !important; }
  .pt_pr4 { padding-right: 4px !important; }
  .pt_pb4 { padding-bottom: 4px !important; }
  .pt_p5 { padding: 5px !important; }
  .pt_pt5 { padding-top: 5px !important; }
  .pt_pl5 { padding-left: 5px !important; }
  .pt_pr5 { padding-right: 5px !important; }
  .pt_pb5 { padding-bottom: 5px !important; }
  .pt_p7 { padding: 7px !important; }
  .pt_pt7 { padding-top: 7px !important; }
  .pt_pl7 { padding-left: 7px !important; }
  .pt_pr7 { padding-right: 7px !important; }
  .pt_pb7 { padding-bottom: 7px !important; }
  .pt_p10 { padding: 10px !important; }
  .pt_pt10 { padding-top: 10px !important; }
  .pt_pl10 { padding-left: 10px !important; }
  .pt_pr10 { padding-right: 10px !important; }
  .pt_pb10 { padding-bottom: 10px !important; }
  .pt_p13 { padding: 13px !important; }
  .pt_pt13 { padding-top: 13px !important; }
  .pt_pl13 { padding-left: 13px !important; }
  .pt_pr13 { padding-right: 13px !important; }
  .pt_pb13 { padding-bottom: 13px !important; }
  .pt_p15 { padding: 15px !important; }
  .pt_pt15 { padding-top: 15px !important; }
  .pt_pl15 { padding-left: 15px !important; }
  .pt_pr15 { padding-right: 15px !important; }
  .pt_pb15 { padding-bottom: 15px !important; }
  .pt_p20 { padding: 20px !important; }
  .pt_pt20 { padding-top: 20px !important; }
  .pt_pl20 { padding-left: 20px !important; }
  .pt_pr20 { padding-right: 20px !important; }
  .pt_pb20 { padding-bottom: 20px !important; }
  .pt_p25 { padding: 25px !important; }
  .pt_pt25 { padding-top: 25px !important; }
  .pt_pl25 { padding-left: 25px !important; }
  .pt_pr25 { padding-right: 25px !important; }
  .pt_pb25 { padding-bottom: 25px !important; }
  .pt_p30 { padding: 30px !important; }
  .pt_pt30 { padding-top: 30px !important; }
  .pt_pl30 { padding-left: 30px !important; }
  .pt_pr30 { padding-right: 30px !important; }
  .pt_pb30 { padding-bottom: 30px !important; }
  .pt_p35 { padding: 35px !important; }
  .pt_pt35 { padding-top: 35px !important; }
  .pt_pl35 { padding-left: 35px !important; }
  .pt_pr35 { padding-right: 35px !important; }
  .pt_pb35 { padding-bottom: 35px !important; }

  .pt_pt10p { padding-top: 10% !important; }
  .pt_pt15p { padding-top: 15% !important; }
  .pt_pt20p { padding-top: 20% !important; }
  .pt_pt25p { padding-top: 25% !important; }
  .pt_pt33p { padding-top: 33.33333333% !important; }
  .pt_pt35p { padding-top: 35% !important; }
  .pt_pt40p { padding-top: 40% !important; }
  .pt_pt50p { padding-top: 50% !important; }
  .pt_pt60p { padding-top: 60% !important; }
  .pt_pt65p { padding-top: 65% !important; }
  .pt_pt66p { padding-top: 66.66666666% !important; }
  .pt_pt70p { padding-top: 70% !important; }
  .pt_pt75p { padding-top: 75% !important; }
  .pt_pt80p { padding-top: 80% !important; }
  .pt_pt85p { padding-top: 85% !important; }
  .pt_pt90p { padding-top: 90% !important; }
  .pt_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .pt_vat { vertical-align: top; }
  .pt_vam { vertical-align: middle; }
  .pt_vab { vertical-align: bottom; }
  .pt_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .pt_tal { text-align: left; }
  .pt_tal_i { text-align: left !important; }
  .pt_tac { text-align: center; }
  .pt_tac_i { text-align: center !important; }
  .pt_tar { text-align: right; }
  .pt_tar_i { text-align: right !important; }
  .pt_taj { text-align: justify; }
  .pt_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .pt_fsi { font-style: italic; }

  /* Font size */
  .pt_fz0 { font-size: 0; }
  .pt_fz10, .pt_fz10_a, .pt_fz10_a * { font-size: 10px; }
  .pt_fz11, .pt_fz11_a, .pt_fz11_a * { font-size: 11px; }
  .pt_fz12, .pt_fz12_a, .pt_fz12_a * { font-size: 12px; }
  .pt_fz13, .pt_fz13_a, .pt_fz13_a * { font-size: 13px; }
  .pt_fz14, .pt_fz14_a, .pt_fz14_a * { font-size: 14px; }
  .pt_fz15 { font-size: 15px; }
  .pt_fz16 { font-size: 16px; }
  .pt_fz18 { font-size: 18px; }
  .pt_fz20 { font-size: 20px; }
  .pt_fz22 { font-size: 22px; }
  .pt_fz24 { font-size: 24px; }
  .pt_fz26 { font-size: 26px; }
  .pt_fz28 { font-size: 28px; }
  .pt_fz30 { font-size: 30px; }
  .pt_fz32 { font-size: 32px; }
  .pt_fz34 { font-size: 34px; }
  .pt_fz36 { font-size: 36px; }
  .pt_fz38 { font-size: 38px; }
  .pt_fz40 { font-size: 40px; }
  .pt_fz42 { font-size: 42px; }
  .pt_fz44 { font-size: 44px; }
  .pt_fz46 { font-size: 46px; }
  .pt_fz48 { font-size: 48px; }
  .pt_fz50 { font-size: 50px; }
  .pt_fz0_i { font-size: 0 !important; }
  .pt_fz10_i, .pt_fz10_a_i, .pt_fz10_a_i * { font-size: 10px !important; }
  .pt_fz11_i, .pt_fz11_a_i, .pt_fz11_a_i * { font-size: 11px !important; }
  .pt_fz12_i, .pt_fz12_a_i, .pt_fz12_a_i * { font-size: 12px !important; }
  .pt_fz13_i, .pt_fz13_a_i, .pt_fz13_a_i * { font-size: 13px !important; }
  .pt_fz14_i, .pt_fz14_a_i, .pt_fz14_a_i * { font-size: 14px !important; }
  .pt_fz15_i { font-size: 15px !important; }
  .pt_fz16_i { font-size: 16px !important; }
  .pt_fz18_i { font-size: 18px !important; }
  .pt_fz20_i { font-size: 20px !important; }
  .pt_fz22_i { font-size: 22px !important; }
  .pt_fz24_i { font-size: 24px !important; }
  .pt_fz26_i { font-size: 26px !important; }
  .pt_fz28_i { font-size: 28px !important; }
  .pt_fz30_i { font-size: 30px !important; }
  .pt_fz32_i { font-size: 32px !important; }
  .pt_fz34_i { font-size: 34px !important; }
  .pt_fz36_i { font-size: 36px !important; }
  .pt_fz38_i { font-size: 38px !important; }
  .pt_fz40_i { font-size: 40px !important; }
  .pt_fz42_i { font-size: 42px !important; }
  .pt_fz44_i { font-size: 44px !important; }
  .pt_fz46_i { font-size: 46px !important; }
  .pt_fz48_i { font-size: 48px !important; }
  .pt_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .pt_fw900, .pt_fw900_a, .pt_fw900_a *, .pt_black, .pt_black_a, .pt_black_a * { font-weight: 900; }
  .pt_fw900_i, .pt_fw900_a_i, .pt_fw900_a_i *, .pt_black_i, .pt_black_a_i, .pt_black_a_i * { font-weight: 900 !important; }
  .pt_fw700, .pt_fw700_a, .pt_fw700_a *, .pt_bold, .pt_bold_a, .pt_bold_a * { font-weight: 700; }
  .pt_fw700_i, .pt_fw700_a_i, .pt_fw700_a_i *, .pt_bold_i, .pt_bold_a_i, .pt_bold_a_i * { font-weight: 700 !important; }
  .pt_fw600, .pt_fw600_a, .pt_fw600_a *, .pt_semibold, .pt_semibold_a, .pt_semibold_a * { font-weight: 600; }
  .pt_fw600_i, .pt_fw600_a_i, .pt_fw600_a_i *, .pt_semibold_i, .pt_semibold_a_i, .pt_semibold_a_i * { font-weight: 600 !important; }
  .pt_fw400, .pt_fw400_a, .pt_fw400_a * { font-weight: 400; }
  .pt_fw400_i, .pt_fw400_a_i, .pt_fw400_a_i * { font-weight: 400 !important; }
  .pt_fw300, .pt_fw300_a, .pt_fw300_a *, .pt_semilight, .pt_semilight_a, .pt_semilight_a * { font-weight: 300; }
  .pt_fw300_i, .pt_fw300_a_i, .pt_fw300_a_i *, .pt_semilight_i, .pt_semilight_a_i, .pt_semilight_a_i * { font-weight: 300 !important; }

  /* Line height */
  .pt_lh10 { line-height: 1; }
  .pt_lh100p { line-height: 100%; }
  .pt_lh11 { line-height: 1.1; }
  .pt_lh110p { line-height: 110%; }
  .pt_lh12 { line-height: 1.2; }
  .pt_lh120p { line-height: 120%; }
  .pt_lh13 { line-height: 1.3; }
  .pt_lh130p { line-height: 130%; }
  .pt_lh14 { line-height: 1.4; }
  .pt_lh140p { line-height: 140%; }
  .pt_lh15 { line-height: 1.5; }
  .pt_lh150p { line-height: 150%; }
  .pt_lh16 { line-height: 1.6; }
  .pt_lh160p { line-height: 160%; }
  .pt_lh17 { line-height: 1.7; }
  .pt_lh170p { line-height: 170%; }
  .pt_lh18 { line-height: 1.8; }
  .pt_lh180p { line-height: 180%; }
  .pt_lh19 { line-height: 1.9; }
  .pt_lh190p { line-height: 190%; }
  .pt_lh20 { line-height: 2; }
  .pt_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .pt_fln { float: none; }
  .pt_fll { float: left; }
  .pt_flr { float: right; }
}
@media screen and (max-width: 979px) {
  html {
    font-size: 17px;
    line-height: 1.35;
  }


  p {
    margin-bottom: 15px;
  }



  .form-row-captcha .captcha-container img {
    max-height: 44px;
  }



  .price-table-sum-total .price-name,
  .price-table-sum-total .price-sum {
    flex-shrink: unset;
  }
}

@media screen and (max-width: 899px) {
  .contact-map-block-container,
  .contact-map-block-container > div + div {
    display: block;
  }
    .contact-map-block-container > div,
    .contact-map-block-container > div > div {
      width: auto;
      min-width: unset;
    }

    .contact-info-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
      background: none;
      border: none;
      width: auto;
      padding: 0px 0px 30px;
      margin: -20px 0px 0px -20px;
    }
      .contact-info-content > div {
        padding: 20px 0px 0px 20px;
      }
      .contact-info-content > div:first-child:not(.contact-info-phone-container, .contact-info-address-container, .contact-info-emails-container) {
        width: 100%;
      }

    .contact-map-block-container > div + div > div .map-container > div:before {
      left: 0px;
    }
}

@media screen and (max-width: 799px) {
  .list-view-item,
  .advantages-block-container > div {
    width: 50%;
  }

  .team-block-container .list-view-item {
    width: 25%;
  }
}

/* landscape mobile - lm */
@media screen and (max-width: 767px) {
  /* Border */
  /* ====== */
  /* Border width */
  .lm_brw0 { border-width: 0px !important; }
  .lm_brtw0 { border-top-width: 0px !important; }
  .lm_brlw0 { border-left-width: 0px !important; }
  .lm_brrw0 { border-right-width: 0px !important; }
  .lm_brbw0 { border-bottom-width: 0px !important; }
  .lm_brw1 { border-width: 1px !important; }
  .lm_brtw1 { border-top-width: 1px !important; }
  .lm_brlw1 { border-left-width: 1px !important; }
  .lm_brrw1 { border-right-width: 1px !important; }
  .lm_brbw1 { border-bottom-width: 1px !important; }

  /* Border style */
  .lm_brstn { border-style: none; }
  .lm_brsts { border-style: solid; }
  .lm_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .lm_dn { display: none; }
  .lm_dn_i { display: none !important; }
  .lm_db { display: block; }
  .lm_di { display: inline; }
  .lm_dib { display: inline-block; }
  .lm_dt { display: table; }
  .lm_dtr { display: table-row; }
  .lm_dtc { display: table-cell; }
  .lm_du { display: unset; }

  /* Flex */
  .lm_dfdr { display: flex; flex-direction: row !important; }
  .lm_dfdrr { display: flex; flex-direction: row-reverse !important; }
  .lm_dfdc { display: flex; flex-direction: column !important; }
  .lm_dfdcr { display: flex; flex-direction: column-reverse !important; }
  .lm_difdr { display: inline-flex; flex-direction: row !important; }
  .lm_difdrr { display: inline-flex; flex-direction: row-reverse !important; }
  .lm_difdc { display: inline-flex; flex-direction: column !important; }
  .lm_difdcr { display: inline-flex; flex-direction: column-reverse !important; }
  .lm_fwn { flex-wrap: nowrap !important; }
  .lm_fww { flex-wrap: wrap !important; }
  .lm_fwwr { flex-wrap: wrap-reverse !important; }
  .lm_fjcs { justify-content: flex-start !important; }
  .lm_fjce { justify-content: flex-end !important; }
  .lm_fjcc { justify-content: center !important; }
  .lm_fjcsa { justify-content: space-around !important; }
  .lm_fjcsb { justify-content: space-between !important; }
  .lm_fais { align-items: flex-start !important; }
  .lm_faie { align-items: flex-end !important; }
  .lm_faic { align-items: center !important; }
  .lm_faib { align-items: baseline !important; }
  .lm_faist { align-items: stretch !important; }
  .lm_facs { align-content: flex-start !important; }
  .lm_face { align-content: flex-end !important; }
  .lm_facc { align-content: center !important; }
  .lm_facsa { align-content: space-around !important; }
  .lm_facsb { align-content: space-between !important; }
  .lm_facst { align-content: stretch !important; }
  .lm_fass { align-self: flex-start !important; }
  .lm_fase { align-self: flex-end !important; }
  .lm_fasc { align-self: center !important; }
  .lm_fasb { align-self: baseline !important; }
  .lm_fasst { align-self: stretch !important; }
  .lm_fg0 { flex-grow: 0 !important; }
  .lm_fg1 { flex-grow: 1 !important; }
  .lm_fsh0 { flex-shrink: 0 !important; }
  .lm_fsh1 { flex-shrink: 1 !important; }

  .lm_fo0 { order: 0; }
  .lm_fo1 { order: 1; }
  .lm_fo2 { order: 2; }
  .lm_fo3 { order: 3; }
  .lm_fo4 { order: 4; }
  .lm_fo5 { order: 5; }
  .lm_fo6 { order: 6; }
  .lm_fo7 { order: 7; }
  .lm_fo8 { order: 8; }
  .lm_fo9 { order: 9; }
  .lm_fo99 { order: 99; }
  .lm_fo999 { order: 999; }
  .lm_fo9999 { order: 9999; }


  /* Height */
  /* ====== */
  .lm_h0 { height: 0; }
  .lm_h100p, .lm_hfull { height: 100%; }
  .lm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .lm_w0 { width: 0; }
  .lm_w1 { width: 1px; }
  .lm_w5p { width: 5%; }
  .lm_w7p { width: 7%; }
  .lm_w10p { width: 10%; }
  .lm_w12p { width: 12.5%; }
  .lm_w15p { width: 15%; }
  .lm_w20p { width: 20%; }
  .lm_w25p { width: 25%; }
  .lm_w33p { width: 33.33333333%; }
  .lm_w35p { width: 35%; }
  .lm_w40p { width: 40%; }
  .lm_w50p { width: 50%; }
  .lm_w60p { width: 60%; }
  .lm_w65p { width: 65%; }
  .lm_w66p { width: 66.66666666%; }
  .lm_w70p { width: 70%; }
  .lm_w75p { width: 75%; }
  .lm_w80p { width: 80%; }
  .lm_w85p { width: 85%; }
  .lm_w88p { width: 87.5%; }
  .lm_w90p { width: 90%; }
  .lm_w93p { width: 93%; }
  .lm_w95p { width: 95%; }
  .lm_w100p, .lm_wfull { width: 100%; }
  .lm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .lm_m0auto, .lm_m0a, .lm_m0auto_i, .lm_m0a_i { margin: 0 auto !important; }
  .lm_mauto0, .lm_ma0, .lm_mauto0_i, .lm_ma0_i { margin: auto 0 !important; }
  .lm_mauto, .lm_ma, .lm_mauto_i, .lm_ma_i { margin: auto !important; }
  .lm_mtauto, .lm_mta, .lm_mtauto_i, .lm_mta_i { margin-top: auto !important; }
  .lm_mlauto, .lm_mla, .lm_mlauto_i, .lm_mla_i { margin-left: auto !important; }
  .lm_mrauto, .lm_mra, .lm_mrauto_i, .lm_mra_i { margin-right: auto !important; }
  .lm_mbauto, .lm_mba, .lm_mbauto_i, .lm_mba_i { margin-bottom: auto !important; }
  .lm_m0, .lm_m0_i { margin: 0 !important; }
  .lm_mt0, .lm_mt0_i { margin-top: 0 !important; }
  .lm_ml0, .lm_ml0_i { margin-left: 0 !important; }
  .lm_mr0, .lm_mr0_i { margin-right: 0 !important; }
  .lm_mb0, .lm_mb0_i { margin-bottom: 0 !important; }
  .lm_m1 { margin: 1px !important; }
  .lm_mt1 { margin-top: 1px !important; }
  .lm_ml1 { margin-left: 1px !important; }
  .lm_mr1 { margin-right: 1px !important; }
  .lm_mb1 { margin-bottom: 1px !important; }
  .lm_m2 { margin: 2px !important; }
  .lm_mt2 { margin-top: 2px !important; }
  .lm_ml2 { margin-left: 2px !important; }
  .lm_mr2 { margin-right: 2px !important; }
  .lm_mb2 { margin-bottom: 2px !important; }
  .lm_m3 { margin: 3px !important; }
  .lm_mt3 { margin-top: 3px !important; }
  .lm_ml3 { margin-left: 3px !important; }
  .lm_mr3 { margin-right: 3px !important; }
  .lm_mb3 { margin-bottom: 3px !important; }
  .lm_m4 { margin: 4px !important; }
  .lm_mt4 { margin-top: 4px !important; }
  .lm_ml4 { margin-left: 4px !important; }
  .lm_mr4 { margin-right: 4px !important; }
  .lm_mb4 { margin-bottom: 4px !important; }
  .lm_m5 { margin: 5px !important; }
  .lm_mt5 { margin-top: 5px !important; }
  .lm_ml5 { margin-left: 5px !important; }
  .lm_mr5 { margin-right: 5px !important; }
  .lm_mb5 { margin-bottom: 5px !important; }
  .lm_m7 { margin: 7px !important; }
  .lm_mt7 { margin-top: 7px !important; }
  .lm_ml7 { margin-left: 7px !important; }
  .lm_mr7 { margin-right: 7px !important; }
  .lm_mb7 { margin-bottom: 7px !important; }
  .lm_m10 { margin: 10px !important; }
  .lm_mt10 { margin-top: 10px !important; }
  .lm_ml10 { margin-left: 10px !important; }
  .lm_mr10 { margin-right: 10px !important; }
  .lm_mb10 { margin-bottom: 10px !important; }
  .lm_m13 { margin: 13px !important; }
  .lm_mt13 { margin-top: 13px !important; }
  .lm_ml13 { margin-left: 13px !important; }
  .lm_mr13 { margin-right: 13px !important; }
  .lm_mb13 { margin-bottom: 13px !important; }
  .lm_m15 { margin: 15px !important; }
  .lm_mt15 { margin-top: 15px !important; }
  .lm_ml15 { margin-left: 15px !important; }
  .lm_mr15 { margin-right: 15px !important; }
  .lm_mb15 { margin-bottom: 15px !important; }
  .lm_m20 { margin: 20px !important; }
  .lm_mt20 { margin-top: 20px !important; }
  .lm_ml20 { margin-left: 20px !important; }
  .lm_mr20 { margin-right: 20px !important; }
  .lm_mb20 { margin-bottom: 20px !important; }
  .lm_m25 { margin: 25px !important; }
  .lm_mt25 { margin-top: 25px !important; }
  .lm_ml25 { margin-left: 25px !important; }
  .lm_mr25 { margin-right: 25px !important; }
  .lm_mb25 { margin-bottom: 25px !important; }
  .lm_m30 { margin: 30px !important; }
  .lm_mt30 { margin-top: 30px !important; }
  .lm_ml30 { margin-left: 30px !important; }
  .lm_mr30 { margin-right: 30px !important; }
  .lm_mb30 { margin-bottom: 30px !important; }
  .lm_m35 { margin: 35px !important; }
  .lm_mt35 { margin-top: 35px !important; }
  .lm_ml35 { margin-left: 35px !important; }
  .lm_mr35 { margin-right: 35px !important; }
  .lm_mb35 { margin-bottom: 35px !important; }
  .lm_m-1 { margin: -1px !important; }
  .lm_mt-1 { margin-top: -1px !important; }
  .lm_ml-1 { margin-left: -1px !important; }
  .lm_mr-1 { margin-right: -1px !important; }
  .lm_mb-1 { margin-bottom: -1px !important; }
  .lm_m-2 { margin: -2px !important; }
  .lm_mt-2 { margin-top: -2px !important; }
  .lm_ml-2 { margin-left: -2px !important; }
  .lm_mr-2 { margin-right: -2px !important; }
  .lm_mb-2 { margin-bottom: -2px !important; }
  .lm_m-3 { margin: -3px !important; }
  .lm_mt-3 { margin-top: -3px !important; }
  .lm_ml-3 { margin-left: -3px !important; }
  .lm_mr-3 { margin-right: -3px !important; }
  .lm_mb-3 { margin-bottom: -3px !important; }
  .lm_m-4 { margin: -4px !important; }
  .lm_mt-4 { margin-top: -4px !important; }
  .lm_ml-4 { margin-left: -4px !important; }
  .lm_mr-4 { margin-right: -4px !important; }
  .lm_mb-4 { margin-bottom: -4px !important; }
  .lm_m-5 { margin: -5px !important; }
  .lm_mt-5 { margin-top: -5px !important; }
  .lm_ml-5 { margin-left: -5px !important; }
  .lm_mr-5 { margin-right: -5px !important; }
  .lm_mb-5 { margin-bottom: -5px !important; }
  .lm_m-7 { margin: -7px !important; }
  .lm_mt-7 { margin-top: -7px !important; }
  .lm_ml-7 { margin-left: -7px !important; }
  .lm_mr-7 { margin-right: -7px !important; }
  .lm_mb-7 { margin-bottom: -7px !important; }
  .lm_m-10 { margin: -10px !important; }
  .lm_mt-10 { margin-top: -10px !important; }
  .lm_ml-10 { margin-left: -10px !important; }
  .lm_mr-10 { margin-right: -10px !important; }
  .lm_mb-10 { margin-bottom: -10px !important; }
  .lm_m-13 { margin: -13px !important; }
  .lm_mt-13 { margin-top: -13px !important; }
  .lm_ml-13 { margin-left: -13px !important; }
  .lm_mr-13 { margin-right: -13px !important; }
  .lm_mb-13 { margin-bottom: -13px !important; }
  .lm_m-15 { margin: -15px !important; }
  .lm_mt-15 { margin-top: -15px !important; }
  .lm_ml-15 { margin-left: -15px !important; }
  .lm_mr-15 { margin-right: -15px !important; }
  .lm_mb-15 { margin-bottom: -15px !important; }
  .lm_m-20 { margin: -20px !important; }
  .lm_mt-20 { margin-top: -20px !important; }
  .lm_ml-20 { margin-left: -20px !important; }
  .lm_mr-20 { margin-right: -20px !important; }
  .lm_mb-20 { margin-bottom: -20px !important; }
  .lm_m-25 { margin: -25px !important; }
  .lm_mt-25 { margin-top: -25px !important; }
  .lm_ml-25 { margin-left: -25px !important; }
  .lm_mr-25 { margin-right: -25px !important; }
  .lm_mb-25 { margin-bottom: -25px !important; }
  .lm_m-30 { margin: -30px !important; }
  .lm_mt-30 { margin-top: -30px !important; }
  .lm_ml-30 { margin-left: -30px !important; }
  .lm_mr-30 { margin-right: -30px !important; }
  .lm_mb-30 { margin-bottom: -30px !important; }
  .lm_m-35 { margin: -35px !important; }
  .lm_mt-35 { margin-top: -35px !important; }
  .lm_ml-35 { margin-left: -35px !important; }
  .lm_mr-35 { margin-right: -35px !important; }
  .lm_mb-35 { margin-bottom: -35px !important; }


  /* Paddings */
  /* ======== */
  .lm_p0 { padding: 0 !important; }
  .lm_pt0 { padding-top: 0 !important; }
  .lm_pl0 { padding-left: 0 !important; }
  .lm_pr0 { padding-right: 0 !important; }
  .lm_pb0 { padding-bottom: 0 !important; }
  .lm_p1 { padding: 1px !important; }
  .lm_pt1 { padding-top: 1px !important; }
  .lm_pl1 { padding-left: 1px !important; }
  .lm_pr1 { padding-right: 1px !important; }
  .lm_pb1 { padding-bottom: 1px !important; }
  .lm_p2 { padding: 2px !important; }
  .lm_pt2 { padding-top: 2px !important; }
  .lm_pl2 { padding-left: 2px !important; }
  .lm_pr2 { padding-right: 2px !important; }
  .lm_pb2 { padding-bottom: 2px !important; }
  .lm_p3 { padding: 3px !important; }
  .lm_pt3 { padding-top: 3px !important; }
  .lm_pl3 { padding-left: 3px !important; }
  .lm_pr3 { padding-right: 3px !important; }
  .lm_pb3 { padding-bottom: 3px !important; }
  .lm_p4 { padding: 4px !important; }
  .lm_pt4 { padding-top: 4px !important; }
  .lm_pl4 { padding-left: 4px !important; }
  .lm_pr4 { padding-right: 4px !important; }
  .lm_pb4 { padding-bottom: 4px !important; }
  .lm_p5 { padding: 5px !important; }
  .lm_pt5 { padding-top: 5px !important; }
  .lm_pl5 { padding-left: 5px !important; }
  .lm_pr5 { padding-right: 5px !important; }
  .lm_pb5 { padding-bottom: 5px !important; }
  .lm_p7 { padding: 7px !important; }
  .lm_pt7 { padding-top: 7px !important; }
  .lm_pl7 { padding-left: 7px !important; }
  .lm_pr7 { padding-right: 7px !important; }
  .lm_pb7 { padding-bottom: 7px !important; }
  .lm_p10 { padding: 10px !important; }
  .lm_pt10 { padding-top: 10px !important; }
  .lm_pl10 { padding-left: 10px !important; }
  .lm_pr10 { padding-right: 10px !important; }
  .lm_pb10 { padding-bottom: 10px !important; }
  .lm_p13 { padding: 13px !important; }
  .lm_pt13 { padding-top: 13px !important; }
  .lm_pl13 { padding-left: 13px !important; }
  .lm_pr13 { padding-right: 13px !important; }
  .lm_pb13 { padding-bottom: 13px !important; }
  .lm_p15 { padding: 15px !important; }
  .lm_pt15 { padding-top: 15px !important; }
  .lm_pl15 { padding-left: 15px !important; }
  .lm_pr15 { padding-right: 15px !important; }
  .lm_pb15 { padding-bottom: 15px !important; }
  .lm_p20 { padding: 20px !important; }
  .lm_pt20 { padding-top: 20px !important; }
  .lm_pl20 { padding-left: 20px !important; }
  .lm_pr20 { padding-right: 20px !important; }
  .lm_pb20 { padding-bottom: 20px !important; }
  .lm_p25 { padding: 25px !important; }
  .lm_pt25 { padding-top: 25px !important; }
  .lm_pl25 { padding-left: 25px !important; }
  .lm_pr25 { padding-right: 25px !important; }
  .lm_pb25 { padding-bottom: 25px !important; }
  .lm_p30 { padding: 30px !important; }
  .lm_pt30 { padding-top: 30px !important; }
  .lm_pl30 { padding-left: 30px !important; }
  .lm_pr30 { padding-right: 30px !important; }
  .lm_pb30 { padding-bottom: 30px !important; }
  .lm_p35 { padding: 35px !important; }
  .lm_pt35 { padding-top: 35px !important; }
  .lm_pl35 { padding-left: 35px !important; }
  .lm_pr35 { padding-right: 35px !important; }
  .lm_pb35 { padding-bottom: 35px !important; }

  .lm_pt10p { padding-top: 10% !important; }
  .lm_pt15p { padding-top: 15% !important; }
  .lm_pt20p { padding-top: 20% !important; }
  .lm_pt25p { padding-top: 25% !important; }
  .lm_pt33p { padding-top: 33.33333333% !important; }
  .lm_pt35p { padding-top: 35% !important; }
  .lm_pt40p { padding-top: 40% !important; }
  .lm_pt50p { padding-top: 50% !important; }
  .lm_pt60p { padding-top: 60% !important; }
  .lm_pt65p { padding-top: 65% !important; }
  .lm_pt66p { padding-top: 66.66666666% !important; }
  .lm_pt70p { padding-top: 70% !important; }
  .lm_pt75p { padding-top: 75% !important; }
  .lm_pt80p { padding-top: 80% !important; }
  .lm_pt85p { padding-top: 85% !important; }
  .lm_pt90p { padding-top: 90% !important; }
  .lm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .lm_vat { vertical-align: top; }
  .lm_vam { vertical-align: middle; }
  .lm_vab { vertical-align: bottom; }
  .lm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .lm_tal { text-align: left; }
  .lm_tal_i { text-align: left !important; }
  .lm_tac { text-align: center; }
  .lm_tac_i { text-align: center !important; }
  .lm_tar { text-align: right; }
  .lm_tar_i { text-align: right !important; }
  .lm_taj { text-align: justify; }
  .lm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .lm_fsi { font-style: italic; }

  /* Font size */
  .lm_fz0 { font-size: 0; }
  .lm_fz10, .lm_fz10_a, .lm_fz10_a * { font-size: 10px; }
  .lm_fz11, .lm_fz11_a, .lm_fz11_a * { font-size: 11px; }
  .lm_fz12, .lm_fz12_a, .lm_fz12_a * { font-size: 12px; }
  .lm_fz13, .lm_fz13_a, .lm_fz13_a * { font-size: 13px; }
  .lm_fz14, .lm_fz14_a, .lm_fz14_a * { font-size: 14px; }
  .lm_fz15 { font-size: 15px; }
  .lm_fz16 { font-size: 16px; }
  .lm_fz18 { font-size: 18px; }
  .lm_fz20 { font-size: 20px; }
  .lm_fz22 { font-size: 22px; }
  .lm_fz24 { font-size: 24px; }
  .lm_fz26 { font-size: 26px; }
  .lm_fz28 { font-size: 28px; }
  .lm_fz30 { font-size: 30px; }
  .lm_fz32 { font-size: 32px; }
  .lm_fz34 { font-size: 34px; }
  .lm_fz36 { font-size: 36px; }
  .lm_fz38 { font-size: 38px; }
  .lm_fz40 { font-size: 40px; }
  .lm_fz42 { font-size: 42px; }
  .lm_fz44 { font-size: 44px; }
  .lm_fz46 { font-size: 46px; }
  .lm_fz48 { font-size: 48px; }
  .lm_fz50 { font-size: 50px; }
  .lm_fz0_i { font-size: 0 !important; }
  .lm_fz10_i, .lm_fz10_a_i, .lm_fz10_a_i * { font-size: 10px !important; }
  .lm_fz11_i, .lm_fz11_a_i, .lm_fz11_a_i * { font-size: 11px !important; }
  .lm_fz12_i, .lm_fz12_a_i, .lm_fz12_a_i * { font-size: 12px !important; }
  .lm_fz13_i, .lm_fz13_a_i, .lm_fz13_a_i * { font-size: 13px !important; }
  .lm_fz14_i, .lm_fz14_a_i, .lm_fz14_a_i * { font-size: 14px !important; }
  .lm_fz15_i { font-size: 15px !important; }
  .lm_fz16_i { font-size: 16px !important; }
  .lm_fz18_i { font-size: 18px !important; }
  .lm_fz20_i { font-size: 20px !important; }
  .lm_fz22_i { font-size: 22px !important; }
  .lm_fz24_i { font-size: 24px !important; }
  .lm_fz26_i { font-size: 26px !important; }
  .lm_fz28_i { font-size: 28px !important; }
  .lm_fz30_i { font-size: 30px !important; }
  .lm_fz32_i { font-size: 32px !important; }
  .lm_fz34_i { font-size: 34px !important; }
  .lm_fz36_i { font-size: 36px !important; }
  .lm_fz38_i { font-size: 38px !important; }
  .lm_fz40_i { font-size: 40px !important; }
  .lm_fz42_i { font-size: 42px !important; }
  .lm_fz44_i { font-size: 44px !important; }
  .lm_fz46_i { font-size: 46px !important; }
  .lm_fz48_i { font-size: 48px !important; }
  .lm_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .lm_fw900, .lm_fw900_a, .lm_fw900_a *, .lm_black, .lm_black_a, .lm_black_a * { font-weight: 900; }
  .lm_fw900_i, .lm_fw900_a_i, .lm_fw900_a_i *, .lm_black_i, .lm_black_a_i, .lm_black_a_i * { font-weight: 900 !important; }
  .lm_fw700, .lm_fw700_a, .lm_fw700_a *, .lm_bold, .lm_bold_a, .lm_bold_a * { font-weight: 700; }
  .lm_fw700_i, .lm_fw700_a_i, .lm_fw700_a_i *, .lm_bold_i, .lm_bold_a_i, .lm_bold_a_i * { font-weight: 700 !important; }
  .lm_fw600, .lm_fw600_a, .lm_fw600_a *, .lm_semibold, .lm_semibold_a, .lm_semibold_a * { font-weight: 600; }
  .lm_fw600_i, .lm_fw600_a_i, .lm_fw600_a_i *, .lm_semibold_i, .lm_semibold_a_i, .lm_semibold_a_i * { font-weight: 600 !important; }
  .lm_fw400, .lm_fw400_a, .lm_fw400_a * { font-weight: 400; }
  .lm_fw400_i, .lm_fw400_a_i, .lm_fw400_a_i * { font-weight: 400 !important; }
  .lm_fw300, .lm_fw300_a, .lm_fw300_a *, .lm_semilight, .lm_semilight_a, .lm_semilight_a * { font-weight: 300; }
  .lm_fw300_i, .lm_fw300_a_i, .lm_fw300_a_i *, .lm_semilight_i, .lm_semilight_a_i, .lm_semilight_a_i * { font-weight: 300 !important; }

  /* Line height */
  .lm_lh10 { line-height: 1; }
  .lm_lh100p { line-height: 100%; }
  .lm_lh11 { line-height: 1.1; }
  .lm_lh110p { line-height: 110%; }
  .lm_lh12 { line-height: 1.2; }
  .lm_lh120p { line-height: 120%; }
  .lm_lh13 { line-height: 1.3; }
  .lm_lh130p { line-height: 130%; }
  .lm_lh14 { line-height: 1.4; }
  .lm_lh140p { line-height: 140%; }
  .lm_lh15 { line-height: 1.5; }
  .lm_lh150p { line-height: 150%; }
  .lm_lh16 { line-height: 1.6; }
  .lm_lh160p { line-height: 160%; }
  .lm_lh17 { line-height: 1.7; }
  .lm_lh170p { line-height: 170%; }
  .lm_lh18 { line-height: 1.8; }
  .lm_lh180p { line-height: 180%; }
  .lm_lh19 { line-height: 1.9; }
  .lm_lh190p { line-height: 190%; }
  .lm_lh20 { line-height: 2; }
  .lm_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .lm_fln { float: none; }
  .lm_fll { float: left; }
  .lm_flr { float: right; }
}
@media screen and (max-width: 767px) {
  #callback-btn-content {
    width: 100%;
  }
  .callback-order-form-wrapper {
    width: auto;
  }



  .main-gallery-notice-container > div {
    bottom: 0px;
    margin: 0px 0px -30px;
    position: initial;
  }



  .response-block-container .list-view-item {
    width: 100%;
  }



  .map-container:before {
    padding-top: 40%;
  }



  .footer-copyrights {
    flex-flow: row wrap;
    width: 100%;
    padding: 0px !important;
  }
    .footer-copyright,
    .footer-copyright + .footer-copyright {
      padding: 20px 0px 0px 20px;
    }
}

/* large portrait mobile - lpm */
@media screen and (max-width: 699px) {
  /* Border */
  /* ====== */
  /* Border width */
  .lpm_brw0 { border-width: 0px !important; }
  .lpm_brtw0 { border-top-width: 0px !important; }
  .lpm_brlw0 { border-left-width: 0px !important; }
  .lpm_brrw0 { border-right-width: 0px !important; }
  .lpm_brbw0 { border-bottom-width: 0px !important; }
  .lpm_brw1 { border-width: 1px !important; }
  .lpm_brtw1 { border-top-width: 1px !important; }
  .lpm_brlw1 { border-left-width: 1px !important; }
  .lpm_brrw1 { border-right-width: 1px !important; }
  .lpm_brbw1 { border-bottom-width: 1px !important; }

  /* Border style */
  .lpm_brstn { border-style: none; }
  .lpm_brsts { border-style: solid; }
  .lpm_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .lpm_dn { display: none; }
  .lpm_dn_i { display: none !important; }
  .lpm_db { display: block; }
  .lpm_di { display: inline; }
  .lpm_dib { display: inline-block; }
  .lpm_dt { display: table; }
  .lpm_dtr { display: table-row; }
  .lpm_dtc { display: table-cell; }
  .lpm_du { display: unset; }

  /* Flex */
  .lpm_dfdr { display: flex; flex-direction: row !important; }
  .lpm_dfdrr { display: flex; flex-direction: row-reverse !important; }
  .lpm_dfdc { display: flex; flex-direction: column !important; }
  .lpm_dfdcr { display: flex; flex-direction: column-reverse !important; }
  .lpm_difdr { display: inline-flex; flex-direction: row !important; }
  .lpm_difdrr { display: inline-flex; flex-direction: row-reverse !important; }
  .lpm_difdc { display: inline-flex; flex-direction: column !important; }
  .lpm_difdcr { display: inline-flex; flex-direction: column-reverse !important; }
  .lpm_fwn { flex-wrap: nowrap !important; }
  .lpm_fww { flex-wrap: wrap !important; }
  .lpm_fwwr { flex-wrap: wrap-reverse !important; }
  .lpm_fjcs { justify-content: flex-start !important; }
  .lpm_fjce { justify-content: flex-end !important; }
  .lpm_fjcc { justify-content: center !important; }
  .lpm_fjcsa { justify-content: space-around !important; }
  .lpm_fjcsb { justify-content: space-between !important; }
  .lpm_fais { align-items: flex-start !important; }
  .lpm_faie { align-items: flex-end !important; }
  .lpm_faic { align-items: center !important; }
  .lpm_faib { align-items: baseline !important; }
  .lpm_faist { align-items: stretch !important; }
  .lpm_facs { align-content: flex-start !important; }
  .lpm_face { align-content: flex-end !important; }
  .lpm_facc { align-content: center !important; }
  .lpm_facsa { align-content: space-around !important; }
  .lpm_facsb { align-content: space-between !important; }
  .lpm_facst { align-content: stretch !important; }
  .lpm_fass { align-self: flex-start !important; }
  .lpm_fase { align-self: flex-end !important; }
  .lpm_fasc { align-self: center !important; }
  .lpm_fasb { align-self: baseline !important; }
  .lpm_fasst { align-self: stretch !important; }
  .lpm_fg0 { flex-grow: 0 !important; }
  .lpm_fg1 { flex-grow: 1 !important; }
  .lpm_fsh0 { flex-shrink: 0 !important; }
  .lpm_fsh1 { flex-shrink: 1 !important; }

  .lpm_fo0 { order: 0; }
  .lpm_fo1 { order: 1; }
  .lpm_fo2 { order: 2; }
  .lpm_fo3 { order: 3; }
  .lpm_fo4 { order: 4; }
  .lpm_fo5 { order: 5; }
  .lpm_fo6 { order: 6; }
  .lpm_fo7 { order: 7; }
  .lpm_fo8 { order: 8; }
  .lpm_fo9 { order: 9; }
  .lpm_fo99 { order: 99; }
  .lpm_fo999 { order: 999; }
  .lpm_fo9999 { order: 9999; }


  /* Height */
  /* ====== */
  .lpm_h0 { height: 0; }
  .lpm_h100p, .lpm_hfull { height: 100%; }
  .lpm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .lpm_w0 { width: 0; }
  .lpm_w1 { width: 1px; }
  .lpm_w5p { width: 5%; }
  .lpm_w7p { width: 7%; }
  .lpm_w10p { width: 10%; }
  .lpm_w12p { width: 12.5%; }
  .lpm_w15p { width: 15%; }
  .lpm_w20p { width: 20%; }
  .lpm_w25p { width: 25%; }
  .lpm_w33p { width: 33.33333333%; }
  .lpm_w35p { width: 35%; }
  .lpm_w40p { width: 40%; }
  .lpm_w50p { width: 50%; }
  .lpm_w60p { width: 60%; }
  .lpm_w65p { width: 65%; }
  .lpm_w66p { width: 66.66666666%; }
  .lpm_w70p { width: 70%; }
  .lpm_w75p { width: 75%; }
  .lpm_w80p { width: 80%; }
  .lpm_w85p { width: 85%; }
  .lpm_w88p { width: 87.5%; }
  .lpm_w90p { width: 90%; }
  .lpm_w93p { width: 93%; }
  .lpm_w95p { width: 95%; }
  .lpm_w100p, .lpm_wfull { width: 100%; }
  .lpm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .lpm_m0auto, .lpm_m0a, .lpm_m0auto_i, .lpm_m0a_i { margin: 0 auto !important; }
  .lpm_mauto0, .lpm_ma0, .lpm_mauto0_i, .lpm_ma0_i { margin: auto 0 !important; }
  .lpm_mauto, .lpm_ma, .lpm_mauto_i, .lpm_ma_i { margin: auto !important; }
  .lpm_mtauto, .lpm_mta, .lpm_mtauto_i, .lpm_mta_i { margin-top: auto !important; }
  .lpm_mlauto, .lpm_mla, .lpm_mlauto_i, .lpm_mla_i { margin-left: auto !important; }
  .lpm_mrauto, .lpm_mra, .lpm_mrauto_i, .lpm_mra_i { margin-right: auto !important; }
  .lpm_mbauto, .lpm_mba, .lpm_mbauto_i, .lpm_mba_i { margin-bottom: auto !important; }
  .lpm_m0, .lpm_m0_i { margin: 0 !important; }
  .lpm_mt0, .lpm_mt0_i { margin-top: 0 !important; }
  .lpm_ml0, .lpm_ml0_i { margin-left: 0 !important; }
  .lpm_mr0, .lpm_mr0_i { margin-right: 0 !important; }
  .lpm_mb0, .lpm_mb0_i { margin-bottom: 0 !important; }
  .lpm_m1 { margin: 1px !important; }
  .lpm_mt1 { margin-top: 1px !important; }
  .lpm_ml1 { margin-left: 1px !important; }
  .lpm_mr1 { margin-right: 1px !important; }
  .lpm_mb1 { margin-bottom: 1px !important; }
  .lpm_m2 { margin: 2px !important; }
  .lpm_mt2 { margin-top: 2px !important; }
  .lpm_ml2 { margin-left: 2px !important; }
  .lpm_mr2 { margin-right: 2px !important; }
  .lpm_mb2 { margin-bottom: 2px !important; }
  .lpm_m3 { margin: 3px !important; }
  .lpm_mt3 { margin-top: 3px !important; }
  .lpm_ml3 { margin-left: 3px !important; }
  .lpm_mr3 { margin-right: 3px !important; }
  .lpm_mb3 { margin-bottom: 3px !important; }
  .lpm_m4 { margin: 4px !important; }
  .lpm_mt4 { margin-top: 4px !important; }
  .lpm_ml4 { margin-left: 4px !important; }
  .lpm_mr4 { margin-right: 4px !important; }
  .lpm_mb4 { margin-bottom: 4px !important; }
  .lpm_m5 { margin: 5px !important; }
  .lpm_mt5 { margin-top: 5px !important; }
  .lpm_ml5 { margin-left: 5px !important; }
  .lpm_mr5 { margin-right: 5px !important; }
  .lpm_mb5 { margin-bottom: 5px !important; }
  .lpm_m7 { margin: 7px !important; }
  .lpm_mt7 { margin-top: 7px !important; }
  .lpm_ml7 { margin-left: 7px !important; }
  .lpm_mr7 { margin-right: 7px !important; }
  .lpm_mb7 { margin-bottom: 7px !important; }
  .lpm_m10 { margin: 10px !important; }
  .lpm_mt10 { margin-top: 10px !important; }
  .lpm_ml10 { margin-left: 10px !important; }
  .lpm_mr10 { margin-right: 10px !important; }
  .lpm_mb10 { margin-bottom: 10px !important; }
  .lpm_m13 { margin: 13px !important; }
  .lpm_mt13 { margin-top: 13px !important; }
  .lpm_ml13 { margin-left: 13px !important; }
  .lpm_mr13 { margin-right: 13px !important; }
  .lpm_mb13 { margin-bottom: 13px !important; }
  .lpm_m15 { margin: 15px !important; }
  .lpm_mt15 { margin-top: 15px !important; }
  .lpm_ml15 { margin-left: 15px !important; }
  .lpm_mr15 { margin-right: 15px !important; }
  .lpm_mb15 { margin-bottom: 15px !important; }
  .lpm_m20 { margin: 20px !important; }
  .lpm_mt20 { margin-top: 20px !important; }
  .lpm_ml20 { margin-left: 20px !important; }
  .lpm_mr20 { margin-right: 20px !important; }
  .lpm_mb20 { margin-bottom: 20px !important; }
  .lpm_m25 { margin: 25px !important; }
  .lpm_mt25 { margin-top: 25px !important; }
  .lpm_ml25 { margin-left: 25px !important; }
  .lpm_mr25 { margin-right: 25px !important; }
  .lpm_mb25 { margin-bottom: 25px !important; }
  .lpm_m30 { margin: 30px !important; }
  .lpm_mt30 { margin-top: 30px !important; }
  .lpm_ml30 { margin-left: 30px !important; }
  .lpm_mr30 { margin-right: 30px !important; }
  .lpm_mb30 { margin-bottom: 30px !important; }
  .lpm_m35 { margin: 35px !important; }
  .lpm_mt35 { margin-top: 35px !important; }
  .lpm_ml35 { margin-left: 35px !important; }
  .lpm_mr35 { margin-right: 35px !important; }
  .lpm_mb35 { margin-bottom: 35px !important; }
  .lpm_m-1 { margin: -1px !important; }
  .lpm_mt-1 { margin-top: -1px !important; }
  .lpm_ml-1 { margin-left: -1px !important; }
  .lpm_mr-1 { margin-right: -1px !important; }
  .lpm_mb-1 { margin-bottom: -1px !important; }
  .lpm_m-2 { margin: -2px !important; }
  .lpm_mt-2 { margin-top: -2px !important; }
  .lpm_ml-2 { margin-left: -2px !important; }
  .lpm_mr-2 { margin-right: -2px !important; }
  .lpm_mb-2 { margin-bottom: -2px !important; }
  .lpm_m-3 { margin: -3px !important; }
  .lpm_mt-3 { margin-top: -3px !important; }
  .lpm_ml-3 { margin-left: -3px !important; }
  .lpm_mr-3 { margin-right: -3px !important; }
  .lpm_mb-3 { margin-bottom: -3px !important; }
  .lpm_m-4 { margin: -4px !important; }
  .lpm_mt-4 { margin-top: -4px !important; }
  .lpm_ml-4 { margin-left: -4px !important; }
  .lpm_mr-4 { margin-right: -4px !important; }
  .lpm_mb-4 { margin-bottom: -4px !important; }
  .lpm_m-5 { margin: -5px !important; }
  .lpm_mt-5 { margin-top: -5px !important; }
  .lpm_ml-5 { margin-left: -5px !important; }
  .lpm_mr-5 { margin-right: -5px !important; }
  .lpm_mb-5 { margin-bottom: -5px !important; }
  .lpm_m-7 { margin: -7px !important; }
  .lpm_mt-7 { margin-top: -7px !important; }
  .lpm_ml-7 { margin-left: -7px !important; }
  .lpm_mr-7 { margin-right: -7px !important; }
  .lpm_mb-7 { margin-bottom: -7px !important; }
  .lpm_m-10 { margin: -10px !important; }
  .lpm_mt-10 { margin-top: -10px !important; }
  .lpm_ml-10 { margin-left: -10px !important; }
  .lpm_mr-10 { margin-right: -10px !important; }
  .lpm_mb-10 { margin-bottom: -10px !important; }
  .lpm_m-13 { margin: -13px !important; }
  .lpm_mt-13 { margin-top: -13px !important; }
  .lpm_ml-13 { margin-left: -13px !important; }
  .lpm_mr-13 { margin-right: -13px !important; }
  .lpm_mb-13 { margin-bottom: -13px !important; }
  .lpm_m-15 { margin: -15px !important; }
  .lpm_mt-15 { margin-top: -15px !important; }
  .lpm_ml-15 { margin-left: -15px !important; }
  .lpm_mr-15 { margin-right: -15px !important; }
  .lpm_mb-15 { margin-bottom: -15px !important; }
  .lpm_m-20 { margin: -20px !important; }
  .lpm_mt-20 { margin-top: -20px !important; }
  .lpm_ml-20 { margin-left: -20px !important; }
  .lpm_mr-20 { margin-right: -20px !important; }
  .lpm_mb-20 { margin-bottom: -20px !important; }
  .lpm_m-25 { margin: -25px !important; }
  .lpm_mt-25 { margin-top: -25px !important; }
  .lpm_ml-25 { margin-left: -25px !important; }
  .lpm_mr-25 { margin-right: -25px !important; }
  .lpm_mb-25 { margin-bottom: -25px !important; }
  .lpm_m-30 { margin: -30px !important; }
  .lpm_mt-30 { margin-top: -30px !important; }
  .lpm_ml-30 { margin-left: -30px !important; }
  .lpm_mr-30 { margin-right: -30px !important; }
  .lpm_mb-30 { margin-bottom: -30px !important; }
  .lpm_m-35 { margin: -35px !important; }
  .lpm_mt-35 { margin-top: -35px !important; }
  .lpm_ml-35 { margin-left: -35px !important; }
  .lpm_mr-35 { margin-right: -35px !important; }
  .lpm_mb-35 { margin-bottom: -35px !important; }


  /* Paddings */
  /* ======== */
  .lpm_p0 { padding: 0 !important; }
  .lpm_pt0 { padding-top: 0 !important; }
  .lpm_pl0 { padding-left: 0 !important; }
  .lpm_pr0 { padding-right: 0 !important; }
  .lpm_pb0 { padding-bottom: 0 !important; }
  .lpm_p1 { padding: 1px !important; }
  .lpm_pt1 { padding-top: 1px !important; }
  .lpm_pl1 { padding-left: 1px !important; }
  .lpm_pr1 { padding-right: 1px !important; }
  .lpm_pb1 { padding-bottom: 1px !important; }
  .lpm_p2 { padding: 2px !important; }
  .lpm_pt2 { padding-top: 2px !important; }
  .lpm_pl2 { padding-left: 2px !important; }
  .lpm_pr2 { padding-right: 2px !important; }
  .lpm_pb2 { padding-bottom: 2px !important; }
  .lpm_p3 { padding: 3px !important; }
  .lpm_pt3 { padding-top: 3px !important; }
  .lpm_pl3 { padding-left: 3px !important; }
  .lpm_pr3 { padding-right: 3px !important; }
  .lpm_pb3 { padding-bottom: 3px !important; }
  .lpm_p4 { padding: 4px !important; }
  .lpm_pt4 { padding-top: 4px !important; }
  .lpm_pl4 { padding-left: 4px !important; }
  .lpm_pr4 { padding-right: 4px !important; }
  .lpm_pb4 { padding-bottom: 4px !important; }
  .lpm_p5 { padding: 5px !important; }
  .lpm_pt5 { padding-top: 5px !important; }
  .lpm_pl5 { padding-left: 5px !important; }
  .lpm_pr5 { padding-right: 5px !important; }
  .lpm_pb5 { padding-bottom: 5px !important; }
  .lpm_p7 { padding: 7px !important; }
  .lpm_pt7 { padding-top: 7px !important; }
  .lpm_pl7 { padding-left: 7px !important; }
  .lpm_pr7 { padding-right: 7px !important; }
  .lpm_pb7 { padding-bottom: 7px !important; }
  .lpm_p10 { padding: 10px !important; }
  .lpm_pt10 { padding-top: 10px !important; }
  .lpm_pl10 { padding-left: 10px !important; }
  .lpm_pr10 { padding-right: 10px !important; }
  .lpm_pb10 { padding-bottom: 10px !important; }
  .lpm_p13 { padding: 13px !important; }
  .lpm_pt13 { padding-top: 13px !important; }
  .lpm_pl13 { padding-left: 13px !important; }
  .lpm_pr13 { padding-right: 13px !important; }
  .lpm_pb13 { padding-bottom: 13px !important; }
  .lpm_p15 { padding: 15px !important; }
  .lpm_pt15 { padding-top: 15px !important; }
  .lpm_pl15 { padding-left: 15px !important; }
  .lpm_pr15 { padding-right: 15px !important; }
  .lpm_pb15 { padding-bottom: 15px !important; }
  .lpm_p20 { padding: 20px !important; }
  .lpm_pt20 { padding-top: 20px !important; }
  .lpm_pl20 { padding-left: 20px !important; }
  .lpm_pr20 { padding-right: 20px !important; }
  .lpm_pb20 { padding-bottom: 20px !important; }
  .lpm_p25 { padding: 25px !important; }
  .lpm_pt25 { padding-top: 25px !important; }
  .lpm_pl25 { padding-left: 25px !important; }
  .lpm_pr25 { padding-right: 25px !important; }
  .lpm_pb25 { padding-bottom: 25px !important; }
  .lpm_p30 { padding: 30px !important; }
  .lpm_pt30 { padding-top: 30px !important; }
  .lpm_pl30 { padding-left: 30px !important; }
  .lpm_pr30 { padding-right: 30px !important; }
  .lpm_pb30 { padding-bottom: 30px !important; }
  .lpm_p35 { padding: 35px !important; }
  .lpm_pt35 { padding-top: 35px !important; }
  .lpm_pl35 { padding-left: 35px !important; }
  .lpm_pr35 { padding-right: 35px !important; }
  .lpm_pb35 { padding-bottom: 35px !important; }

  .lpm_pt10p { padding-top: 10% !important; }
  .lpm_pt15p { padding-top: 15% !important; }
  .lpm_pt20p { padding-top: 20% !important; }
  .lpm_pt25p { padding-top: 25% !important; }
  .lpm_pt33p { padding-top: 33.33333333% !important; }
  .lpm_pt35p { padding-top: 35% !important; }
  .lpm_pt40p { padding-top: 40% !important; }
  .lpm_pt50p { padding-top: 50% !important; }
  .lpm_pt60p { padding-top: 60% !important; }
  .lpm_pt65p { padding-top: 65% !important; }
  .lpm_pt66p { padding-top: 66.66666666% !important; }
  .lpm_pt70p { padding-top: 70% !important; }
  .lpm_pt75p { padding-top: 75% !important; }
  .lpm_pt80p { padding-top: 80% !important; }
  .lpm_pt85p { padding-top: 85% !important; }
  .lpm_pt90p { padding-top: 90% !important; }
  .lpm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .lpm_vat { vertical-align: top; }
  .lpm_vam { vertical-align: middle; }
  .lpm_vab { vertical-align: bottom; }
  .lpm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .lpm_tal { text-align: left; }
  .lpm_tal_i { text-align: left !important; }
  .lpm_tac { text-align: center; }
  .lpm_tac_i { text-align: center !important; }
  .lpm_tar { text-align: right; }
  .lpm_tar_i { text-align: right !important; }
  .lpm_taj { text-align: justify; }
  .lpm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .lpm_fsi { font-style: italic; }

  /* Font size */
  .lpm_fz0 { font-size: 0; }
  .lpm_fz10, .lpm_fz10_a, .lpm_fz10_a * { font-size: 10px; }
  .lpm_fz11, .lpm_fz11_a, .lpm_fz11_a * { font-size: 11px; }
  .lpm_fz12, .lpm_fz12_a, .lpm_fz12_a * { font-size: 12px; }
  .lpm_fz13, .lpm_fz13_a, .lpm_fz13_a * { font-size: 13px; }
  .lpm_fz14, .lpm_fz14_a, .lpm_fz14_a * { font-size: 14px; }
  .lpm_fz15 { font-size: 15px; }
  .lpm_fz16 { font-size: 16px; }
  .lpm_fz18 { font-size: 18px; }
  .lpm_fz20 { font-size: 20px; }
  .lpm_fz22 { font-size: 22px; }
  .lpm_fz24 { font-size: 24px; }
  .lpm_fz26 { font-size: 26px; }
  .lpm_fz28 { font-size: 28px; }
  .lpm_fz30 { font-size: 30px; }
  .lpm_fz32 { font-size: 32px; }
  .lpm_fz34 { font-size: 34px; }
  .lpm_fz36 { font-size: 36px; }
  .lpm_fz38 { font-size: 38px; }
  .lpm_fz40 { font-size: 40px; }
  .lpm_fz42 { font-size: 42px; }
  .lpm_fz44 { font-size: 44px; }
  .lpm_fz46 { font-size: 46px; }
  .lpm_fz48 { font-size: 48px; }
  .lpm_fz50 { font-size: 50px; }
  .lpm_fz0_i { font-size: 0 !important; }
  .lpm_fz10_i, .lpm_fz10_a_i, .lpm_fz10_a_i * { font-size: 10px !important; }
  .lpm_fz11_i, .lpm_fz11_a_i, .lpm_fz11_a_i * { font-size: 11px !important; }
  .lpm_fz12_i, .lpm_fz12_a_i, .lpm_fz12_a_i * { font-size: 12px !important; }
  .lpm_fz13_i, .lpm_fz13_a_i, .lpm_fz13_a_i * { font-size: 13px !important; }
  .lpm_fz14_i, .lpm_fz14_a_i, .lpm_fz14_a_i * { font-size: 14px !important; }
  .lpm_fz15_i { font-size: 15px !important; }
  .lpm_fz16_i { font-size: 16px !important; }
  .lpm_fz18_i { font-size: 18px !important; }
  .lpm_fz20_i { font-size: 20px !important; }
  .lpm_fz22_i { font-size: 22px !important; }
  .lpm_fz24_i { font-size: 24px !important; }
  .lpm_fz26_i { font-size: 26px !important; }
  .lpm_fz28_i { font-size: 28px !important; }
  .lpm_fz30_i { font-size: 30px !important; }
  .lpm_fz32_i { font-size: 32px !important; }
  .lpm_fz34_i { font-size: 34px !important; }
  .lpm_fz36_i { font-size: 36px !important; }
  .lpm_fz38_i { font-size: 38px !important; }
  .lpm_fz40_i { font-size: 40px !important; }
  .lpm_fz42_i { font-size: 42px !important; }
  .lpm_fz44_i { font-size: 44px !important; }
  .lpm_fz46_i { font-size: 46px !important; }
  .lpm_fz48_i { font-size: 48px !important; }
  .lpm_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .lpm_fw900, .lpm_fw900_a, .lpm_fw900_a *, .lpm_black, .lpm_black_a, .lpm_black_a * { font-weight: 900; }
  .lpm_fw900_i, .lpm_fw900_a_i, .lpm_fw900_a_i *, .lpm_black_i, .lpm_black_a_i, .lpm_black_a_i * { font-weight: 900 !important; }
  .lpm_fw700, .lpm_fw700_a, .lpm_fw700_a *, .lpm_bold, .lpm_bold_a, .lpm_bold_a * { font-weight: 700; }
  .lpm_fw700_i, .lpm_fw700_a_i, .lpm_fw700_a_i *, .lpm_bold_i, .lpm_bold_a_i, .lpm_bold_a_i * { font-weight: 700 !important; }
  .lpm_fw600, .lpm_fw600_a, .lpm_fw600_a *, .lpm_semibold, .lpm_semibold_a, .lpm_semibold_a * { font-weight: 600; }
  .lpm_fw600_i, .lpm_fw600_a_i, .lpm_fw600_a_i *, .lpm_semibold_i, .lpm_semibold_a_i, .lpm_semibold_a_i * { font-weight: 600 !important; }
  .lpm_fw400, .lpm_fw400_a, .lpm_fw400_a * { font-weight: 400; }
  .lpm_fw400_i, .lpm_fw400_a_i, .lpm_fw400_a_i * { font-weight: 400 !important; }
  .lpm_fw300, .lpm_fw300_a, .lpm_fw300_a *, .lpm_semilight, .lpm_semilight_a, .lpm_semilight_a * { font-weight: 300; }
  .lpm_fw300_i, .lpm_fw300_a_i, .lpm_fw300_a_i *, .lpm_semilight_i, .lpm_semilight_a_i, .lpm_semilight_a_i * { font-weight: 300 !important; }

  /* Line height */
  .lpm_lh10 { line-height: 1; }
  .lpm_lh100p { line-height: 100%; }
  .lpm_lh11 { line-height: 1.1; }
  .lpm_lh110p { line-height: 110%; }
  .lpm_lh12 { line-height: 1.2; }
  .lpm_lh120p { line-height: 120%; }
  .lpm_lh13 { line-height: 1.3; }
  .lpm_lh130p { line-height: 130%; }
  .lpm_lh14 { line-height: 1.4; }
  .lpm_lh140p { line-height: 140%; }
  .lpm_lh15 { line-height: 1.5; }
  .lpm_lh150p { line-height: 150%; }
  .lpm_lh16 { line-height: 1.6; }
  .lpm_lh160p { line-height: 160%; }
  .lpm_lh17 { line-height: 1.7; }
  .lpm_lh170p { line-height: 170%; }
  .lpm_lh18 { line-height: 1.8; }
  .lpm_lh180p { line-height: 180%; }
  .lpm_lh19 { line-height: 1.9; }
  .lpm_lh190p { line-height: 190%; }
  .lpm_lh20 { line-height: 2; }
  .lpm_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .lpm_fln { float: none; }
  .lpm_fll { float: left; }
  .lpm_flr { float: right; }
}
@media screen and (max-width: 699px) {
  .header-menu-button-wrapper {
    padding-right: 20px;
  }


  .header-phones {
    padding: 0px;
  }
    .header-phones div {
      font-size: 24px;
    }

  .header-worktime {
    display: none;
  }


  .header-content .callback-link {
    margin: 0px;
  }
    .header-content .callback-link:before {
      display: none;
    }



  .main-rotator-info-container {
    display: none;
  }


  .main-gallery-block-container {
    --slide-width: calc(100vw - 150px);
  }



  .main-rotator-block-container + .content-bg {
    padding-top: 30px;
  }


  .subheader {
    font-size: 23px;
  }
    h1.subheader {
      font-size: 26px;
    }



  .list-page-group-name,
  .price-table-group span {
    font-size: 22px;
  }



  .price-name,
  .price-price,
  .price-unit,
  .price-volume,
  .price-sum,
  .price-table-head .price-name,
  .price-table-head .price-price,
  .price-table-head .price-unit,
  .price-table-head .price-volume,
  .price-table-head .price-sum {
    font-size: 15px;
    font-weight: 400;
  }
    .price-name,
    .price-price,
    .price-unit,
    .price-volume,
    .price-sum,
    .price-table-subheader .price-name {
      padding-left: 2px;
      padding-right: 2px;
    }
    .price-name {
      padding-left: 10px;
    }

    .price-table-head .price-name,
    .price-table-head .price-price,
    .price-table-head .price-unit,
    .price-table-head .price-volume,
    .price-table-head .price-sum {
      padding-top: 6px;
      padding-bottom: 6px;
    }

  .price-table-sum-total .price-name,
  .price-table-sum-total .price-sum {
    font-size: 18px;
  }



  .incut {
    padding: 16px 18px 18px 95px;
  }
    .incut:before {
      width: 55px;
      left: 20px;
    }



  .footer-content {
    flex-direction: column;
    align-items: center;
  }
    .footer-left-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
}

@media screen and (max-width: 639px) {
  .team-block-container .list-view-item {
    width: 33.33333333%;
  }
}

@media screen and (max-width: 599px) {
  .header-phones div {
    font-size: 22px;
  }

  .header-content .callback-link {
    display: none;
  }



  .list-view-item {
    width: 100%;
    max-width: 388px;
  }


  .portfolio-page-block-container .portfolio-block-container {
    max-width: 388px;
    margin: auto;
  }



  .gallery-item.width-4 {
    width: 50%;
  }
}

@media screen and (max-width: 539px) {
  .form-row {
    display: block;
  }
    .form-label {
      width: auto;
      min-width: unset;
      margin: 0px 0px 6px;
    }


  .callback-form .form-buttons {
    margin-left: 0px;
  }



  .header-service-menu-container {
    min-width: 100%;
  }



  .map-container:before {
    padding-top: 60%;
  }
}

/* portrait mobile - pm */
@media screen and (max-width: 479px) {
  /* Border */
  /* ====== */
  /* Border width */
  .pm_brw0 { border-width: 0px !important; }
  .pm_brtw0 { border-top-width: 0px !important; }
  .pm_brlw0 { border-left-width: 0px !important; }
  .pm_brrw0 { border-right-width: 0px !important; }
  .pm_brbw0 { border-bottom-width: 0px !important; }
  .pm_brw1 { border-width: 1px !important; }
  .pm_brtw1 { border-top-width: 1px !important; }
  .pm_brlw1 { border-left-width: 1px !important; }
  .pm_brrw1 { border-right-width: 1px !important; }
  .pm_brbw1 { border-bottom-width: 1px !important; }

  /* Border style */
  .pm_brstn { border-style: none; }
  .pm_brsts { border-style: solid; }
  .pm_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .pm_dn { display: none; }
  .pm_dn_i { display: none !important; }
  .pm_db { display: block; }
  .pm_di { display: inline; }
  .pm_dib { display: inline-block; }
  .pm_dt { display: table; }
  .pm_dtr { display: table-row; }
  .pm_dtc { display: table-cell; }
  .pm_du { display: unset; }

  /* Flex */
  .pm_dfdr { display: flex; flex-direction: row !important; }
  .pm_dfdrr { display: flex; flex-direction: row-reverse !important; }
  .pm_dfdc { display: flex; flex-direction: column !important; }
  .pm_dfdcr { display: flex; flex-direction: column-reverse !important; }
  .pm_difdr { display: inline-flex; flex-direction: row !important; }
  .pm_difdrr { display: inline-flex; flex-direction: row-reverse !important; }
  .pm_difdc { display: inline-flex; flex-direction: column !important; }
  .pm_difdcr { display: inline-flex; flex-direction: column-reverse !important; }
  .pm_fwn { flex-wrap: nowrap !important; }
  .pm_fww { flex-wrap: wrap !important; }
  .pm_fwwr { flex-wrap: wrap-reverse !important; }
  .pm_fjcs { justify-content: flex-start !important; }
  .pm_fjce { justify-content: flex-end !important; }
  .pm_fjcc { justify-content: center !important; }
  .pm_fjcsa { justify-content: space-around !important; }
  .pm_fjcsb { justify-content: space-between !important; }
  .pm_fais { align-items: flex-start !important; }
  .pm_faie { align-items: flex-end !important; }
  .pm_faic { align-items: center !important; }
  .pm_faib { align-items: baseline !important; }
  .pm_faist { align-items: stretch !important; }
  .pm_facs { align-content: flex-start !important; }
  .pm_face { align-content: flex-end !important; }
  .pm_facc { align-content: center !important; }
  .pm_facsa { align-content: space-around !important; }
  .pm_facsb { align-content: space-between !important; }
  .pm_facst { align-content: stretch !important; }
  .pm_fass { align-self: flex-start !important; }
  .pm_fase { align-self: flex-end !important; }
  .pm_fasc { align-self: center !important; }
  .pm_fasb { align-self: baseline !important; }
  .pm_fasst { align-self: stretch !important; }
  .pm_fg0 { flex-grow: 0 !important; }
  .pm_fg1 { flex-grow: 1 !important; }
  .pm_fsh0 { flex-shrink: 0 !important; }
  .pm_fsh1 { flex-shrink: 1 !important; }

  .pm_fo0 { order: 0; }
  .pm_fo1 { order: 1; }
  .pm_fo2 { order: 2; }
  .pm_fo3 { order: 3; }
  .pm_fo4 { order: 4; }
  .pm_fo5 { order: 5; }
  .pm_fo6 { order: 6; }
  .pm_fo7 { order: 7; }
  .pm_fo8 { order: 8; }
  .pm_fo9 { order: 9; }
  .pm_fo99 { order: 99; }
  .pm_fo999 { order: 999; }
  .pm_fo9999 { order: 9999; }


  /* Height */
  /* ====== */
  .pm_h0 { height: 0; }
  .pm_h100p, .pm_hfull { height: 100%; }
  .pm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .pm_w0 { width: 0; }
  .pm_w1 { width: 1px; }
  .pm_w5p { width: 5%; }
  .pm_w7p { width: 7%; }
  .pm_w10p { width: 10%; }
  .pm_w12p { width: 12.5%; }
  .pm_w15p { width: 15%; }
  .pm_w20p { width: 20%; }
  .pm_w25p { width: 25%; }
  .pm_w33p { width: 33.33333333%; }
  .pm_w35p { width: 35%; }
  .pm_w40p { width: 40%; }
  .pm_w50p { width: 50%; }
  .pm_w60p { width: 60%; }
  .pm_w65p { width: 65%; }
  .pm_w66p { width: 66.66666666%; }
  .pm_w70p { width: 70%; }
  .pm_w75p { width: 75%; }
  .pm_w80p { width: 80%; }
  .pm_w85p { width: 85%; }
  .pm_w88p { width: 87.5%; }
  .pm_w90p { width: 90%; }
  .pm_w93p { width: 93%; }
  .pm_w95p { width: 95%; }
  .pm_w100p, .pm_wfull { width: 100%; }
  .pm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .pm_m0auto, .pm_m0a, .pm_m0auto_i, .pm_m0a_i { margin: 0 auto !important; }
  .pm_mauto0, .pm_ma0, .pm_mauto0_i, .pm_ma0_i { margin: auto 0 !important; }
  .pm_mauto, .pm_ma, .pm_mauto_i, .pm_ma_i { margin: auto !important; }
  .pm_mtauto, .pm_mta, .pm_mtauto_i, .pm_mta_i { margin-top: auto !important; }
  .pm_mlauto, .pm_mla, .pm_mlauto_i, .pm_mla_i { margin-left: auto !important; }
  .pm_mrauto, .pm_mra, .pm_mrauto_i, .pm_mra_i { margin-right: auto !important; }
  .pm_mbauto, .pm_mba, .pm_mbauto_i, .pm_mba_i { margin-bottom: auto !important; }
  .pm_m0, .pm_m0_i { margin: 0 !important; }
  .pm_mt0, .pm_mt0_i { margin-top: 0 !important; }
  .pm_ml0, .pm_ml0_i { margin-left: 0 !important; }
  .pm_mr0, .pm_mr0_i { margin-right: 0 !important; }
  .pm_mb0, .pm_mb0_i { margin-bottom: 0 !important; }
  .pm_m1 { margin: 1px !important; }
  .pm_mt1 { margin-top: 1px !important; }
  .pm_ml1 { margin-left: 1px !important; }
  .pm_mr1 { margin-right: 1px !important; }
  .pm_mb1 { margin-bottom: 1px !important; }
  .pm_m2 { margin: 2px !important; }
  .pm_mt2 { margin-top: 2px !important; }
  .pm_ml2 { margin-left: 2px !important; }
  .pm_mr2 { margin-right: 2px !important; }
  .pm_mb2 { margin-bottom: 2px !important; }
  .pm_m3 { margin: 3px !important; }
  .pm_mt3 { margin-top: 3px !important; }
  .pm_ml3 { margin-left: 3px !important; }
  .pm_mr3 { margin-right: 3px !important; }
  .pm_mb3 { margin-bottom: 3px !important; }
  .pm_m4 { margin: 4px !important; }
  .pm_mt4 { margin-top: 4px !important; }
  .pm_ml4 { margin-left: 4px !important; }
  .pm_mr4 { margin-right: 4px !important; }
  .pm_mb4 { margin-bottom: 4px !important; }
  .pm_m5 { margin: 5px !important; }
  .pm_mt5 { margin-top: 5px !important; }
  .pm_ml5 { margin-left: 5px !important; }
  .pm_mr5 { margin-right: 5px !important; }
  .pm_mb5 { margin-bottom: 5px !important; }
  .pm_m7 { margin: 7px !important; }
  .pm_mt7 { margin-top: 7px !important; }
  .pm_ml7 { margin-left: 7px !important; }
  .pm_mr7 { margin-right: 7px !important; }
  .pm_mb7 { margin-bottom: 7px !important; }
  .pm_m10 { margin: 10px !important; }
  .pm_mt10 { margin-top: 10px !important; }
  .pm_ml10 { margin-left: 10px !important; }
  .pm_mr10 { margin-right: 10px !important; }
  .pm_mb10 { margin-bottom: 10px !important; }
  .pm_m13 { margin: 13px !important; }
  .pm_mt13 { margin-top: 13px !important; }
  .pm_ml13 { margin-left: 13px !important; }
  .pm_mr13 { margin-right: 13px !important; }
  .pm_mb13 { margin-bottom: 13px !important; }
  .pm_m15 { margin: 15px !important; }
  .pm_mt15 { margin-top: 15px !important; }
  .pm_ml15 { margin-left: 15px !important; }
  .pm_mr15 { margin-right: 15px !important; }
  .pm_mb15 { margin-bottom: 15px !important; }
  .pm_m20 { margin: 20px !important; }
  .pm_mt20 { margin-top: 20px !important; }
  .pm_ml20 { margin-left: 20px !important; }
  .pm_mr20 { margin-right: 20px !important; }
  .pm_mb20 { margin-bottom: 20px !important; }
  .pm_m25 { margin: 25px !important; }
  .pm_mt25 { margin-top: 25px !important; }
  .pm_ml25 { margin-left: 25px !important; }
  .pm_mr25 { margin-right: 25px !important; }
  .pm_mb25 { margin-bottom: 25px !important; }
  .pm_m30 { margin: 30px !important; }
  .pm_mt30 { margin-top: 30px !important; }
  .pm_ml30 { margin-left: 30px !important; }
  .pm_mr30 { margin-right: 30px !important; }
  .pm_mb30 { margin-bottom: 30px !important; }
  .pm_m35 { margin: 35px !important; }
  .pm_mt35 { margin-top: 35px !important; }
  .pm_ml35 { margin-left: 35px !important; }
  .pm_mr35 { margin-right: 35px !important; }
  .pm_mb35 { margin-bottom: 35px !important; }
  .pm_m-1 { margin: -1px !important; }
  .pm_mt-1 { margin-top: -1px !important; }
  .pm_ml-1 { margin-left: -1px !important; }
  .pm_mr-1 { margin-right: -1px !important; }
  .pm_mb-1 { margin-bottom: -1px !important; }
  .pm_m-2 { margin: -2px !important; }
  .pm_mt-2 { margin-top: -2px !important; }
  .pm_ml-2 { margin-left: -2px !important; }
  .pm_mr-2 { margin-right: -2px !important; }
  .pm_mb-2 { margin-bottom: -2px !important; }
  .pm_m-3 { margin: -3px !important; }
  .pm_mt-3 { margin-top: -3px !important; }
  .pm_ml-3 { margin-left: -3px !important; }
  .pm_mr-3 { margin-right: -3px !important; }
  .pm_mb-3 { margin-bottom: -3px !important; }
  .pm_m-4 { margin: -4px !important; }
  .pm_mt-4 { margin-top: -4px !important; }
  .pm_ml-4 { margin-left: -4px !important; }
  .pm_mr-4 { margin-right: -4px !important; }
  .pm_mb-4 { margin-bottom: -4px !important; }
  .pm_m-5 { margin: -5px !important; }
  .pm_mt-5 { margin-top: -5px !important; }
  .pm_ml-5 { margin-left: -5px !important; }
  .pm_mr-5 { margin-right: -5px !important; }
  .pm_mb-5 { margin-bottom: -5px !important; }
  .pm_m-7 { margin: -7px !important; }
  .pm_mt-7 { margin-top: -7px !important; }
  .pm_ml-7 { margin-left: -7px !important; }
  .pm_mr-7 { margin-right: -7px !important; }
  .pm_mb-7 { margin-bottom: -7px !important; }
  .pm_m-10 { margin: -10px !important; }
  .pm_mt-10 { margin-top: -10px !important; }
  .pm_ml-10 { margin-left: -10px !important; }
  .pm_mr-10 { margin-right: -10px !important; }
  .pm_mb-10 { margin-bottom: -10px !important; }
  .pm_m-13 { margin: -13px !important; }
  .pm_mt-13 { margin-top: -13px !important; }
  .pm_ml-13 { margin-left: -13px !important; }
  .pm_mr-13 { margin-right: -13px !important; }
  .pm_mb-13 { margin-bottom: -13px !important; }
  .pm_m-15 { margin: -15px !important; }
  .pm_mt-15 { margin-top: -15px !important; }
  .pm_ml-15 { margin-left: -15px !important; }
  .pm_mr-15 { margin-right: -15px !important; }
  .pm_mb-15 { margin-bottom: -15px !important; }
  .pm_m-20 { margin: -20px !important; }
  .pm_mt-20 { margin-top: -20px !important; }
  .pm_ml-20 { margin-left: -20px !important; }
  .pm_mr-20 { margin-right: -20px !important; }
  .pm_mb-20 { margin-bottom: -20px !important; }
  .pm_m-25 { margin: -25px !important; }
  .pm_mt-25 { margin-top: -25px !important; }
  .pm_ml-25 { margin-left: -25px !important; }
  .pm_mr-25 { margin-right: -25px !important; }
  .pm_mb-25 { margin-bottom: -25px !important; }
  .pm_m-30 { margin: -30px !important; }
  .pm_mt-30 { margin-top: -30px !important; }
  .pm_ml-30 { margin-left: -30px !important; }
  .pm_mr-30 { margin-right: -30px !important; }
  .pm_mb-30 { margin-bottom: -30px !important; }
  .pm_m-35 { margin: -35px !important; }
  .pm_mt-35 { margin-top: -35px !important; }
  .pm_ml-35 { margin-left: -35px !important; }
  .pm_mr-35 { margin-right: -35px !important; }
  .pm_mb-35 { margin-bottom: -35px !important; }


  /* Paddings */
  /* ======== */
  .pm_p0 { padding: 0 !important; }
  .pm_pt0 { padding-top: 0 !important; }
  .pm_pl0 { padding-left: 0 !important; }
  .pm_pr0 { padding-right: 0 !important; }
  .pm_pb0 { padding-bottom: 0 !important; }
  .pm_p1 { padding: 1px !important; }
  .pm_pt1 { padding-top: 1px !important; }
  .pm_pl1 { padding-left: 1px !important; }
  .pm_pr1 { padding-right: 1px !important; }
  .pm_pb1 { padding-bottom: 1px !important; }
  .pm_p2 { padding: 2px !important; }
  .pm_pt2 { padding-top: 2px !important; }
  .pm_pl2 { padding-left: 2px !important; }
  .pm_pr2 { padding-right: 2px !important; }
  .pm_pb2 { padding-bottom: 2px !important; }
  .pm_p3 { padding: 3px !important; }
  .pm_pt3 { padding-top: 3px !important; }
  .pm_pl3 { padding-left: 3px !important; }
  .pm_pr3 { padding-right: 3px !important; }
  .pm_pb3 { padding-bottom: 3px !important; }
  .pm_p4 { padding: 4px !important; }
  .pm_pt4 { padding-top: 4px !important; }
  .pm_pl4 { padding-left: 4px !important; }
  .pm_pr4 { padding-right: 4px !important; }
  .pm_pb4 { padding-bottom: 4px !important; }
  .pm_p5 { padding: 5px !important; }
  .pm_pt5 { padding-top: 5px !important; }
  .pm_pl5 { padding-left: 5px !important; }
  .pm_pr5 { padding-right: 5px !important; }
  .pm_pb5 { padding-bottom: 5px !important; }
  .pm_p7 { padding: 7px !important; }
  .pm_pt7 { padding-top: 7px !important; }
  .pm_pl7 { padding-left: 7px !important; }
  .pm_pr7 { padding-right: 7px !important; }
  .pm_pb7 { padding-bottom: 7px !important; }
  .pm_p10 { padding: 10px !important; }
  .pm_pt10 { padding-top: 10px !important; }
  .pm_pl10 { padding-left: 10px !important; }
  .pm_pr10 { padding-right: 10px !important; }
  .pm_pb10 { padding-bottom: 10px !important; }
  .pm_p13 { padding: 13px !important; }
  .pm_pt13 { padding-top: 13px !important; }
  .pm_pl13 { padding-left: 13px !important; }
  .pm_pr13 { padding-right: 13px !important; }
  .pm_pb13 { padding-bottom: 13px !important; }
  .pm_p15 { padding: 15px !important; }
  .pm_pt15 { padding-top: 15px !important; }
  .pm_pl15 { padding-left: 15px !important; }
  .pm_pr15 { padding-right: 15px !important; }
  .pm_pb15 { padding-bottom: 15px !important; }
  .pm_p20 { padding: 20px !important; }
  .pm_pt20 { padding-top: 20px !important; }
  .pm_pl20 { padding-left: 20px !important; }
  .pm_pr20 { padding-right: 20px !important; }
  .pm_pb20 { padding-bottom: 20px !important; }
  .pm_p25 { padding: 25px !important; }
  .pm_pt25 { padding-top: 25px !important; }
  .pm_pl25 { padding-left: 25px !important; }
  .pm_pr25 { padding-right: 25px !important; }
  .pm_pb25 { padding-bottom: 25px !important; }
  .pm_p30 { padding: 30px !important; }
  .pm_pt30 { padding-top: 30px !important; }
  .pm_pl30 { padding-left: 30px !important; }
  .pm_pr30 { padding-right: 30px !important; }
  .pm_pb30 { padding-bottom: 30px !important; }
  .pm_p35 { padding: 35px !important; }
  .pm_pt35 { padding-top: 35px !important; }
  .pm_pl35 { padding-left: 35px !important; }
  .pm_pr35 { padding-right: 35px !important; }
  .pm_pb35 { padding-bottom: 35px !important; }

  .pm_pt10p { padding-top: 10% !important; }
  .pm_pt15p { padding-top: 15% !important; }
  .pm_pt20p { padding-top: 20% !important; }
  .pm_pt25p { padding-top: 25% !important; }
  .pm_pt33p { padding-top: 33.33333333% !important; }
  .pm_pt35p { padding-top: 35% !important; }
  .pm_pt40p { padding-top: 40% !important; }
  .pm_pt50p { padding-top: 50% !important; }
  .pm_pt60p { padding-top: 60% !important; }
  .pm_pt65p { padding-top: 65% !important; }
  .pm_pt66p { padding-top: 66.66666666% !important; }
  .pm_pt70p { padding-top: 70% !important; }
  .pm_pt75p { padding-top: 75% !important; }
  .pm_pt80p { padding-top: 80% !important; }
  .pm_pt85p { padding-top: 85% !important; }
  .pm_pt90p { padding-top: 90% !important; }
  .pm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .pm_vat { vertical-align: top; }
  .pm_vam { vertical-align: middle; }
  .pm_vab { vertical-align: bottom; }
  .pm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .pm_tal { text-align: left; }
  .pm_tal_i { text-align: left !important; }
  .pm_tac { text-align: center; }
  .pm_tac_i { text-align: center !important; }
  .pm_tar { text-align: right; }
  .pm_tar_i { text-align: right !important; }
  .pm_taj { text-align: justify; }
  .pm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .pm_fsi { font-style: italic; }

  /* Font size */
  .pm_fz0 { font-size: 0; }
  .pm_fz10, .pm_fz10_a, .pm_fz10_a * { font-size: 10px; }
  .pm_fz11, .pm_fz11_a, .pm_fz11_a * { font-size: 11px; }
  .pm_fz12, .pm_fz12_a, .pm_fz12_a * { font-size: 12px; }
  .pm_fz13, .pm_fz13_a, .pm_fz13_a * { font-size: 13px; }
  .pm_fz14, .pm_fz14_a, .pm_fz14_a * { font-size: 14px; }
  .pm_fz15 { font-size: 15px; }
  .pm_fz16 { font-size: 16px; }
  .pm_fz18 { font-size: 18px; }
  .pm_fz20 { font-size: 20px; }
  .pm_fz22 { font-size: 22px; }
  .pm_fz24 { font-size: 24px; }
  .pm_fz26 { font-size: 26px; }
  .pm_fz28 { font-size: 28px; }
  .pm_fz30 { font-size: 30px; }
  .pm_fz32 { font-size: 32px; }
  .pm_fz34 { font-size: 34px; }
  .pm_fz36 { font-size: 36px; }
  .pm_fz38 { font-size: 38px; }
  .pm_fz40 { font-size: 40px; }
  .pm_fz42 { font-size: 42px; }
  .pm_fz44 { font-size: 44px; }
  .pm_fz46 { font-size: 46px; }
  .pm_fz48 { font-size: 48px; }
  .pm_fz50 { font-size: 50px; }
  .pm_fz0_i { font-size: 0 !important; }
  .pm_fz10_i, .pm_fz10_a_i, .pm_fz10_a_i * { font-size: 10px !important; }
  .pm_fz11_i, .pm_fz11_a_i, .pm_fz11_a_i * { font-size: 11px !important; }
  .pm_fz12_i, .pm_fz12_a_i, .pm_fz12_a_i * { font-size: 12px !important; }
  .pm_fz13_i, .pm_fz13_a_i, .pm_fz13_a_i * { font-size: 13px !important; }
  .pm_fz14_i, .pm_fz14_a_i, .pm_fz14_a_i * { font-size: 14px !important; }
  .pm_fz15_i { font-size: 15px !important; }
  .pm_fz16_i { font-size: 16px !important; }
  .pm_fz18_i { font-size: 18px !important; }
  .pm_fz20_i { font-size: 20px !important; }
  .pm_fz22_i { font-size: 22px !important; }
  .pm_fz24_i { font-size: 24px !important; }
  .pm_fz26_i { font-size: 26px !important; }
  .pm_fz28_i { font-size: 28px !important; }
  .pm_fz30_i { font-size: 30px !important; }
  .pm_fz32_i { font-size: 32px !important; }
  .pm_fz34_i { font-size: 34px !important; }
  .pm_fz36_i { font-size: 36px !important; }
  .pm_fz38_i { font-size: 38px !important; }
  .pm_fz40_i { font-size: 40px !important; }
  .pm_fz42_i { font-size: 42px !important; }
  .pm_fz44_i { font-size: 44px !important; }
  .pm_fz46_i { font-size: 46px !important; }
  .pm_fz48_i { font-size: 48px !important; }
  .pm_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .pm_fw900, .pm_fw900_a, .pm_fw900_a *, .pm_black, .pm_black_a, .pm_black_a * { font-weight: 900; }
  .pm_fw900_i, .pm_fw900_a_i, .pm_fw900_a_i *, .pm_black_i, .pm_black_a_i, .pm_black_a_i * { font-weight: 900 !important; }
  .pm_fw700, .pm_fw700_a, .pm_fw700_a *, .pm_bold, .pm_bold_a, .pm_bold_a * { font-weight: 700; }
  .pm_fw700_i, .pm_fw700_a_i, .pm_fw700_a_i *, .pm_bold_i, .pm_bold_a_i, .pm_bold_a_i * { font-weight: 700 !important; }
  .pm_fw600, .pm_fw600_a, .pm_fw600_a *, .pm_semibold, .pm_semibold_a, .pm_semibold_a * { font-weight: 600; }
  .pm_fw600_i, .pm_fw600_a_i, .pm_fw600_a_i *, .pm_semibold_i, .pm_semibold_a_i, .pm_semibold_a_i * { font-weight: 600 !important; }
  .pm_fw400, .pm_fw400_a, .pm_fw400_a * { font-weight: 400; }
  .pm_fw400_i, .pm_fw400_a_i, .pm_fw400_a_i * { font-weight: 400 !important; }
  .pm_fw300, .pm_fw300_a, .pm_fw300_a *, .pm_semilight, .pm_semilight_a, .pm_semilight_a * { font-weight: 300; }
  .pm_fw300_i, .pm_fw300_a_i, .pm_fw300_a_i *, .pm_semilight_i, .pm_semilight_a_i, .pm_semilight_a_i * { font-weight: 300 !important; }

  /* Line height */
  .pm_lh10 { line-height: 1; }
  .pm_lh100p { line-height: 100%; }
  .pm_lh11 { line-height: 1.1; }
  .pm_lh110p { line-height: 110%; }
  .pm_lh12 { line-height: 1.2; }
  .pm_lh120p { line-height: 120%; }
  .pm_lh13 { line-height: 1.3; }
  .pm_lh130p { line-height: 130%; }
  .pm_lh14 { line-height: 1.4; }
  .pm_lh140p { line-height: 140%; }
  .pm_lh15 { line-height: 1.5; }
  .pm_lh150p { line-height: 150%; }
  .pm_lh16 { line-height: 1.6; }
  .pm_lh160p { line-height: 160%; }
  .pm_lh17 { line-height: 1.7; }
  .pm_lh170p { line-height: 170%; }
  .pm_lh18 { line-height: 1.8; }
  .pm_lh180p { line-height: 180%; }
  .pm_lh19 { line-height: 1.9; }
  .pm_lh190p { line-height: 190%; }
  .pm_lh20 { line-height: 2; }
  .pm_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .pm_fln { float: none; }
  .pm_fll { float: left; }
  .pm_flr { float: right; }
}
@media screen and (max-width: 479px) {
  p {
    text-align: left;
  }



  .form-popup-container {
    width: 100%;
  }
    .form-popup-container .form-block-container {
      min-width: unset;
    }



  .team-block-container .list-view-item {
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  .header-phones-container {
    display: none;
  }

  .header-content .callback-link {
    display: block;
    padding: 5px;
  }
    .header-content .callback-link:before {
      background-size: contain;
      display: block;
      width: 32px;
      height: 32px;
    }
    .header-content .callback-link > * {
      display: none;
    }



  /*.advantages-block-container > div {
    width: 100%;
  }*/
}

/* small mobile - sm */
@media screen and (max-width: 359px) {
  /* Border */
  /* ====== */
  /* Border width */
  .sm_brw0 { border-width: 0px !important; }
  .sm_brtw0 { border-top-width: 0px !important; }
  .sm_brlw0 { border-left-width: 0px !important; }
  .sm_brrw0 { border-right-width: 0px !important; }
  .sm_brbw0 { border-bottom-width: 0px !important; }
  .sm_brw1 { border-width: 1px !important; }
  .sm_brtw1 { border-top-width: 1px !important; }
  .sm_brlw1 { border-left-width: 1px !important; }
  .sm_brrw1 { border-right-width: 1px !important; }
  .sm_brbw1 { border-bottom-width: 1px !important; }

  /* Border style */
  .sm_brstn { border-style: none; }
  .sm_brsts { border-style: solid; }
  .sm_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .sm_dn { display: none; }
  .sm_dn_i { display: none !important; }
  .sm_db { display: block; }
  .sm_di { display: inline; }
  .sm_dib { display: inline-block; }
  .sm_dt { display: table; }
  .sm_dtr { display: table-row; }
  .sm_dtc { display: table-cell; }
  .sm_du { display: unset; }

  /* Flex */
  .sm_dfdr { display: flex; flex-direction: row !important; }
  .sm_dfdrr { display: flex; flex-direction: row-reverse !important; }
  .sm_dfdc { display: flex; flex-direction: column !important; }
  .sm_dfdcr { display: flex; flex-direction: column-reverse !important; }
  .sm_difdr { display: inline-flex; flex-direction: row !important; }
  .sm_difdrr { display: inline-flex; flex-direction: row-reverse !important; }
  .sm_difdc { display: inline-flex; flex-direction: column !important; }
  .sm_difdcr { display: inline-flex; flex-direction: column-reverse !important; }
  .sm_fwn { flex-wrap: nowrap !important; }
  .sm_fww { flex-wrap: wrap !important; }
  .sm_fwwr { flex-wrap: wrap-reverse !important; }
  .sm_fjcs { justify-content: flex-start !important; }
  .sm_fjce { justify-content: flex-end !important; }
  .sm_fjcc { justify-content: center !important; }
  .sm_fjcsa { justify-content: space-around !important; }
  .sm_fjcsb { justify-content: space-between !important; }
  .sm_fais { align-items: flex-start !important; }
  .sm_faie { align-items: flex-end !important; }
  .sm_faic { align-items: center !important; }
  .sm_faib { align-items: baseline !important; }
  .sm_faist { align-items: stretch !important; }
  .sm_facs { align-content: flex-start !important; }
  .sm_face { align-content: flex-end !important; }
  .sm_facc { align-content: center !important; }
  .sm_facsa { align-content: space-around !important; }
  .sm_facsb { align-content: space-between !important; }
  .sm_facst { align-content: stretch !important; }
  .sm_fass { align-self: flex-start !important; }
  .sm_fase { align-self: flex-end !important; }
  .sm_fasc { align-self: center !important; }
  .sm_fasb { align-self: baseline !important; }
  .sm_fasst { align-self: stretch !important; }
  .sm_fg0 { flex-grow: 0 !important; }
  .sm_fg1 { flex-grow: 1 !important; }
  .sm_fsh0 { flex-shrink: 0 !important; }
  .sm_fsh1 { flex-shrink: 1 !important; }

  .sm_fo0 { order: 0; }
  .sm_fo1 { order: 1; }
  .sm_fo2 { order: 2; }
  .sm_fo3 { order: 3; }
  .sm_fo4 { order: 4; }
  .sm_fo5 { order: 5; }
  .sm_fo6 { order: 6; }
  .sm_fo7 { order: 7; }
  .sm_fo8 { order: 8; }
  .sm_fo9 { order: 9; }
  .sm_fo99 { order: 99; }
  .sm_fo999 { order: 999; }
  .sm_fo9999 { order: 9999; }


  /* Height */
  /* ====== */
  .sm_h0 { height: 0; }
  .sm_h100p, .sm_hfull { height: 100%; }
  .sm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .sm_w0 { width: 0; }
  .sm_w1 { width: 1px; }
  .sm_w5p { width: 5%; }
  .sm_w7p { width: 7%; }
  .sm_w10p { width: 10%; }
  .sm_w12p { width: 12.5%; }
  .sm_w15p { width: 15%; }
  .sm_w20p { width: 20%; }
  .sm_w25p { width: 25%; }
  .sm_w33p { width: 33.33333333%; }
  .sm_w35p { width: 35%; }
  .sm_w40p { width: 40%; }
  .sm_w50p { width: 50%; }
  .sm_w60p { width: 60%; }
  .sm_w65p { width: 65%; }
  .sm_w66p { width: 66.66666666%; }
  .sm_w70p { width: 70%; }
  .sm_w75p { width: 75%; }
  .sm_w80p { width: 80%; }
  .sm_w85p { width: 85%; }
  .sm_w88p { width: 87.5%; }
  .sm_w90p { width: 90%; }
  .sm_w93p { width: 93%; }
  .sm_w95p { width: 95%; }
  .sm_w100p, .sm_wfull { width: 100%; }
  .sm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .sm_m0auto, .sm_m0a, .sm_m0auto_i, .sm_m0a_i { margin: 0 auto !important; }
  .sm_mauto0, .sm_ma0, .sm_mauto0_i, .sm_ma0_i { margin: auto 0 !important; }
  .sm_mauto, .sm_ma, .sm_mauto_i, .sm_ma_i { margin: auto !important; }
  .sm_mtauto, .sm_mta, .sm_mtauto_i, .sm_mta_i { margin-top: auto !important; }
  .sm_mlauto, .sm_mla, .sm_mlauto_i, .sm_mla_i { margin-left: auto !important; }
  .sm_mrauto, .sm_mra, .sm_mrauto_i, .sm_mra_i { margin-right: auto !important; }
  .sm_mbauto, .sm_mba, .sm_mbauto_i, .sm_mba_i { margin-bottom: auto !important; }
  .sm_m0, .sm_m0_i { margin: 0 !important; }
  .sm_mt0, .sm_mt0_i { margin-top: 0 !important; }
  .sm_ml0, .sm_ml0_i { margin-left: 0 !important; }
  .sm_mr0, .sm_mr0_i { margin-right: 0 !important; }
  .sm_mb0, .sm_mb0_i { margin-bottom: 0 !important; }
  .sm_m1 { margin: 1px !important; }
  .sm_mt1 { margin-top: 1px !important; }
  .sm_ml1 { margin-left: 1px !important; }
  .sm_mr1 { margin-right: 1px !important; }
  .sm_mb1 { margin-bottom: 1px !important; }
  .sm_m2 { margin: 2px !important; }
  .sm_mt2 { margin-top: 2px !important; }
  .sm_ml2 { margin-left: 2px !important; }
  .sm_mr2 { margin-right: 2px !important; }
  .sm_mb2 { margin-bottom: 2px !important; }
  .sm_m3 { margin: 3px !important; }
  .sm_mt3 { margin-top: 3px !important; }
  .sm_ml3 { margin-left: 3px !important; }
  .sm_mr3 { margin-right: 3px !important; }
  .sm_mb3 { margin-bottom: 3px !important; }
  .sm_m4 { margin: 4px !important; }
  .sm_mt4 { margin-top: 4px !important; }
  .sm_ml4 { margin-left: 4px !important; }
  .sm_mr4 { margin-right: 4px !important; }
  .sm_mb4 { margin-bottom: 4px !important; }
  .sm_m5 { margin: 5px !important; }
  .sm_mt5 { margin-top: 5px !important; }
  .sm_ml5 { margin-left: 5px !important; }
  .sm_mr5 { margin-right: 5px !important; }
  .sm_mb5 { margin-bottom: 5px !important; }
  .sm_m7 { margin: 7px !important; }
  .sm_mt7 { margin-top: 7px !important; }
  .sm_ml7 { margin-left: 7px !important; }
  .sm_mr7 { margin-right: 7px !important; }
  .sm_mb7 { margin-bottom: 7px !important; }
  .sm_m10 { margin: 10px !important; }
  .sm_mt10 { margin-top: 10px !important; }
  .sm_ml10 { margin-left: 10px !important; }
  .sm_mr10 { margin-right: 10px !important; }
  .sm_mb10 { margin-bottom: 10px !important; }
  .sm_m13 { margin: 13px !important; }
  .sm_mt13 { margin-top: 13px !important; }
  .sm_ml13 { margin-left: 13px !important; }
  .sm_mr13 { margin-right: 13px !important; }
  .sm_mb13 { margin-bottom: 13px !important; }
  .sm_m15 { margin: 15px !important; }
  .sm_mt15 { margin-top: 15px !important; }
  .sm_ml15 { margin-left: 15px !important; }
  .sm_mr15 { margin-right: 15px !important; }
  .sm_mb15 { margin-bottom: 15px !important; }
  .sm_m20 { margin: 20px !important; }
  .sm_mt20 { margin-top: 20px !important; }
  .sm_ml20 { margin-left: 20px !important; }
  .sm_mr20 { margin-right: 20px !important; }
  .sm_mb20 { margin-bottom: 20px !important; }
  .sm_m25 { margin: 25px !important; }
  .sm_mt25 { margin-top: 25px !important; }
  .sm_ml25 { margin-left: 25px !important; }
  .sm_mr25 { margin-right: 25px !important; }
  .sm_mb25 { margin-bottom: 25px !important; }
  .sm_m30 { margin: 30px !important; }
  .sm_mt30 { margin-top: 30px !important; }
  .sm_ml30 { margin-left: 30px !important; }
  .sm_mr30 { margin-right: 30px !important; }
  .sm_mb30 { margin-bottom: 30px !important; }
  .sm_m35 { margin: 35px !important; }
  .sm_mt35 { margin-top: 35px !important; }
  .sm_ml35 { margin-left: 35px !important; }
  .sm_mr35 { margin-right: 35px !important; }
  .sm_mb35 { margin-bottom: 35px !important; }
  .sm_m-1 { margin: -1px !important; }
  .sm_mt-1 { margin-top: -1px !important; }
  .sm_ml-1 { margin-left: -1px !important; }
  .sm_mr-1 { margin-right: -1px !important; }
  .sm_mb-1 { margin-bottom: -1px !important; }
  .sm_m-2 { margin: -2px !important; }
  .sm_mt-2 { margin-top: -2px !important; }
  .sm_ml-2 { margin-left: -2px !important; }
  .sm_mr-2 { margin-right: -2px !important; }
  .sm_mb-2 { margin-bottom: -2px !important; }
  .sm_m-3 { margin: -3px !important; }
  .sm_mt-3 { margin-top: -3px !important; }
  .sm_ml-3 { margin-left: -3px !important; }
  .sm_mr-3 { margin-right: -3px !important; }
  .sm_mb-3 { margin-bottom: -3px !important; }
  .sm_m-4 { margin: -4px !important; }
  .sm_mt-4 { margin-top: -4px !important; }
  .sm_ml-4 { margin-left: -4px !important; }
  .sm_mr-4 { margin-right: -4px !important; }
  .sm_mb-4 { margin-bottom: -4px !important; }
  .sm_m-5 { margin: -5px !important; }
  .sm_mt-5 { margin-top: -5px !important; }
  .sm_ml-5 { margin-left: -5px !important; }
  .sm_mr-5 { margin-right: -5px !important; }
  .sm_mb-5 { margin-bottom: -5px !important; }
  .sm_m-7 { margin: -7px !important; }
  .sm_mt-7 { margin-top: -7px !important; }
  .sm_ml-7 { margin-left: -7px !important; }
  .sm_mr-7 { margin-right: -7px !important; }
  .sm_mb-7 { margin-bottom: -7px !important; }
  .sm_m-10 { margin: -10px !important; }
  .sm_mt-10 { margin-top: -10px !important; }
  .sm_ml-10 { margin-left: -10px !important; }
  .sm_mr-10 { margin-right: -10px !important; }
  .sm_mb-10 { margin-bottom: -10px !important; }
  .sm_m-13 { margin: -13px !important; }
  .sm_mt-13 { margin-top: -13px !important; }
  .sm_ml-13 { margin-left: -13px !important; }
  .sm_mr-13 { margin-right: -13px !important; }
  .sm_mb-13 { margin-bottom: -13px !important; }
  .sm_m-15 { margin: -15px !important; }
  .sm_mt-15 { margin-top: -15px !important; }
  .sm_ml-15 { margin-left: -15px !important; }
  .sm_mr-15 { margin-right: -15px !important; }
  .sm_mb-15 { margin-bottom: -15px !important; }
  .sm_m-20 { margin: -20px !important; }
  .sm_mt-20 { margin-top: -20px !important; }
  .sm_ml-20 { margin-left: -20px !important; }
  .sm_mr-20 { margin-right: -20px !important; }
  .sm_mb-20 { margin-bottom: -20px !important; }
  .sm_m-25 { margin: -25px !important; }
  .sm_mt-25 { margin-top: -25px !important; }
  .sm_ml-25 { margin-left: -25px !important; }
  .sm_mr-25 { margin-right: -25px !important; }
  .sm_mb-25 { margin-bottom: -25px !important; }
  .sm_m-30 { margin: -30px !important; }
  .sm_mt-30 { margin-top: -30px !important; }
  .sm_ml-30 { margin-left: -30px !important; }
  .sm_mr-30 { margin-right: -30px !important; }
  .sm_mb-30 { margin-bottom: -30px !important; }
  .sm_m-35 { margin: -35px !important; }
  .sm_mt-35 { margin-top: -35px !important; }
  .sm_ml-35 { margin-left: -35px !important; }
  .sm_mr-35 { margin-right: -35px !important; }
  .sm_mb-35 { margin-bottom: -35px !important; }


  /* Paddings */
  /* ======== */
  .sm_p0 { padding: 0 !important; }
  .sm_pt0 { padding-top: 0 !important; }
  .sm_pl0 { padding-left: 0 !important; }
  .sm_pr0 { padding-right: 0 !important; }
  .sm_pb0 { padding-bottom: 0 !important; }
  .sm_p1 { padding: 1px !important; }
  .sm_pt1 { padding-top: 1px !important; }
  .sm_pl1 { padding-left: 1px !important; }
  .sm_pr1 { padding-right: 1px !important; }
  .sm_pb1 { padding-bottom: 1px !important; }
  .sm_p2 { padding: 2px !important; }
  .sm_pt2 { padding-top: 2px !important; }
  .sm_pl2 { padding-left: 2px !important; }
  .sm_pr2 { padding-right: 2px !important; }
  .sm_pb2 { padding-bottom: 2px !important; }
  .sm_p3 { padding: 3px !important; }
  .sm_pt3 { padding-top: 3px !important; }
  .sm_pl3 { padding-left: 3px !important; }
  .sm_pr3 { padding-right: 3px !important; }
  .sm_pb3 { padding-bottom: 3px !important; }
  .sm_p4 { padding: 4px !important; }
  .sm_pt4 { padding-top: 4px !important; }
  .sm_pl4 { padding-left: 4px !important; }
  .sm_pr4 { padding-right: 4px !important; }
  .sm_pb4 { padding-bottom: 4px !important; }
  .sm_p5 { padding: 5px !important; }
  .sm_pt5 { padding-top: 5px !important; }
  .sm_pl5 { padding-left: 5px !important; }
  .sm_pr5 { padding-right: 5px !important; }
  .sm_pb5 { padding-bottom: 5px !important; }
  .sm_p7 { padding: 7px !important; }
  .sm_pt7 { padding-top: 7px !important; }
  .sm_pl7 { padding-left: 7px !important; }
  .sm_pr7 { padding-right: 7px !important; }
  .sm_pb7 { padding-bottom: 7px !important; }
  .sm_p10 { padding: 10px !important; }
  .sm_pt10 { padding-top: 10px !important; }
  .sm_pl10 { padding-left: 10px !important; }
  .sm_pr10 { padding-right: 10px !important; }
  .sm_pb10 { padding-bottom: 10px !important; }
  .sm_p13 { padding: 13px !important; }
  .sm_pt13 { padding-top: 13px !important; }
  .sm_pl13 { padding-left: 13px !important; }
  .sm_pr13 { padding-right: 13px !important; }
  .sm_pb13 { padding-bottom: 13px !important; }
  .sm_p15 { padding: 15px !important; }
  .sm_pt15 { padding-top: 15px !important; }
  .sm_pl15 { padding-left: 15px !important; }
  .sm_pr15 { padding-right: 15px !important; }
  .sm_pb15 { padding-bottom: 15px !important; }
  .sm_p20 { padding: 20px !important; }
  .sm_pt20 { padding-top: 20px !important; }
  .sm_pl20 { padding-left: 20px !important; }
  .sm_pr20 { padding-right: 20px !important; }
  .sm_pb20 { padding-bottom: 20px !important; }
  .sm_p25 { padding: 25px !important; }
  .sm_pt25 { padding-top: 25px !important; }
  .sm_pl25 { padding-left: 25px !important; }
  .sm_pr25 { padding-right: 25px !important; }
  .sm_pb25 { padding-bottom: 25px !important; }
  .sm_p30 { padding: 30px !important; }
  .sm_pt30 { padding-top: 30px !important; }
  .sm_pl30 { padding-left: 30px !important; }
  .sm_pr30 { padding-right: 30px !important; }
  .sm_pb30 { padding-bottom: 30px !important; }
  .sm_p35 { padding: 35px !important; }
  .sm_pt35 { padding-top: 35px !important; }
  .sm_pl35 { padding-left: 35px !important; }
  .sm_pr35 { padding-right: 35px !important; }
  .sm_pb35 { padding-bottom: 35px !important; }

  .sm_pt10p { padding-top: 10% !important; }
  .sm_pt15p { padding-top: 15% !important; }
  .sm_pt20p { padding-top: 20% !important; }
  .sm_pt25p { padding-top: 25% !important; }
  .sm_pt33p { padding-top: 33.33333333% !important; }
  .sm_pt35p { padding-top: 35% !important; }
  .sm_pt40p { padding-top: 40% !important; }
  .sm_pt50p { padding-top: 50% !important; }
  .sm_pt60p { padding-top: 60% !important; }
  .sm_pt65p { padding-top: 65% !important; }
  .sm_pt66p { padding-top: 66.66666666% !important; }
  .sm_pt70p { padding-top: 70% !important; }
  .sm_pt75p { padding-top: 75% !important; }
  .sm_pt80p { padding-top: 80% !important; }
  .sm_pt85p { padding-top: 85% !important; }
  .sm_pt90p { padding-top: 90% !important; }
  .sm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .sm_vat { vertical-align: top; }
  .sm_vam { vertical-align: middle; }
  .sm_vab { vertical-align: bottom; }
  .sm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .sm_tal { text-align: left; }
  .sm_tal_i { text-align: left !important; }
  .sm_tac { text-align: center; }
  .sm_tac_i { text-align: center !important; }
  .sm_tar { text-align: right; }
  .sm_tar_i { text-align: right !important; }
  .sm_taj { text-align: justify; }
  .sm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .sm_fsi { font-style: italic; }

  /* Font size */
  .sm_fz0 { font-size: 0; }
  .sm_fz10, .sm_fz10_a, .sm_fz10_a * { font-size: 10px; }
  .sm_fz11, .sm_fz11_a, .sm_fz11_a * { font-size: 11px; }
  .sm_fz12, .sm_fz12_a, .sm_fz12_a * { font-size: 12px; }
  .sm_fz13, .sm_fz13_a, .sm_fz13_a * { font-size: 13px; }
  .sm_fz14, .sm_fz14_a, .sm_fz14_a * { font-size: 14px; }
  .sm_fz15 { font-size: 15px; }
  .sm_fz16 { font-size: 16px; }
  .sm_fz18 { font-size: 18px; }
  .sm_fz20 { font-size: 20px; }
  .sm_fz22 { font-size: 22px; }
  .sm_fz24 { font-size: 24px; }
  .sm_fz26 { font-size: 26px; }
  .sm_fz28 { font-size: 28px; }
  .sm_fz30 { font-size: 30px; }
  .sm_fz32 { font-size: 32px; }
  .sm_fz34 { font-size: 34px; }
  .sm_fz36 { font-size: 36px; }
  .sm_fz38 { font-size: 38px; }
  .sm_fz40 { font-size: 40px; }
  .sm_fz42 { font-size: 42px; }
  .sm_fz44 { font-size: 44px; }
  .sm_fz46 { font-size: 46px; }
  .sm_fz48 { font-size: 48px; }
  .sm_fz50 { font-size: 50px; }
  .sm_fz0_i { font-size: 0 !important; }
  .sm_fz10_i, .sm_fz10_a_i, .sm_fz10_a_i * { font-size: 10px !important; }
  .sm_fz11_i, .sm_fz11_a_i, .sm_fz11_a_i * { font-size: 11px !important; }
  .sm_fz12_i, .sm_fz12_a_i, .sm_fz12_a_i * { font-size: 12px !important; }
  .sm_fz13_i, .sm_fz13_a_i, .sm_fz13_a_i * { font-size: 13px !important; }
  .sm_fz14_i, .sm_fz14_a_i, .sm_fz14_a_i * { font-size: 14px !important; }
  .sm_fz15_i { font-size: 15px !important; }
  .sm_fz16_i { font-size: 16px !important; }
  .sm_fz18_i { font-size: 18px !important; }
  .sm_fz20_i { font-size: 20px !important; }
  .sm_fz22_i { font-size: 22px !important; }
  .sm_fz24_i { font-size: 24px !important; }
  .sm_fz26_i { font-size: 26px !important; }
  .sm_fz28_i { font-size: 28px !important; }
  .sm_fz30_i { font-size: 30px !important; }
  .sm_fz32_i { font-size: 32px !important; }
  .sm_fz34_i { font-size: 34px !important; }
  .sm_fz36_i { font-size: 36px !important; }
  .sm_fz38_i { font-size: 38px !important; }
  .sm_fz40_i { font-size: 40px !important; }
  .sm_fz42_i { font-size: 42px !important; }
  .sm_fz44_i { font-size: 44px !important; }
  .sm_fz46_i { font-size: 46px !important; }
  .sm_fz48_i { font-size: 48px !important; }
  .sm_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .sm_fw900, .sm_fw900_a, .sm_fw900_a *, .sm_black, .sm_black_a, .sm_black_a * { font-weight: 900; }
  .sm_fw900_i, .sm_fw900_a_i, .sm_fw900_a_i *, .sm_black_i, .sm_black_a_i, .sm_black_a_i * { font-weight: 900 !important; }
  .sm_fw700, .sm_fw700_a, .sm_fw700_a *, .sm_bold, .sm_bold_a, .sm_bold_a * { font-weight: 700; }
  .sm_fw700_i, .sm_fw700_a_i, .sm_fw700_a_i *, .sm_bold_i, .sm_bold_a_i, .sm_bold_a_i * { font-weight: 700 !important; }
  .sm_fw600, .sm_fw600_a, .sm_fw600_a *, .sm_semibold, .sm_semibold_a, .sm_semibold_a * { font-weight: 600; }
  .sm_fw600_i, .sm_fw600_a_i, .sm_fw600_a_i *, .sm_semibold_i, .sm_semibold_a_i, .sm_semibold_a_i * { font-weight: 600 !important; }
  .sm_fw400, .sm_fw400_a, .sm_fw400_a * { font-weight: 400; }
  .sm_fw400_i, .sm_fw400_a_i, .sm_fw400_a_i * { font-weight: 400 !important; }
  .sm_fw300, .sm_fw300_a, .sm_fw300_a *, .sm_semilight, .sm_semilight_a, .sm_semilight_a * { font-weight: 300; }
  .sm_fw300_i, .sm_fw300_a_i, .sm_fw300_a_i *, .sm_semilight_i, .sm_semilight_a_i, .sm_semilight_a_i * { font-weight: 300 !important; }

  /* Line height */
  .sm_lh10 { line-height: 1; }
  .sm_lh100p { line-height: 100%; }
  .sm_lh11 { line-height: 1.1; }
  .sm_lh110p { line-height: 110%; }
  .sm_lh12 { line-height: 1.2; }
  .sm_lh120p { line-height: 120%; }
  .sm_lh13 { line-height: 1.3; }
  .sm_lh130p { line-height: 130%; }
  .sm_lh14 { line-height: 1.4; }
  .sm_lh140p { line-height: 140%; }
  .sm_lh15 { line-height: 1.5; }
  .sm_lh150p { line-height: 150%; }
  .sm_lh16 { line-height: 1.6; }
  .sm_lh160p { line-height: 160%; }
  .sm_lh17 { line-height: 1.7; }
  .sm_lh170p { line-height: 170%; }
  .sm_lh18 { line-height: 1.8; }
  .sm_lh180p { line-height: 180%; }
  .sm_lh19 { line-height: 1.9; }
  .sm_lh190p { line-height: 190%; }
  .sm_lh20 { line-height: 2; }
  .sm_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .sm_fln { float: none; }
  .sm_fll { float: left; }
  .sm_flr { float: right; }
}
@media screen and (max-width: 359px) {
}