/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  /*text-align: center;*/
  background-color: #f6f6f6;
  overflow-x: hidden;
}

span.required { color: #f33c38; }

.bgcblue, .bgcblue_h:hover { background-color: #3278be; }
.bgcblue_i, .bgcblue_h_i:hover { background-color: #3278be !important; }
.bgcorange, .bgcorange_h:hover { background-color: #f0964b; }
.bgcorange_i, .bgcorange_h_i:hover { background-color: #f0964b !important; }

.cblue, .cblue_h:hover { color: #3278be; }
.cblue_i, .cblue_h_i:hover { color: #3278be !important; }
.corange, .corange_h:hover { color: #f0964b; }
.corange_i, .corange_h_i:hover { color: #f0964b !important; }

.brcblue, .brcblue_h:hover { border-color: #3278be; }
.brcblue_i, .brcblue_h_i:hover { border-color: #3278be !important; }
.brcorange, .brcorange_h:hover { border-color: #f0964b; }
.brcorange_i, .brcorange_h_i:hover { border-color: #f0964b !important; }



/* Components */
p { margin-bottom: 20px; text-align: justify; }
p + p { margin-top: -8px; }


.link {
  color: #3278be;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }



.form {
  position: relative;
}
  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
    .form-row + .form-row,
    .form-buttons {
      margin-top: 20px;
    }
      .form-label {
        width: 165px;
        min-width: 165px;
        margin-top: 11px;
      }
        .form-label label .required {
          font-weight: 700;
        }

      .form-field {
        width: 100%;
      }
        .form-row-captcha .form-field {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
        }
          .form-row-captcha .captcha-container + div {
            flex-grow: 1;
          }

        .input-container {
          line-height: 0;
          background-color: #fff;
          border: 2px solid #e1e1e1;
          padding: 8px 12px 7px;
          position: relative;
          z-index: 1;
        }
          .input-container input,
          .input-container textarea,
          .input-container select {
            background: transparent;
            font-size: 1em;
            line-height: 1.5;
            border: 0px;
            padding: 0px;
            margin: 0px;
          }
          .input-container input:not([type='radio']):not([type='checkbox']),
          .input-container textarea,
          .input-container select {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
          }
            .input-container input::-webkit-input-placeholder { color: #000; opacity: 0.7; }
            .input-container input:-moz-placeholder { color: #000; opacity: 0.7; }
            .input-container input::-moz-placeholder { color: #000; opacity: 0.7; }
            .input-container input:-ms-input-placeholder { color: #000; opacity: 0.7; }
            .input-container input::-ms-input-placeholder { color: #000; opacity: 0.7; }
            .input-container input::placeholder { color: #000; opacity: 0.7; }


        .form-error {
          position: relative;
        }
          .errorMessage {
            background-color: #f0964b;
            padding: 3px 6px 2px;
            margin: 0px;
            color: #fff;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.3;
          }


    .form-row-captcha .captcha-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
    }
      .form-row-captcha .captcha-container img {
        border: 2px solid #e1e1e1;
      }
      .form-row-captcha .captcha-container .refresh-button.button-style {
        background: none;
        border: none;
        max-width: unset;
        padding: 0px;
      }
        /*.form-row-captcha .captcha-container .refresh-button.button-style:before,
        .form-row-captcha .captcha-container .refresh-button.button-style:after {
          display: none;
        }*/

        .form-row-captcha .captcha-container .refresh-button.button-style span {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          font-size: 0px;
          height: 100%;
          min-width: 46px;
        }
          .form-row-captcha .captcha-container .refresh-button.button-style span:after {
            content: '';
            display: block;
            background: url('/i/refresh-button-icon--orange.svg') no-repeat 50% 50%/22px auto transparent;
            border: solid #e1e1e1;
            border-width: 2px 0px;
            line-height: 0;
            width: 100%;
          }
            .form-row-captcha .captcha-container .refresh-button.button-style:hover span:after {
              background-image: url('/i/refresh-button-icon--white.svg');
              background-color: #f0964b;
            }


  .form-button {
    display: inline-block;
  }


.button-style {
  color: #f0964b;
  display: inline-block;
  background: none;
  border: max(2px, 0.16666667em) solid #f0964b;
  /*font-size: 0px;*/
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  position: relative;
  /*max-width: 450px;*/
  padding: 0.55555556em 1.66666667em;
  white-space: nowrap;
}
  .button-style:hover,
  .button-style.active {
    color: #fff;
    background-color: #f0964b;
  }

  .button-style.button-style-white {
    color: #fff;
    border-color: #fff;
    border-radius: 999px;
  }
    .button-style.button-style-white:hover,
    .button-style.button-style-white.active {
      color: #000;
      background-color: #fff;
    }


ul.ul, ol.ol {
  margin: -6px 0px 20px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 20px;
    margin-top: 0px;
    margin-bottom: 2px;
  }
  ul.ul li, ol.ol li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    padding-top: 6px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li:before,
    .with-marker:before {
      content: "●";
      display: inline-block;
      background: url('/i/ul-list-style-image.svg') no-repeat 50% 50% transparent;
      background-size: contain;
      font-weight: 600;
      margin-right: 0.6em;
      width: 0.62em;
      color: transparent;
    }

    ol.ol li:before {
      content: counters(ol-list-counter, '.') '. ';
      counter-increment: ol-list-counter;
      color: #3278be;
      font-weight: 600;
    }


.zoom-image {
  display: inline-block;
  position: relative;
}
  .zoom-image:before {
    content: '';
    background: url('/i/gallery-image-zoom.svg') 100% 100% no-repeat transparent;
    background-size: contain;
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 8%;
    height: 8%;
    opacity: 0.7;

    -webkit-transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
    -moz-transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
    -o-transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
    transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
  }
    .zoom-image:hover:before {
      width: 10%;
      height: 10%;
      opacity: 1 !important;
    }


.wrapper {
  width: 100%;
  max-width: 1224px;
  margin: 0px auto;
  padding: 0px 10px;
  position: relative;
}
  .unwrapper {
    margin-left: -9999px;
    margin-right: -9999px;
    position: static;
  }



/* Header */
.header-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: stretch;
}
  .header {
    position: relative;
    z-index: 5;
  }
    .header-info-menu-container ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
    }
      .header-info-menu-container ul li {
        position: relative;
      }
        .header-info-menu-container ul li + li:before {
          content: '';
          position: absolute;
          background: #000;
          top: 5px;
          left: 0px;
          bottom: 5px;
          width: 1px;
        }
        .header-info-menu-container ul li a {
          display: inline-block;
          color: #0264a5;
          font-size: 16px;
          font-weight: 400;
          padding: 5px 14px 6px;
          cursor: pointer;
        }
          .header-info-menu-container ul li a:hover,
          .header-info-menu-container ul li.active a {
            text-decoration: underline;
          }
          .header-info-menu-container ul li.active a {
            color: #f0964b;
            font-weight: 600;
            cursor: default;
          }


    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0px 15px;
    }
      .header-logo-container > a {
        cursor: pointer;
        text-decoration: none;
      }
      .header-logo-container div {
        line-height: 0;
      }
      .header-logo-container > a > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
        .header-logo-container img {
          width: 70px;
          margin-right: -12px;
        }
        .header-logo-container span {
          display: block;
          text-align: right;
          line-height: 0.8;
          letter-spacing: -0.04em;
        }
          .header-logo-container span.first {
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            padding-right: 5px;
          }
          .header-logo-container span.last {
            color: #3278be;
            font-size: 42px;
            font-weight: 600;
            margin-top: -14px;
          }
            .header-logo-container span.last:first-letter {
              font-size: 60px;
            }


      .header-phones-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
        .header-phones {
          padding-bottom: 10px;
        }
          .header-phones div {
            font-size: 35px;
            font-weight: 600;
            line-height: 1;
          }
            .header-phones a {
              cursor: default;
            }

        .header-worktime * {
          font-size: 16px;
          line-height: 1.3;
        }
          .header-worktime * > span {
            display: inline-block;
            color: #3278be;
            font-weight: 700;
            padding: 0px 0px 0px 6px;
            margin: 0px 0px 0px 3px;
            position: relative;
          }
            .header-worktime * > span:before {
              content: '';
              position: absolute;
              background: #3278be;
              top: 3px;
              left: 0px;
              bottom: 2px;
              width: 2px;
            }


      .header-address * {
        font-size: 20px;
      }


      .header-content .callback-link {
        display: block;
        line-height: 1.1;
        margin-right: 15px;
        cursor: pointer;
      }
        .header-content .callback-link:before {
          content: '';
          display: inline-block;
          background: url('/i/callback-button-icon--orange.svg') no-repeat left center transparent;
          background-size: auto 26px;
          width: 36px;
          height: 26px;
          vertical-align: middle;
          cursor: pointer;
        }
        .header-content .callback-link > * {
          display: inline-block;
          color: #f0964b;
          font-weight: 600;
          text-align: center;
          vertical-align: middle;
          cursor: pointer;
          border-bottom: 1px dashed #f0964b;
        }
          .header-content .callback-link:hover > * {
            border-bottom-color: transparent;
          }


    .header-menu-button-wrapper,
    .header-menu-checkbox,
    .header-menu-button-close {
      display: none;
    }
    /* .header-service-menu-container defined in _adaptive.css */


  .header-discount {
    background: #eaeaea;
    border-bottom: 2px solid #8db4ce;
    padding: 6px 0 7px;
    margin: 0px 0px 10px;
  }
    .header-discount > .wrapper {
      text-align: center;
    }
      .header-discount a,
      .header-discount span {
        font-weight: 600;
      }
      .header-discount a {
        text-decoration: underline;
        cursor: pointer;
      }
        .header-discount a:hover {
          text-decoration: none;
        }



/* Content block */
.content {
  padding: 0px 0px 10px;
  position: relative;
  z-index: 3;
  overflow: hidden;

  /*min-height: 3000px;*/
}
  .main-rotator-block-container {
    padding: 0px 0px 50px;
    margin: 0px 0px -50px;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
    .main-rotator-info-container {
      height: 0px;
      z-index: 2;
    }
      .main-rotator-info-container > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        position: absolute;
        top: 0px;
        right: 10px;
        width: 365px;
        height: 400px;
        padding: 0px 20px;
      }
        .main-rotator-info-container > div:before {
          content: '';
          background: rgba(0,0,0,.85);
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          width: 9999px;
        }
        .main-rotator-info-container > div > * {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          /*padding: 0px;
          margin: 0px;*/
          position: relative;
        }
          .main-rotator-info-container > div > * + * {
            border: solid #fff;
            border-width: 1px 0px 0px;
          }
          .main-rotator-info-container > div a {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            color: #f0964b;
            font-size: 27px;
            line-height: 0.98;
            padding: 10px 20px;
            cursor: pointer;
          }
            .main-rotator-info-container > div a img {
              width: 40px;
              min-width: 40px;
              margin-right: 20px;
            }
              .main-rotator-info-container > div a span {
                border-bottom: 1px solid #f0964b;
              }
              .main-rotator-info-container > div a[data-fancybox] span {
                border-bottom-style: dashed;
              }
                .main-rotator-info-container > div a:hover span {
                  border-bottom-color: transparent;
                }

    .main-rotator-container {
      height: 450px;
      margin-bottom: -50px;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
      .main-rotator-slide-container {
        padding-bottom: 50px;
        position: relative;
      }
        .main-rotator-slide-image {
          position: relative;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-color: #eaeaea;
          background-size: cover;
          height: 400px;
        }
          .main-rotator-slide-image a {
            content: '';
            display: block;
            font-size: 0px;
            cursor: pointer;
            height: 100%;
          }
        .main-slide-code-container {
          height: 0px;
        }
          .main-slide-code-container > div {
            position: absolute;
            background: #3278be;
            left: 10px;
            bottom: -50px;
            max-width: 500px;
            max-height: 215px;
            border: 20px solid #3278be;
            border-top-width: 15px;
            border-bottom-width: 15px;
            overflow: hidden;
          }
            .main-slide-code-container > div * {
              color: #fff;
            }

      #main-rotator-controls {
        height: 0px;
        /*z-index: 1;*/
      }
        #main-rotator-controls > div {
          max-width: calc(100% - 385px);
          height: 0px;
          position: relative;
        }
        #main-rotator-controls .slick-arrow {
          display: inline-block;
          font-size: 0px;
          background-repeat: no-repeat;
          background-color: transparent;
          background-size: cover;
          width: 66px;
          height: 45px;
          cursor: pointer;
          position: absolute;
          top: 177px;
          opacity: 0.9;
          z-index: 1;
        }
          #main-rotator-controls .slick-arrow:hover {
            opacity: 1;
          }
          #main-rotator-controls .slick-prev {
            background-image: url('/i/arrows--white.svg');
            background-position: left center;
            left: 0px;
          }
          #main-rotator-controls .slick-next {
            background-image: url('/i/arrows--white.svg');
            background-position: right center;
            right: 0px;
          }

      #main-rotator-dots {
        height: 0px;
        z-index: 1;
      }
        #main-rotator-dots > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 0px 518px;
          position: relative;
        }
          #main-rotator-dots > div > div {
            flex-grow: 1;
            margin-top: -32px;
          }
            #main-rotator-dots ul {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
              #main-rotator-dots li {
                width: 15px;
                height: 15px;
                border: 2px solid #b7b7b7;
                /*border-radius: 50%;*/
                cursor: pointer;
              }
                #main-rotator-dots li:hover {
                  border-color: #000;
                }
                #main-rotator-dots li + li {
                  margin-left: 15px;
                }
                #main-rotator-dots li.slick-active {
                  background: #3278be;
                  border-color: #3278be !important;
                }


  .content-bg {
    padding-top: 35px;
    margin-top: -21px;
    overflow: hidden;
    position: relative;
  }
    .main-rotator-block-container + .content-bg {
      padding-top: 65px;
      margin-top: 0px;
    }

    .breadcrumbs {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      line-height: 0;
      padding: 15px 0px 0px;
    }
      .content-bg .breadcrumbs {
        padding: 0px;
      }
      .breadcrumbs li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
        .breadcrumbs li:before,
        .breadcrumbs a,
        .breadcrumbs span {
          font-size: 14px;
          vertical-align: baseline;
          line-height: 1.1;
        }
        .breadcrumbs li + li:before {
          content: '';
          align-self: stretch;
          background: url('/i/breadcrumbs-arrow.svg') no-repeat 50% 50% transparent;
          background-size: auto 70%;
          width: 20px;
          color: transparent;
        }
        .breadcrumbs a {
          cursor: pointer;
          text-decoration: none;
          font-weight: 600;
        }
          .breadcrumbs a:hover {
            text-decoration: underline;
          }


    .subheader {
      position: relative;
      font-size: 30px;
      font-weight: 400;
      line-height: 1.3;
      text-transform: uppercase;
      padding: 15px 0px 20px;
      text-align: center;
      z-index: 2;
    }
      .subheader span {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: 900;
        text-transform: uppercase;
      }
      h1.subheader {
        font-size: 31px;
      }


    .black-bg-container {
      background: #333;
      padding: 10px 0px 0px;
      margin: 20px 0px;
    }
      .black-bg-container .subheader {
        color: #fff;
      }


    .text-container {
      padding: 0px 0px 30px;
      position: relative;
      z-index: 1;
    }


    .quick-link-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-content: flex-start;
      align-items: center;
      background: #eaeaea;
      padding: 0px 10px;
      margin: 0px 0px 20px;
      position: relative;
      z-index: 1;
    }
      .quick-link-list > div {
        padding: 15px 10px;
      }
        .quick-link-list a {
          display: block;
          font-size: 15px;
          line-height: 1;
          text-decoration: none;
          text-transform: uppercase;
          border-bottom: 1px dashed #000;
          cursor: pointer;
          position: relative;
        }
          .quick-link-list a:hover,
          .quick-link-list a.active {
            border-bottom-color: transparent;
          }
          .quick-link-list a.active {
            font-weight: 600;
            cursor: default;
          }


    .incut {
      /*color: #3278be;*/
      font-weight: 600;
      /*text-transform: uppercase;*/
      line-height: 1.4;
      padding: 24px 25px 26px 115px;
      margin: 0px 0px 20px;
      border: 3px solid #3278be;
      position: relative;
    }
      .incut:before {
        content: '';
        background: url('/f/upload/incut-icon.svg') no-repeat 50% 50% transparent;
        background-size: contain;
        position: absolute;
        top: 10px;
        left: 25px;
        bottom: 10px;
        width: 65px;
        min-height: 55px;
      }


    .advantages-block-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: stretch;
      align-content: flex-start;
      padding: 0px 0px 20px;
      margin: -12px 0px 0px -12px;
    }
      .advantages-block-container > div {
        width: 25%;
        padding: 12px 0px 0px 12px;
      }
        .advantages-block-container > div > div {
          background: #3278be;
          border: 3px solid #3278be;
          width: 100%;
          height: 100%;
          padding: 25px 20px 75px;
          position: relative;
        }
          .advantages-block-container > div > div:hover {
            background: none;
            border-color: #e1e1e1;
          }
          .advantage-item-image {
            line-height: 0;
            padding: 0px 0px 20px;
          }
            .advantage-item-image img {
              height: 80px;
            }
              .advantage-item-image img:first-child,
              .advantages-block-container div:hover > .advantage-item-image img + img {
                display: none;
              }
                .advantages-block-container div:hover > .advantage-item-image img:first-child {
                  display: inline;
                }

          .advantage-item-name {
            color: #fff;
            font-size: 22px;
            font-weight: 700;
            line-height: 1.2;
            min-height: 68px;
            text-transform: uppercase;
            padding: 0px 0px 15px;
          }
            .advantages-block-container div:hover > .advantage-item-name {
              color: #3278be;
            }

          .advantage-item-notice {
            font-size: 16px;
            color: #fff;
            padding: 0px 0px 20px;
          }
            .advantages-block-container div:hover > .advantage-item-notice {
              color: #000;
            }

          .advantage-item-arrow {
            position: absolute;
            background: url('/i/arrows--white.svg') no-repeat right center transparent;
            background-size: cover;
            left: 20px;
            bottom: 38px;
            width: 41px;
            height: 28px;
          }
            .advantages-block-container > div > div:hover .advantage-item-arrow {
              background-image: url('/i/arrows--blue.svg');
            }


    .base-more-link {
      line-height: 0;
    }
      .button-style .base-more-link {
        padding: 12px 28px;
      }
      .base-more-link a {
        line-height: 1.1;
        vertical-align: middle;
        cursor: pointer;
      }
        .base-more-link a:hover,
        .base-more-link.cw a,
        .base-more-link.cw_h a:hover,
        .button-style.button-style-white .base-more-link a {
          color: #fff;
        }
        .base-more-link.c0 a,
        .base-more-link.c0_h a:hover,
        .button-style:hover .base-more-link a {
          color: #000;
        }
        .base-more-link.cblue a,
        .base-more-link.cblue_h a:hover {
          color: #3278be;
        }
        .base-more-link.corange a,
        .base-more-link.corange_h a:hover {
          color: #f0964b;
        }
        .base-more-link a:after {
          content: '';
          background: none no-repeat right center transparent;
          background-size: cover;
          display: inline-block;
          vertical-align: middle;
          width: 26px;
          height: 18px;
          margin: 0px 0px 0px 10px;
        }
          .base-more-link a:after,
          .base-more-link.c0 a:after,
          .base-more-link.c0_h a:hover:after,
          .button-style:hover .base-more-link a:after,
          .button-style.button-style-white:hover .base-more-link a:after {
            background-image: url('/i/arrows--black.svg');
          }
          .base-more-link a:hover:after,
          .base-more-link.cw a:after,
          .base-more-link.cw_h a:hover:after,
          .button-style.button-style-white .base-more-link a:after {
            background-image: url('/i/arrows--white.svg');
          }
          .base-more-link.cblue a:after,
          .base-more-link.cblue_h a:hover:after {
            background-image: url('/i/arrows--blue.svg');
          }
          .base-more-link.corange a:after,
          .base-more-link.corange_h a:hover:after {
            background-image: url('/i/arrows--orange.svg');
          }


    .list-page-group-name {
      font-size: 25px;
      font-weight: 700;
      line-height: 1.1;
      text-transform: uppercase;
      padding-bottom: 10px;
    }
      .list-page-group-name + .list-block-container {
        padding-top: 10px;
      }
      .list-block-container + .list-page-group-name {
        padding-top: 15px;
      }
      .list-page-group-name ~ .list-block-container + .list-block-container {
        padding-top: 20px;
      }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      padding: 0px 0px 20px;
      margin: -20px 0px 0px -20px;
      position: relative;
    }
      .gallery-block-container .gallery-item {
        padding: 20px 0px 0px 20px;
        position: relative;
      }
        .gallery-item.width-2 {
          width: 50%;
        }
        .gallery-item.width-3 {
          width: 33.33333333%;
        }
        .gallery-item.width-4 {
          width: 25%;
        }

        .gallery-item-image {
          line-height: 0;
        }
          .gallery-item-image img {
            width: 100%;
          }

      .gallery-block-container .gallery-notice {
        padding: 0px 0px 20px;
        position: relative;
      }


    .list-view,
    .portfolio-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: stretch;

      margin: -20px 0px 0px -20px;
      position: relative;
    }
    .list-view {
      padding: 0px 0px 20px;
    }
      .list-view-item {
        /*width: 408px;*/
        width: 33.33333333%;
        padding: 20px 0px 0px 20px;
        position: relative;
      }
        .list-view-item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          width: 100%;
          height: 100%;
          border: 2px solid #3278be;
          position: relative;
        }
          .list-view-item-image {
            background: #eaeaea;
            line-height: 0;
            position: relative;
            order: 2;
            z-index: 1;
          }
            .list-view-item-image a,
            .list-view-item-image span {
              display: block;
              background: no-repeat 50% 50%/cover;
              padding: 65.10416667% 0% 0%;
            }
              .list-view-item-image a {
                cursor: pointer;
              }

          .list-view-item-text {
            padding: 20px 20px 25px;
            position: relative;
            order: 3;
            z-index: 2;
          }

          .list-view-item-name {
            color: #fff;
            background: #3278be;
            text-align: center;
            font-size: 22px;
            font-weight: 700;
            line-height: 1.3;
            position: relative;
            order: 1;
          }
            .list-view-item-text .list-view-item-name {
              background: transparent;
            }
            .list-view-item-name a,
            .list-view-item-name span {
              display: block;
              padding: 12px 20px 13px;
              text-transform: uppercase;
              text-decoration: none;
            }
              .list-view-item-name a {
                cursor: pointer;
              }
              .list-view-item-text .list-view-item-name {
                color: #000;
                text-align: left;
              }
                .list-view-item-text .list-view-item-name a,
                .list-view-item-text .list-view-item-name span {
                  display: inline;
                  padding: 0px;
                }
              .list-view-item-text .list-view-item-name a,
              .list-view-item-image + .list-view-item-text .list-view-item-name a {
                text-decoration: underline;
              }
                .list-view-item-text .list-view-item-name a:hover,
                .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                  text-decoration: none;
                }

          .list-view-item-name + .list-view-item-notice {
            padding: 15px 0px 0px;
          }
            /*.list-view-item-notice {
              text-align: left;
            }*/
            .list-view-item-notice *:last-child {
              margin-bottom: 0px;
            }


    .portfolio-block-container {
      line-height: 0;
    }
      .portfolio-block-container .list-view {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        margin: 0px;
      }
        .portfolio-block-container .list-view-item-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: rgba(0,0,0,.3);
          padding: 0px;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
        }
          .portfolio-block-container .portfolio-item-bg-link {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            cursor: pointer;
          }
          .portfolio-block-container .portfolio-item-name {
            margin: 4px 0px 8px;
          }
            .portfolio-block-container .portfolio-item-name a {
              display: block;
              color: #fff;
              font-size: 28px;
              font-weight: 900;
              line-height: 1.1;
              text-align: center;
              text-transform: uppercase;
              padding: 0px 15px;
              cursor: pointer;
            }

          .portfolio-block-container .portfolio-item-address,
          .portfolio-block-container .portfolio-item-info-block > div {
            font-size: 0px;
            text-align: center;
            padding: 0px 10px 10px;
          }
          .portfolio-item-info-block {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-content: flex-start;
            align-items: flex-start;
          }
            .portfolio-block-container .portfolio-item-address img,
            .portfolio-block-container .portfolio-item-info-block > div img {
              height: 16px;
              margin-right: 8px;
              vertical-align: middle;
            }
            .portfolio-block-container .portfolio-item-address span,
            .portfolio-block-container .portfolio-item-info-block > div span {
              color: #fff;
              font-size: 18px;
              font-weight: 400;
              line-height: 1.1;
              text-align: center;
              vertical-align: middle;
            }


    .team-block-container .list-view {
      justify-content: flex-start;
    }
      .team-block-container .list-view-item {
        width: 16.66666667%;
      }
        .team-block-container .list-view-item-image a,
        .team-block-container .list-view-item-image span {
          padding-top: 88.88888889%;
        }

        .team-block-container .list-view-item-name {
          font-size: 18px;
        }
          .team-block-container .list-view-item-name span {
            text-transform: none;
          }

        .team-block-container .list-view-item-notice {
          font-size: 16px;
        }


    .discount-block-container .list-view {
      flex-flow: column nowrap;
      margin: 0px;
    }
      .discount-block-container .list-view-item {
        width: 100%;
        padding: 0px;
      }
        .discount-block-container .list-view-item + .list-view-item {
          margin-top: -2px;
        }
        .discount-block-container .list-view-item-wrapper {
          flex-direction: row;
        }
          .discount-block-container .list-view-item-image {
            min-width: 29%;
          }
            .discount-block-container .list-view-item-image a,
            .discount-block-container .list-view-item-image span {
              height: 100%;
              padding: 0%;
            }
              .discount-block-container .list-view-item-image a:before,
              .discount-block-container .list-view-item-image span:before {
                content: '';
                display: block;
                height: 0px;
                padding: 60% 0% 0%;
              }

          .discount-block-container .list-view-item-notice {
            text-align: justify;
          }


    /*.discount-page-image {
      height: 0px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: transparent;
      background-size: cover;
      padding: 17% 0px 0px;
      margin: 0px 0px 35px;
    }*/


    .response-block-container .list-view {
      justify-content: flex-start;
      margin: 2px 0px 0px 2px;
    }
      .response-block-container .list-view-item {
        width: 50%;
        padding: 0px;
        margin: -2px 0px 0px -2px;
      }
        .response-block-container .list-view-item-wrapper {
          flex-direction: row;
        }
          .response-block-container .list-view-item-name {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;
          }
          .response-block-container .response-date {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.3;
            margin-left: 10px;
            text-transform: none;
          }

    /*.response-form-container {
      width: 700px;
      margin: 0px auto;
    }*/


    .price-table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0px 0px 20px;
      position: relative;
    }
      .price-table-counter {
        counter-reset: price-table-group-counter;
      }
      .price-table > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
      }
      .price-table-group {
        padding-bottom: 13px;
      }
        .price-table-counter .price-table-group {
          counter-reset: price-table-subheader-counter;
        }
        .price-table > div + .price-table-group {
          margin-top: 35px;
        }
        .price-table-group span {
          font-size: 26px;
          font-weight: 700;
          text-transform: uppercase;
        }
          .price-table-counter .price-table-group span:before {
            content: counter(price-table-group-counter) ". ";
            counter-increment: price-table-group-counter;
            font-weight: 300;
          }

      .price-table-head {
        background: #3278be;
      }

      .price-table-subheader {
        background: #e1e1e1;
        position: relative;
        z-index: 1;
      }
        .price-table-row + .price-table-subheader {
          margin-top: -1px;
        }
      .price-table-row {
        background: rgba(2,100,165,.06);
      }
        .price-table-row:nth-child(2n+1) {
          background: rgba(2,100,165,.02);
        }
        .price-name,
        .price-price,
        .price-unit,
        .price-volume,
        .price-sum {
          padding: 1px 18px;
          flex-basis: 100%;
          flex-shrink: 2;
        }
        .price-name {
          padding-left: 35px;
        }
        .price-price {
          flex-shrink: 3;
        }
        .price-unit,
        .price-unit + .price-price {
          flex-shrink: 4;
        }
        .price-unit,
        .price-price,
        .price-volume,
        .price-sum {
          text-align: center;
          border-left: 2px solid #e1e1e1;
        }
          .price-table-head .price-name,
          .price-table-head .price-price,
          .price-table-head .price-unit,
          .price-table-head .price-volume,
          .price-table-head .price-sum {
            color: #fff;
            font-size: 19px;
            font-weight: 400;
            padding-top: 7px;
            padding-bottom: 7px;
          }
          .price-table-head .price-price,
          .price-table-head .price-unit,
          .price-table-head .price-volume,
          .price-table-head .price-sum {
            border-left-color: #3278be;
          }
          .price-table-subheader .price-name {
            font-weight: 600;
            text-transform: uppercase;
            padding: 5px 18px;
            text-align: center;
          }
            .price-table-counter .price-table-subheader .price-name:before {
              content: counter(price-table-group-counter) "." counter(price-table-subheader-counter) ". ";
              counter-increment: price-table-subheader-counter;
              font-weight: 300;
            }

          .price-table-row .price-name,
          .price-table-row .price-price,
          .price-table-row .price-unit,
          .price-table-row .price-volume,
          .price-table-row .price-sum {
            border-bottom: 2px solid #e1e1e1;
          }

        .price-associations .price-name {
          flex-shrink: 17;
        }
        .price-associations .price-price,
        .price-associations .price-volume,
        .price-associations .price-sum {
          flex-shrink: 32;
        }

        .price-table-sum-total .price-name,
        .price-table-sum-total .price-sum {
          font-size: 20px;
          font-weight: 600;
          border-top: 2px solid #e1e1e1;
          padding-bottom: 2px;
        }
        .price-table-sum-total .price-sum {
          flex-shrink: 11;
          padding-left: 2px;
          padding-right: 2px;
        }

    .price-table-more-link {
      text-align: center;
      padding: 0px 0px 20px;
    }
      .price-table-more-link .button-style,
      .callback-button-style {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        padding-left: 1.11111111em;
      }
        .price-table-more-link .button-style:before,
        .callback-button-style:before {
          content: '';
          background: url('/i/price-more-button-icon--orange.svg') no-repeat 50% 50%/contain;
          min-width: 1.22222222em;
          min-height: 1.22222222em;
          margin-right: 0.77777778em;
        }
          .price-table-more-link .button-style:hover:before {
            background-image: url('/i/price-more-button-icon--white.svg');
          }

        .callback-button-style:before {
          min-width: 1.44444444em;
          min-height: 1.44444444em;
          background-image: url('/i/callback-button-icon--orange.svg');
        }
          .callback-button-style:hover:before {
            background-image: url('/i/callback-button-icon--white.svg');
          }


    .main-gallery-block-container {
      --slide-width: min(680px, calc(100vw - 260px));
      --active-slide-width: min(850px, calc(100vw - 90px));
      --slide-height-ratio: 0.58823529412;
      --slide-height-percent: 58.823529412%;
      padding: 0px 0px 20px;
    }
      .main-gallery-rotator {
        padding: 0px 45px 50px;
        position: relative;
        overflow: hidden;
      }
        .main-gallery-rotator .slick-list {
          max-width: var(--slide-width);
          margin: 0px auto;
          overflow: visible !important;
        }
          .main-gallery-rotator .slick-list:before {
            content: '';
            display: block;
            height: 0px;
            padding: calc(var(--active-slide-width) * var(--slide-height-ratio)) 0% 0%;
          }

          .main-gallery-rotator .slick-track {
            /*display: block !important;*/
            align-items: center;
            height: 100%;
            margin: 0px 0px 0px calc((var(--active-slide-width) - var(--slide-width)) / -2);
            top: 0px;
            left: 0px;
            position: absolute;
            /*height: 500px;*/
          }
            .main-gallery-image-container {
              display: inline-flex !important;
              flex-direction: column;
              justify-content: center;
              align-items: stretch;

              /*display: inline-block !important;*/
              width: auto !important;
              min-width: var(--slide-width);
              /*height: 500px !important;*/
              height: auto !important;
              /*padding: 50px 0px;*/
              position: relative;

              -webkit-transition: width 0.5s ease 0s, min-width 0.5s ease 0s/*, padding 0.5s ease 0s*/;
              -moz-transition: width 0.5s ease 0s, min-width 0.5s ease 0s/*, padding 0.5s ease 0s*/;
              -o-transition: width 0.5s ease 0s, min-width 0.5s ease 0s/*, padding 0.5s ease 0s*/;
              transition: width 0.5s ease 0s, min-width 0.5s ease 0s/*, padding 0.5s ease 0s*/;
            }
              .main-gallery-image-container:before {
                content: '';
                position: absolute;
                top: 50px;
                left: 0px;
                right: 0px;
                bottom: 50px;
                box-shadow: 0px 0px 70px -20px transparent;

                -webkit-transition: top 0.5s ease 0s, bottom 0.5s ease 0s, box-shadow 0.5s ease 0s;
                -moz-transition: top 0.5s ease 0s, bottom 0.5s ease 0s, box-shadow 0.5s ease 0s;
                -o-transition: top 0.5s ease 0s, bottom 0.5s ease 0s, box-shadow 0.5s ease 0s;
                transition: top 0.5s ease 0s, bottom 0.5s ease 0s, box-shadow 0.5s ease 0s;
              }

              .main-gallery-image-container.slick-active {
                /*width: var(--active-slide-width) !important;*/
                min-width: var(--active-slide-width);
                /*padding: 0px;*/
                z-index: 1;
              }
                .main-gallery-image-container.slick-active:before {
                  top: 45px;
                  bottom: 10px;
                  box-shadow: 0px 0px 60px -2px #000;
                }

              .main-gallery-image-container a,
              .main-gallery-image-container span {
                /*display: block;*/
                background: no-repeat 50% 50%/cover;
                padding: var(--slide-height-percent) 0% 0%;
                position: relative;
                z-index: 1;
              }
                .main-gallery-image-container a:after,
                .main-gallery-image-container span:after {
                  content: '';
                  background: #333;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;

                  opacity: 0.7;

                  -webkit-transition: opacity 0.5s ease 0s;
                  -moz-transition: opacity 0.5s ease 0s;
                  -o-transition: opacity 0.5s ease 0s;
                  transition: opacity 0.5s ease 0s;
                }
                  .main-gallery-image-container.slick-active a:after,
                  .main-gallery-image-container.slick-active span:after {
                    opacity: 0;
                  }
                .main-gallery-image-container a:before {
                  opacity: 0;
                }
                  .main-gallery-image-container.slick-active a:before {
                    opacity: 0.7;
                  }

                .main-gallery-image-container img {
                  /*width: 100%;*/
                  display: none !important;
                }

        .main-gallery-controls {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          max-width: var(--active-slide-width);
          height: calc(var(--active-slide-width) * var(--slide-height-ratio));
          margin: 0px auto;
          position: absolute;
          top: 0px;
          left: 45px;
          right: 45px;
          /*z-index: 1;*/
        }
          .main-gallery-controls:before {
            content: '';
            width: 100%;
            min-width: 100%;
            order: 2;
          }

          .main-gallery-controls .slick-arrow {
            /*top: 50px;*/
            width: 9999px;
            min-width: 9999px;
            /*height: 400px;*/
            height: calc(var(--slide-width) * var(--slide-height-ratio));
            cursor: pointer;
            /*position: absolute;*/
            position: relative;
            z-index: 1;
          }
            .main-gallery-controls .slick-prev {
              order: 1;
              /*right: 100%;*/
            }
            .main-gallery-controls .slick-next {
              order: 3;
              /*left: 100%;*/
            }
            .main-gallery-controls .button-style {
              background: none;
              border: none;
              line-height: 0;
              width: min(63px, calc((100vw - var(--active-slide-width)) / 2));
              top: 50%;
              position: absolute;
              padding: 11px;
              transform: translateY(-50%);
            }
              .main-gallery-controls .slick-prev .button-style {
                right: 2px;
              }
              .main-gallery-controls .slick-next .button-style {
                left: 2px;
              }
              .main-gallery-controls .button-style:before {
                content: '';
                background: rgba(51,51,51,.6);
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                position: absolute;
                filter: blur(5px);
              }

              .main-gallery-controls .button-style:after {
                content: '';
                background: url('/i/arrows--white.svg') no-repeat 0% 50%/cover transparent;
                display: block;
                padding: 68.29268292% 0% 0%;
                position: relative;
              }
                .main-gallery-controls .slick-next .button-style:after {
                  background-position: 100% 50%;
                }
                .main-gallery-controls .slick-arrow:hover .button-style:after {
                  background-image: url('/i/arrows--orange.svg');
                }

        .main-gallery-dots {
          max-width: calc(var(--active-slide-width) + 20px);
          height: 0px;
        }
          /*.main-gallery-rotator.slick-dotted .main-gallery-dots {
            height: 50px;
          }*/
          .main-gallery-dots > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0px 322px;
            position: relative;
          }
            .main-gallery-dots > div > div {
              flex-grow: 1;
              margin-top: 18px;
            }
              .main-gallery-dots ul {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
              }
                .main-gallery-dots li {
                  width: 15px;
                  height: 15px;
                  border: 2px solid #b7b7b7;
                  /*border-radius: 50%;*/
                  cursor: pointer;
                }
                  .main-gallery-dots li:hover {
                    border-color: #fff;
                  }
                  .main-gallery-dots li + li {
                    margin-left: 15px;
                  }
                  .main-gallery-dots li.slick-active {
                    background: #3278be;
                    border-color: #3278be !important;
                  }

        .main-gallery-notice-container {
          max-width: var(--active-slide-width);
          margin: 0px auto;
          position: relative;
          z-index: 1;
        }
          /*.main-gallery-rotator.slick-dotted .main-gallery-notice-container {
            height: 0px;
          }*/
          .main-gallery-notice-container > div {
            position: absolute;
            background: #3278be;
            left: 0px;
            bottom: -50px;
            width: 307px;
            max-height: 250px;
            border: 20px solid #3278be;
            overflow: hidden;
          }
            .main-gallery-notice-container > div * {
              color: #fff;
            }
            .main-gallery-notice-container > div .base-more-link a:after {
              background-image: url('/i/arrows--white.svg');
            }


    .portfolio-info-block-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      line-height: 1.2;
      padding: 0px 0px 20px;
      margin: -10px 0px 0px -25px;
    }
      .portfolio-info-block-container > div {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px 0px 25px;
      }
        .portfolio-info-block-container > div img {
          align-self: flex-start;
          height: 18px;
          margin: 2px 8px 0px 0px;
        }
        .portfolio-info-block-container > div span {
          font-size: 17px;
          vertical-align: middle;
        }


    .faq-list {
      padding: 0px 0px 20px;
      margin: -10px 0px 0px;
    }
      .faq-list > div {
        padding: 10px 0px 0px;
      }
        .faq-list > div > div {
          border: 2px solid #3278be;
        }
          .faq-list > div > div > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            background: #eaeaea;
            /*line-height: 1.2;*/
            position: relative;
            cursor: pointer;
            overflow: hidden;
            z-index: 1;

            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
            .faq-list > div > div > div:first-child:hover,
            .faq-list > div > div.active > div:first-child {
              background: #3278be;
            }

            .faq-list > div > div > div:first-child > span:first-child {
              font-weight: 400;
              padding: 10px 15px;
            }
              .faq-list > div > div > div:first-child:hover > span:first-child,
              .faq-list > div > div.active > div:first-child > span:first-child {
                color: #fff;
              }

            .faq-list > div > div > div:first-child > span + span {
              width: 41px;
              min-width: 41px;
              position: relative;
            }
              .faq-list > div > div > div:first-child > span + span:after {
                content: '';
                display: flex;
                background: url('/i/faq-list-plus.svg') no-repeat 50% 50%/1em auto transparent;
                line-height: 0;
                height: 100%;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                position: absolute;
                z-index: 1;

                -webkit-transition: transform 0.2s ease 0s;
                -moz-transition: transform 0.2s ease 0s;
                -o-transition: transform 0.2s ease 0s;
                transition: transform 0.2s ease 0s;
              }
              .faq-list > div > div > div:first-child:hover > span + span:after,
              .faq-list > div > div.active > div:first-child > span + span:after {
                background-image: url('/i/faq-list-plus--white.svg');
              }
              .faq-list > div > div.active > div:first-child > span + span:after {
                transform-origin: 50% 50%;
                transform: rotate(-45deg) scale(1.1);
              }


            .faq-list > div > div > div:first-child + div {
              display: none;
              overflow: hidden;
              vertical-align: bottom;
            }
              .faq-list > div > div > div:first-child + div > div {
                padding: 10px 15px;
              }
                .faq-list > div > div > div:first-child + div > div p:last-child {
                  margin-bottom: 0px;
                }



/* Contact */
.contact-map-block-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin: 0px 0px 20px;
  position: relative;
}
  .contact-map-block-container > div {
    width: 50%;
    min-width: 50%;
  }
  .contact-map-block-container > div + div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;
  }

    .contact-map-block-container > div > div {
      width: 200%;
      min-width: 200%;
    }
      .contact-info-content {
        background: #fff;
        border: 7px solid #3278be;
        width: 440px;
        margin: 50px 0px 40px;
        padding: 0px 30px 40px 10px;
        position: relative;
        z-index: 1;
      }
        .contact-info-content > div {
          padding: 30px 0px 0px 30px;
        }
          .contact-info-label span {
            display: block;
            color: #3278be;
            font-size: 20px;
            font-weight: 900;
            line-height: 1.3;
            text-transform: uppercase;
            margin: 0px 0px 7px;
          }
          .contact-info-worktime {
            font-size: 15px;
          }
          .contact-info-phones a {
            font-size: 22px;
            font-weight: 600;
            line-height: 1.3;
            cursor: default;
          }
          .contact-info-emails a {
            font-weight: 600;
            line-height: 1.1;
            text-decoration: underline;
            cursor: pointer;
          }
            .contact-info-emails a:hover {
              text-decoration: none;
            }

          .contact-info-emails span {
            line-height: 1.1;
          }


    .contact-map-block-container > div + div > div {
      border: solid #3278be;
      border-width: 7px 0px;
    }

      .map-container {
        overflow: hidden;
        position: relative;
      }
        .contact-map-block-container > div + div > div .map-container {
          height: 100%;
        }

        .map-container:before {
          content: '';
          display: block;
          height: 0px;
          padding-top: 30%;
        }

        .map-container > div:before {
          content: 'Загрузка карты...';
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: auto;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          position: absolute;
        }
          .contact-map-block-container > div + div > div .map-container > div:before {
            left: 450px;
          }

        .map-container > div > div {
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          position: absolute;
        }



/* Footer */
.callback-order-container {
  display: inline-block;
  background: #fff;
  width: 100%;
  max-width: 1204px;
  padding: 10px 0px 0px;
  border: 7px solid #3278be;
}
  .callback-order-form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20px;
  }
    .callback-order-form-wrapper {
      width: 700px;
      padding-bottom: 25px;
    }
      .callback-form .form-row-content-link,
      .callback-form .form-row-content {
        overflow: hidden;

        -webkit-transition: /*max-height 0.3s ease 0s, margin-top 0.3s ease 0s, */opacity 0.3s ease 0s;
        -moz-transition: /*max-height 0.3s ease 0s, margin-top 0.3s ease 0s, */opacity 0.3s ease 0s;
        -o-transition: /*max-height 0.3s ease 0s, margin-top 0.3s ease 0s, */opacity 0.3s ease 0s;
        transition: /*max-height 0.3s ease 0s, margin-top 0.3s ease 0s, */opacity 0.3s ease 0s;
      }
        .callback-form .form-row-content-link,
        .callback-form.callback-form-with-content .form-row-content {
          height: auto;
          margin-top: 20px;
          opacity: 1;
        }
        .callback-form.callback-form-with-content .form-row-content-link,
        .callback-form .form-row-content {
          height: 0px;
          margin-top: 0px;
          opacity: 0;
        }
          .callback-form .form-row-content-link span {
            color: #3278be;
            font-size: 15px;
            font-weight: 600;
            line-height: 1;
            border-bottom: 1px dashed #3278be;
            cursor: pointer;
          }
            .callback-form .form-row-content-link span:hover {
              border-bottom-color: transparent;
            }

      .callback-form .form-buttons {
        margin-left: 165px;
      }


.footer-menu-container {
  background: #3278be;
}
  .footer-menu-container ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
      .footer-menu-container ul li a,
      .footer-menu-container ul li span {
        display: block;
        color: #fff;
        position: relative;
        font-size: 17px;
        font-weight: 600;
        padding: 12px 18px;
        text-transform: uppercase;
        text-decoration: none;
        white-space: nowrap;
      }
      .footer-menu-container ul li span {
        cursor: default;
      }
      .footer-menu-container ul li a {
        cursor: pointer;
      }
        .footer-menu-container ul li.active a,
        .footer-menu-container ul li a:hover {
          background: #333;
        }
        .footer-menu-container ul li.active a {
          cursor: default;
          font-weight: 700;
        }

.footer {
  background: #333;
  padding: 30px 0px;
  margin: 0px;
  position: relative;
  z-index: 1;
}
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: -20px 0px 0px -20px;
  }
    .footer-content > div {
      padding: 20px 0px 0px 20px;
    }

    .footer-phones-container > div {
      background: url('/i/callback-button-icon--orange.svg') no-repeat 0% 1px/auto 26px transparent;
      padding-left: 36px;
    }
      .footer-phones,
      .footer-worktime {
        padding-bottom: 10px;
      }
        .footer-phones div {
          color: #fff;
          font-size: 28px;
          font-weight: 700;
          line-height: 1;
        }
          .footer-phones a {
            cursor: default;
          }

        .footer-worktime * {
          color: #fff;
          font-size: 16px;
          line-height: 1.3;
        }
          .footer-worktime * > span {
            display: inline-block;
            font-weight: 700;
            padding: 0px 6px;
            margin: 0px 0px 0px 3px;
            position: relative;
          }
            .footer-worktime * > span:before {
              content: '';
              position: absolute;
              background: #fff;
              top: 3px;
              left: 0px;
              bottom: 2px;
              width: 2px;
            }

        .footer-contact-callback-link a {
          display: inline-block;
          color: #f0964b;
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
          border-bottom: 1px dashed #f0964b;
          cursor: pointer;
          white-space: nowrap;
        }
          .footer-contact-callback-link a:hover {
            border-bottom-color: transparent;
          }

    .footer-info-menu-container ul {
      line-height: 1.3;
    }
      .footer-info-menu-container li a {
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
      .footer-info-menu-container li a:hover,
      .footer-info-menu-container li.active a {
        color: #f0964b;
        text-decoration: none;
      }
      .footer-info-menu-container li.active a {
        font-weight: 600;
        cursor: default;
      }

    .footer-address-container {
      background: url('/i/footer-address-icon.svg') no-repeat 0% 3px transparent;
      background-size: auto 25px;
      padding-left: 36px;
    }
      .footer-address * {
        color: #fff;
        font-size: 18px;
        line-height: 1.3;
      }


  .footer-copyrights {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
    .footer-copyright + .footer-copyright {
      padding-top: 10px;
    }
    .footer-copyright,
    .footer-copyright * {
      color: #fff;
      font-size: 15px;
    }